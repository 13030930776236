<div class="upload-file-container">
    <mat-label *ngIf="label"
               [ngClass]="{'field-required': isRequired && control.enabled}"
               class="form-control-label">
        {{label}}
    </mat-label>

    <div fxLayout="row" class="upload-file-boundary">
        <dui-select-file [fileExtensions]="allowedFileExtensions"
                         [isMultipleFileUpload]="isMultipleFileUpload"
                         [placeholder]="placeholder"
                         [disabled]="control.disabled"
                         [hasSelectedFile]="control.value?.length"
                         (filesAdded)="onFilesAdded($event)">
        </dui-select-file>
        <div class="file-info-list">
            <ng-container *ngIf="control.value">
                <dui-file-info *ngFor="let file of control.value; index as i"
                    [file]="file" (fileRemoved)="onFileRemoved(i)"
                ></dui-file-info>
            </ng-container>
        </div>
    </div>
    <div *ngFor="let errorFile of errorFiles" class="file-errors">
        <ng-container [ngSwitch]="errorFile.errorType">
            <ng-container *ngSwitchCase="ErrorType.Size"><b>{{errorFile.file.name}}</b> file too large</ng-container>
            <ng-container *ngSwitchCase="ErrorType.Extension"><b>{{errorFile.file.name}}</b> file has an unsupported extension
            </ng-container>
            <ng-container *ngSwitchCase="ErrorType.Length"><b>{{errorFile.file.name}}</b> file name too long</ng-container>
        </ng-container>
    </div>
</div>
