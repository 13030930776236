import { Injectable, OnDestroy } from "@angular/core";
import dayjs, { Dayjs } from "dayjs";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { ITimeframeSettings, TimeframeInterval } from "@core/interfaces/timeframe.interface";
import { TimeframeSettingsOption } from "@core/enums/timeframes.enum";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { DEFAULT_TIMEFRAMES } from "@core/constants/timeframe.constant";
import { LocalStorageService } from "@core/services/local-storage.service";
import { takeUntil } from "rxjs/operators";
import { TIMEFRAME_LS_KEY } from "@shared/constants/local-storage-keys.constant";

interface TimeframeIntervals {
    [key: number]: () => TimeframeInterval;
}

const DEFAULT_TIMEFRAME_SETTINGS: ITimeframeSettings = {
    settings: DEFAULT_TIMEFRAMES.value,
    startDate: null,
    endDate: null,
    isDefault: false,
};

@Injectable({
    providedIn: "root",
})
export class TimeframeService implements OnDestroy {
    public settings$: Observable<ITimeframeSettings>;

    private readonly frameIntervals: TimeframeIntervals = {};
    private settingsData$ = new BehaviorSubject<ITimeframeSettings>({
        ...DEFAULT_TIMEFRAME_SETTINGS,
    });

    private customInterval: ITimeframeSettings = {
        settings: null,
        startDate: null,
        endDate: null,
        isDefault: false,
    };

    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private settings: SettingStorageService,
        private auth: AuthStorageService,
        private localStorageService: LocalStorageService
    ) {
        this.settings$ = this.settingsData$.asObservable();

        this.initFrameIntervals();

        // process user session
        this.auth
            .isAuthorized$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                const defaults: ITimeframeSettings = this.localStorageService.getItem(
                    TIMEFRAME_LS_KEY
                ) || { ...DEFAULT_TIMEFRAME_SETTINGS };
                this.customInterval = { ...DEFAULT_TIMEFRAME_SETTINGS };
                this.setSettings(
                    defaults.settings,
                    defaults.startDate,
                    defaults.endDate,
                    defaults.isDefault
                );
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getFrameInterval(timeframe?: TimeframeSettingsOption): TimeframeInterval {
        return this.frameIntervals[timeframe || this.getSettings().settings]();
    }

    getSettings(): ITimeframeSettings {
        return this.settingsData$.value;
    }

    setSettings(
        settings: TimeframeSettingsOption,
        startDate: Date,
        endDate: Date,
        isDefault: boolean
    ): void {
        const timeframe = {
            settings,
            startDate,
            endDate,
            isDefault,
        };
        // Save current custom interval
        if (settings === TimeframeSettingsOption.Custom) {
            this.customInterval = timeframe;
        }
        this.settingsData$.next(timeframe);
        this.updateSettings(timeframe);
    }

    private updateSettings(timeframe: ITimeframeSettings) {
        this.localStorageService.setItem(TIMEFRAME_LS_KEY, timeframe);
        this.settings.updateSetting({
            timeframe,
        });
    }

    private today() {
        return this.getTimeInterval();
    }

    private yesterday(): TimeframeInterval {
        const startDate = dayjs().subtract(1, "day");
        const endDate = dayjs().subtract(1, "day");
        return this.getTimeInterval(startDate, endDate);
    }

    private last7Days(): TimeframeInterval {
        const startDate = dayjs().subtract(6, "day");
        return this.getTimeInterval(startDate);
    }

    private last30days(): TimeframeInterval {
        const startDate = dayjs().subtract(29, "day");
        return this.getTimeInterval(startDate);
    }

    private thisMonth(): TimeframeInterval {
        return this.getTimeInterval(dayjs().startOf("month"));
    }

    private lastMonth(): TimeframeInterval {
        const startDate = dayjs().subtract(1, "month").startOf("month");
        const endDate = dayjs().subtract(1, "month").endOf("month");
        return this.getTimeInterval(startDate, endDate);
    }

    private lastHourPeriod(numberOfHours: number): TimeframeInterval {
        return {
            startDate: dayjs().subtract(numberOfHours, "hours").toDate(),
            endDate: dayjs().toDate(),
        };
    }

    private lastMinutePeriod(numberOfMinutes: number): TimeframeInterval {
        const startDate = dayjs().subtract(numberOfMinutes, "minutes");
        const endDate = dayjs();
        return {
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
        };
    }

    private thisWeek(): TimeframeInterval {
        const startDate = dayjs().startOf("week");
        return this.getTimeInterval(startDate);
    }

    private lastWeek(): TimeframeInterval {
        const startDate = dayjs().subtract(1, "week").startOf("week");
        const endDate = dayjs().subtract(1, "week").endOf("week");
        return this.getTimeInterval(startDate, endDate);
    }

    private thisYear(): TimeframeInterval {
        const startDate = dayjs().startOf("year");
        return this.getTimeInterval(startDate);
    }

    private lastYear(): TimeframeInterval {
        const startDate = dayjs().subtract(1, "year").startOf("year");
        const endDate = dayjs().subtract(1, "year").endOf("year");
        return this.getTimeInterval(startDate, endDate);
    }

    private thisQuarter(): TimeframeInterval {
        dayjs().startOf("quarter");
        const startDate = dayjs().startOf("quarter");
        return this.getTimeInterval(startDate);
    }

    private lastQuarter(): TimeframeInterval {
        const startDate = dayjs().subtract(1, "quarter").startOf("quarter");
        const endDate = dayjs().subtract(1, "quarter").endOf("quarter");
        return this.getTimeInterval(startDate, endDate);
    }

    private customFrameInterval(): TimeframeInterval {
        return this.getTimeInterval(
            dayjs(this.customInterval.startDate),
            dayjs(this.customInterval.endDate)
        );
    }

    private getTimeInterval(startDate?: Dayjs, endDate?: Dayjs): TimeframeInterval {
        startDate = startDate || dayjs();
        endDate = endDate || dayjs();
        return {
            startDate: startDate.startOf("day").toDate(),
            endDate: endDate.endOf("day").toDate(),
        };
    }

    private initFrameIntervals() {
        this.frameIntervals[TimeframeSettingsOption.Today] = this.today.bind(this);
        this.frameIntervals[TimeframeSettingsOption.Yesterday] = this.yesterday.bind(this);
        this.frameIntervals[TimeframeSettingsOption.Last7Days] = this.last7Days.bind(this);
        this.frameIntervals[TimeframeSettingsOption.Last30Days] = this.last30days.bind(this);
        this.frameIntervals[TimeframeSettingsOption.ThisMonth] = this.thisMonth.bind(this);
        this.frameIntervals[TimeframeSettingsOption.LastMonth] = this.lastMonth.bind(this);
        this.frameIntervals[TimeframeSettingsOption.Last30Minutes] = this.lastMinutePeriod.bind(
            this,
            30
        );
        this.frameIntervals[TimeframeSettingsOption.Last1hour] = this.lastHourPeriod.bind(this, 1);
        this.frameIntervals[TimeframeSettingsOption.Last6hours] = this.lastHourPeriod.bind(this, 6);
        this.frameIntervals[TimeframeSettingsOption.Last12hours] = this.lastHourPeriod.bind(
            this,
            12
        );
        this.frameIntervals[TimeframeSettingsOption.Last24hours] = this.lastHourPeriod.bind(
            this,
            24
        );
        this.frameIntervals[TimeframeSettingsOption.Last48hours] = this.lastHourPeriod.bind(
            this,
            48
        );
        this.frameIntervals[TimeframeSettingsOption.LastWeek] = this.lastWeek.bind(this);
        this.frameIntervals[TimeframeSettingsOption.ThisWeek] = this.thisWeek.bind(this);
        this.frameIntervals[TimeframeSettingsOption.LastYear] = this.lastYear.bind(this);
        this.frameIntervals[TimeframeSettingsOption.ThisYear] = this.thisYear.bind(this);
        this.frameIntervals[TimeframeSettingsOption.LastQuarter] = this.lastQuarter.bind(this);
        this.frameIntervals[TimeframeSettingsOption.ThisQuarter] = this.thisQuarter.bind(this);
        this.frameIntervals[TimeframeSettingsOption.Custom] = this.customFrameInterval.bind(this);
    }
}
