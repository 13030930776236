import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { NoDataComponent } from "./no-data.component";

@NgModule({
    declarations: [NoDataComponent],
    imports: [MatIconModule],
    providers: [],
    exports: [NoDataComponent],
})
export class DuiNoDataModule {}
