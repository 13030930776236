export enum CountryCode {
    UK = "UK",
    GB = "GB",
    JE = "JE",
    GG = "GG",
    IM = "IM",
    US = "US",
    CA = "CA",
    IE = "IE",
    DE = "DE",
    JP = "JP",
    FR = "FR",
    AU = "AU",
    IT = "IT",
    CH = "CH",
    AT = "AT",
    ES = "ES",
    NL = "NL",
    BE = "BE",
    DK = "DK",
    SE = "SE",
    NO = "NO",
    BR = "BR",
    PT = "PT",
    FI = "FI",
    AX = "AX",
    KR = "KR",
    CN = "CN",
    TW = "TW",
    SG = "SG",
    DZ = "DZ",
    AD = "AD",
    AR = "AR",
    AM = "AM",
    AZ = "AZ",
    BH = "BH",
    BD = "BD",
    BB = "BB",
    BY = "BY",
    BM = "BM",
    BA = "BA",
    IO = "IO",
    BN = "BN",
    BG = "BG",
    KH = "KH",
    CV = "CV",
    CL = "CL",
    CR = "CR",
    HR = "HR",
    CY = "CY",
    CZ = "CZ",
    DO = "DO",
    EC = "EC",
    EG = "EG",
    EE = "EE",
    FO = "FO",
    GE = "GE",
    GR = "GR",
    GL = "GL",
    GT = "GT",
    HT = "HT",
    HN = "HN",
    HU = "HU",
    IS = "IS",
    IN = "IN",
    ID = "ID",
    IL = "IL",
    JO = "JO",
    KZ = "KZ",
    KE = "KE",
    KW = "KW",
    KY = "KY",
    LA = "LA",
    LV = "LV",
    LB = "LB",
    LI = "LI",
    LT = "LT",
    LU = "LU",
    MK = "MK",
    MY = "MY",
    MV = "MV",
    MT = "MT",
    MU = "MU",
    MX = "MX",
    MD = "MD",
    MC = "MC",
    MA = "MA",
    NP = "NP",
    NZ = "NZ",
    NI = "NI",
    NG = "NG",
    OM = "OM",
    PA = "PA",
    PK = "PK",
    PY = "PY",
    PH = "PH",
    PL = "PL",
    PR = "PR",
    RO = "RO",
    RU = "RU",
    SM = "SM",
    SA = "SA",
    SN = "SN",
    SK = "SK",
    SI = "SI",
    ZA = "ZA",
    LK = "LK",
    TJ = "TJ",
    TH = "TH",
    TN = "TN",
    TR = "TR",
    TM = "TM",
    UA = "UA",
    UY = "UY",
    UZ = "UZ",
    VA = "VA",
    VE = "VE",
    ZM = "ZM",
    AS = "AS",
    CC = "CC",
    CK = "CK",
    RS = "RS",
    ME = "ME",
    CS = "CS",
    YU = "YU",
    CX = "CX",
    ET = "ET",
    FK = "FK",
    NF = "NF",
    FM = "FM",
    GF = "GF",
    GN = "GN",
    GP = "GP",
    GS = "GS",
    GU = "GU",
    GW = "GW",
    HM = "HM",
    IQ = "IQ",
    KG = "KG",
    LR = "LR",
    LS = "LS",
    MG = "MG",
    MH = "MH",
    MN = "MN",
    MP = "MP",
    MQ = "MQ",
    NC = "NC",
    NE = "NE",
    VI = "VI",
    VN = "VN",
    PF = "PF",
    PG = "PG",
    PM = "PM",
    PN = "PN",
    PW = "PW",
    RE = "RE",
    SH = "SH",
    SJ = "SJ",
    SO = "SO",
    SZ = "SZ",
    TC = "TC",
    WF = "WF",
    XK = "XK",
    YT = "YT",
    PE = "PE",
    INTL = "INTL",
}
