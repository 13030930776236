import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MenuListItem } from "./menu-list.interface";

@Component({
    selector: "dui-menu-list",
    templateUrl: "./menu-list.component.html",
    styleUrls: ["./menu-list.component.scss"],
})
export class MenuListComponent<T> {
    @Input() public options: MenuListItem<T>[];
    @Input() public isLoading: boolean;
    @Input() public innerTitle: string;
    @Output() public itemSelect = new EventEmitter<T>();

    public onClickButton(value: T): void {
        this.itemSelect.emit(value);
    }
}
