import { Component } from "@angular/core";
import { commonUrls } from "@core/constants/url.constant";

@Component({
    selector: "dbp-portal-footer",
    templateUrl: "./portal-footer.component.html",
    styleUrls: ["./portal-footer.component.scss"],
})
export class PortalFooterComponent {
    public readonly commonUrls = commonUrls;
}
