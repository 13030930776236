import { TimeframeLabel, TimeframeSettingsOption } from "@core/enums/timeframes.enum";

export const DEFAULT_TIMEFRAMES = {
    value: TimeframeSettingsOption.Last30Days,
    list: <TimeframeSettingsOption[]>[
        TimeframeSettingsOption.Today,
        TimeframeSettingsOption.Yesterday,
        TimeframeSettingsOption.Last7Days,
        TimeframeSettingsOption.Last30Days,
        TimeframeSettingsOption.ThisMonth,
        TimeframeSettingsOption.LastMonth,
        TimeframeSettingsOption.Custom,
    ],
};

export const TIMEFRAME_DATA_PARAMETERS = {
    value: "timeframeValue",
    options: "timeframeOptions",
};

export const TIME_FRAME_MAX_DAYS_RANGE = 90;
export const TIMEFRAME_DROPDOWN_OPTIONS = [
    {
        id: TimeframeSettingsOption.Today,
        name: TimeframeLabel.Today,
    },
    {
        id: TimeframeSettingsOption.Yesterday,
        name: TimeframeLabel.Yesterday,
    },
    {
        id: TimeframeSettingsOption.Last7Days,
        name: TimeframeLabel.Last7Days,
    },
    {
        id: TimeframeSettingsOption.Last30Days,
        name: TimeframeLabel.Last30Days,
    },
    {
        id: TimeframeSettingsOption.ThisMonth,
        name: TimeframeLabel.ThisMonth,
    },
    {
        id: TimeframeSettingsOption.LastMonth,
        name: TimeframeLabel.LastMonth,
    },
    {
        id: TimeframeSettingsOption.Custom,
        name: TimeframeLabel.Custom,
    },
    {
        id: TimeframeSettingsOption.Last1hour,
        name: TimeframeLabel.Last1hour,
    },
    {
        id: TimeframeSettingsOption.Last6hours,
        name: TimeframeLabel.Last6hours,
    },
    {
        id: TimeframeSettingsOption.Last12hours,
        name: TimeframeLabel.Last12hours,
    },
    {
        id: TimeframeSettingsOption.Last24hours,
        name: TimeframeLabel.Last24hours,
    },
    {
        id: TimeframeSettingsOption.Last48hours,
        name: TimeframeLabel.Last48hours,
    },
    {
        id: TimeframeSettingsOption.LastWeek,
        name: TimeframeLabel.LastWeek,
    },
    {
        id: TimeframeSettingsOption.ThisWeek,
        name: TimeframeLabel.ThisWeek,
    },
    {
        id: TimeframeSettingsOption.ThisQuarter,
        name: TimeframeLabel.ThisQuarter,
    },
    {
        id: TimeframeSettingsOption.LastQuarter,
        name: TimeframeLabel.LastQuarter,
    },
    {
        id: TimeframeSettingsOption.ThisYear,
        name: TimeframeLabel.ThisYear,
    },
    {
        id: TimeframeSettingsOption.LastYear,
        name: TimeframeLabel.LastYear,
    },
];
