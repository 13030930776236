import { Component, Input } from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import { MinimumFileInfo } from "./minimum-file-info.interface";

enum ErrorType {
    Size,
    Extension,
    Length,
}

@Component({
    selector: "dui-upload-files",
    templateUrl: "./upload-files.component.html",
    styleUrls: ["./upload-files.component.scss"],
})
export class UploadFilesComponent {
    @Input() control: FormControl<(File | MinimumFileInfo)[]>;
    @Input() placeholder = "Click to upload";
    @Input() label: string;
    @Input() isMultipleFileUpload = false;
    @Input() maxFileSize = 20000000; // Value in bytes. 0 === any size allowed
    @Input() maxFilenameLength = 250; // 0 === any length allowed
    // Empty array === any extension allowed
    @Input() allowedFileExtensions: string[] = ["docx", "doc", "xlsx", "xls", "pptx", "ppt", "pdf"];

    public ErrorType = ErrorType;

    // List of validation errors to display for user
    public errorFiles: {
        file: File;
        errorType: ErrorType;
    }[] = [];

    // true if control has 'required' validator
    get isRequired(): boolean {
        const validator =
            this.control && this.control.validator
                ? this.control.validator({} as AbstractControl)
                : null;
        return validator && validator.required;
    }

    public onFilesAdded(newFiles: File[]): void {
        this.errorFiles = [];
        const validFiles: File[] = [];

        for (const file of newFiles) {
            if (!this.isAvailableBySize(file.size)) {
                this.errorFiles = [...this.errorFiles, { file, errorType: ErrorType.Size }];
            } else if (!this.isAvailableByExtension(file.name)) {
                this.errorFiles = [...this.errorFiles, { file, errorType: ErrorType.Extension }];
            } else if (!this.isAvailableByLengthName(file.name)) {
                this.errorFiles = [...this.errorFiles, { file, errorType: ErrorType.Length }];
            } else {
                validFiles.push(file);
            }
        }

        if (this.isMultipleFileUpload) {
            // Add files to existing ones
            this.control.setValue([...(this.control.value || []), ...validFiles]);
        } else {
            // Rewrite existing file with the new one
            this.control.setValue([...validFiles]);
        }

        this.control.markAsTouched();
        this.control.markAsDirty();
    }

    public onFileRemoved(index: number): void {
        this.errorFiles = [];
        const newValue = [...(this.control.value || [])];
        newValue.splice(index, 1);

        this.control.setValue(newValue);
        this.control.markAsTouched();
        this.control.markAsDirty();
    }

    private isAvailableBySize(size: number): boolean {
        return this.maxFileSize === 0 || size < this.maxFileSize;
    }

    private isAvailableByExtension(filename: string): boolean {
        const extension = this.getExtensionFromName(filename);
        return (
            this.allowedFileExtensions.length === 0 ||
            this.allowedFileExtensions.includes(extension)
        );
    }

    private isAvailableByLengthName(name: string): boolean {
        return this.maxFilenameLength === 0 || name.length <= this.maxFilenameLength;
    }

    private getExtensionFromName(filename: string): string {
        const splittedName = filename.split(".");
        if (splittedName.length === 1) return ""; // filename does not have an extension
        return splittedName[splittedName.length - 1];
    }
}
