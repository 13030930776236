import { Injectable } from "@angular/core";
import { Router, CanLoad, CanActivateChild, CanActivate } from "@angular/router";
import { Observable } from "rxjs";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { LoggerService } from "@core/services/logger.service";
import { map, take } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class UnAuthGuard implements CanLoad, CanActivateChild, CanActivate {
    constructor(
        private router: Router,
        private authStorageService: AuthStorageService,
        private loggerService: LoggerService
    ) {}

    canLoad(): Observable<boolean> {
        this.loggerService.log("[UnAuthGuard] CanLoad Init");
        return this.isLoggedIn();
    }

    canActivateChild(): Observable<boolean> {
        this.loggerService.log("[UnAuthGuard] CanActivateChild Init");
        return this.isLoggedIn();
    }

    canActivate(): Observable<boolean> {
        this.loggerService.log("[UnAuthGuard] CanActivate Init");
        return this.isLoggedIn();
    }

    isLoggedIn(): Observable<boolean> {
        return this.authStorageService.isAuthorized$().pipe(
            take(1),
            map((isLoggedIn: boolean) => {
                if (!isLoggedIn) {
                    this.loggerService.log("[UnAuthGuard] Is not Authorize");
                    return true;
                }
                this.router.navigate(["/home"]);
                return false;
            })
        );
    }
}
