import { IEnvironment } from "@core/interfaces/environment.interface";

export const environment: IEnvironment = {
    production: true,
    protocol: "https",
    hostname: "StagePartnersAPI.databank.com",
    debug: false,
    featureFlags: {
        manageRolesEdit: true,
    },
    idle: {
        sessionTime: 780,
        warningTime: 120,
        tokenLifeTime: 900,
    },
};
