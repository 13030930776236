import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { MAT_TABS_CONFIG } from "@angular/material/tabs";
import { CoreModule } from "@core/core.module";
import { DuiCoreModule, DuiValidationErrorModule } from "@databank-ui";
import { NgxMaskModule } from "ngx-mask";
import { NgLetModule } from "ng-let";
import { AttachmentMapService } from "@shared/services/attachment-map.service";
import { PasswordManagerComponent } from "./components/password-manager/password-manager.component";
import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { MATERIAL_MODULES } from "./constants/material.constant";

@NgModule({
    declarations: [ProgressBarComponent, PasswordManagerComponent],
    imports: [
        CoreModule,
        CommonModule,
        ...MATERIAL_MODULES,
        DuiValidationErrorModule,
        // Shared modules
        // Third-party modules
        NgxMaskModule.forRoot({ validation: false }),
        DuiCoreModule,
        NgLetModule,
    ],
    exports: [
        CommonModule,
        ...MATERIAL_MODULES,
        ProgressBarComponent,
        PasswordManagerComponent,
        // Third-party modules
        NgxMaskModule,
        DuiCoreModule,
        NgLetModule,
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: "outline", floatLabel: "always" },
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { horizontalPosition: "right", verticalPosition: "top" },
        },
        { provide: MAT_TABS_CONFIG, useValue: { animationDuration: "0ms" } },
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { disableOptionCentering: true },
        },
        AttachmentMapService,
    ],
})
export class SharedModule {}
