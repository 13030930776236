<div>
    <div class="dui-dialog-header">
        <span class="dui-dialog-title flexible">Custom Settings</span>
    </div>
    <div class="dui-dialog-content" mat-dialog-content>
        <div class="edge-form-group column top-bar-date" [formGroup]="dateForm">
            <div class="form-group-ctrl">
                <mat-form-field>
                    <mat-label>Start Date</mat-label>
                    <input id="top-bar-date-from__input"
                           matInput
                           formControlName="dateFrom"
                           placeholder="Select Start Date"
                           [matDatepicker]="pickerFrom"
                           [min]="minDate"
                           [max]="selectedToValue"
                           readonly
                    />
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                    <mat-error>
                        <dui-validation-error [control]="dateForm.controls.dateFrom"></dui-validation-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group-ctrl">
                <mat-form-field>
                    <mat-label>End Date</mat-label>
                    <input id="top-bar-date-to__input"
                           matInput
                           formControlName="dateTo"
                           placeholder="Select End Date"
                           [matDatepicker]="pickerTo"
                           [min]="selectedFromValue"
                           [max]="maxDate"
                           readonly
                    />
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                    <mat-error>
                        <dui-validation-error [control]="dateForm.controls.dateTo"></dui-validation-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="dui-dialog-footer" mat-dialog-actions>
        <button id="top-bar-date-cancel__button"
                mat-flat-button color="primary"
                (click)="close()"
        >Cancel</button>
        <button id="top-bar-date-apply__button"
                mat-flat-button
                color="primary"
                [disabled]="dateForm.invalid"
                (click)="submit()"
        >Apply</button>
    </div>
</div>
