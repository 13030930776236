import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, combineLatest } from "rxjs";
import { filter, map, takeUntil } from "rxjs/operators";
import { ISelectOption } from "@databank-ui";
import { webApiUrls } from "@core/constants/url.constant";
import { IPartnerDTOIn, PartnerParamToApi } from "@shared/interfaces/partner.interface";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { IAgentForm } from "../../modules/auth/modules/register/interfaces/register.interface";

const DEFAULT_PARTNER_ID = "";

@Injectable({
    providedIn: "root",
})
export class PartnersService implements OnDestroy {
    public selected$: Observable<string>;
    private displayAllOption = new BehaviorSubject<boolean>(true);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public list$: Observable<ISelectOption[]> = combineLatest([
        this.getPartnersRequest({ isApproved: true }),
        this.displayAllOption,
    ]).pipe(
        map(([data, displayAllOption]) => {
            return displayAllOption
                ? this.addAllOptionToList(data)
                : this.removeAllOptionFromList(data);
        })
    );

    private selected: BehaviorSubject<string> = new BehaviorSubject(DEFAULT_PARTNER_ID);
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private http: HttpClient,
        private auth: AuthStorageService,
        private settings: SettingStorageService
    ) {
        this.selected$ = this.selected.asObservable();
        this.settings.updateSetting({ partner: DEFAULT_PARTNER_ID });

        // process user session
        this.auth
            .isAuthorized$()
            .pipe(
                takeUntil(this.destroy$),
                filter(value => !value)
            )
            .subscribe(() => this.setValue(DEFAULT_PARTNER_ID));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getValue(): string {
        return this.selected.value;
    }

    setValue(value: string): void {
        this.selected.next(value);
        this.settings.updateSetting({ partner: value });
    }

    public createPartner(params: IAgentForm): Observable<string> {
        const path = `${webApiUrls.base}/partner/create`;
        return this.http
            .post(path, params, { responseType: "text" })
            .pipe(map(id => id.slice(1, -1))); // We receive in response GUID with extra " symbol in the beginning and in the end
    }

    public getMasterAgentPartners(): Observable<ISelectOption[]> {
        return this.getPartnersRequest({ isMasterAgentCase: true });
    }

    public getPartners(): Observable<ISelectOption[]> {
        return this.getPartnersRequest().pipe(map(data => this.addAllOptionToList(data)));
    }

    public getPartnersRequest(params?: PartnerParamToApi): Observable<ISelectOption[]> {
        const path = `${webApiUrls.base}/partner/list`;
        return this.http
            .get<IPartnerDTOIn[]>(path, {
                params: { ...params },
            })
            .pipe(
                map(data => {
                    return data.map(partner => ({
                        value: partner.partnerId,
                        label: partner.name,
                    }));
                })
            );
    }

    public removeAllOption(): void {
        this.displayAllOption.next(false);
    }

    public addAllOption(): void {
        this.displayAllOption.next(true);
    }

    private addAllOptionToList(data: ISelectOption[]): ISelectOption[] {
        if (!data.find(item => item.value === DEFAULT_PARTNER_ID)) {
            data.unshift({
                value: DEFAULT_PARTNER_ID,
                label: "All",
            });
        }
        return [...data];
    }

    private removeAllOptionFromList(data: ISelectOption[]): ISelectOption[] {
        if (data.find(item => item.value === DEFAULT_PARTNER_ID)) {
            data.shift();
        }
        return [...data];
    }
}
