<div class="filter-area">
    <mat-form-field>
        <mat-label>Search</mat-label>
        <input
            class="widget-search__input"
            matInput
            [maxlength]="isNoLimit ? null : maxLength"
            placeholder="Search..."
            (keyup.enter)="onSearchClicked()"
            [formControl]="control"
        >
    </mat-form-field>

    <div class="filter-controls">
        <button mat-flat-button (click)="onSearchClicked()">Search</button>
    </div>
</div>
