import { Component, Input } from "@angular/core";

import { DEFAULT_OPTIONS } from "./progress.metadata";
import { ThousandthPipe } from "../../core";

@Component({
    selector: "dui-progress",
    templateUrl: "./progress.component.html",
    styleUrls: ["./progress.component.scss"],
    providers: [ThousandthPipe],
})
export class ProgressComponent {
    @Input() value: number = DEFAULT_OPTIONS.value;
    @Input() limit: number = DEFAULT_OPTIONS.limit;
    @Input() type: string = DEFAULT_OPTIONS.type;
    @Input() staticColor: string = DEFAULT_OPTIONS.staticColor;
    @Input() titleName: string = DEFAULT_OPTIONS.title;
    @Input() customLabel?: string;
    @Input() progressTitle?: string;

    constructor(private thousandthPipe: ThousandthPipe) {}

    get label(): string {
        return this.customLabel
            ? this.customLabel
            : DEFAULT_OPTIONS.label(this.type)(
                  this.thousandthPipe.transform(this.value),
                  this.thousandthPipe.transform(this.limit)
              );
    }

    get width(): number {
        return Math.floor((this.value * 100) / this.limit);
    }

    get colorClass(): string {
        if (this.type !== "PERCENTAGE" || this.staticColor) {
            return this.staticColor;
        }

        if (this.width <= 60) {
            return "progress-color-success";
        }

        if (this.width > 60 && this.width <= 80) {
            return "progress-color-warn";
        }

        return "progress-color-error";
    }
}
