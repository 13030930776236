/* eslint-disable no-useless-escape */
import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import cardValidator from "card-validator";
import { postalCodesMap } from "../constants";
import { CountryCode } from "../enums";

export const VALIDATION_OPTIONS = {
    PASSWORD_MINLENGTH: 8,
    USERNAME_MINLENGTH: 8,
};

@Injectable({
    providedIn: "root",
})
export class ValidationService {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static getValidatorErrorMessage(validatorName: string, validatorValue?: any): string {
        const config = {
            required: "This field is required",
            email: "Please enter a valid email",
            number: "Please enter numbers only",
            maxlength: `Must contain no more than ${validatorValue.requiredLength} characters`,
            minlength: `Must contain at least ${validatorValue.requiredLength} characters`,
            min: `Must be greater than or equal to ${validatorValue.min}`,
            max: `Must be less than or equal to ${validatorValue.max}`,
            passwordLength: "Password is too short",
            passwordMatch: "New password and confirm do not match",
            passwordOld: "New password cannot be the same as the old password",
            passwordRequirements: "Password does not meet requirements",
            phone: "Please enter a valid phone number",
            invalidEndDate: "Please enter a date after the start date",
            postalCode: "Please enter a valid zip code",
            ssnMismatch: "Social Security Numbers do not match",
            streetAddress: "Please enter a residential address",
            passwordsMismatch: "New password and confirm do not match",
            oldPasswordNotMatch: "Does not match current password",
            newPasswordIsDuplicated: "You can not reuse your current password",
            newPasswordIsDuplicatedServer:
                "You can not reuse your previous 24 passwords. Try a different one",
            creditCard: "Invalid credit card number",
            expDate: "Invalid expiration date",
            forbiddenValue: "Such value already exists",
            startTime: "Start time is higher than end time",
            endTime: "End time is lower than start time",
            pattern: "Please enter a valid value",
            ip: "Please enter a valid IP address",
            userNameDuplicated: "Username already in use. Please specify a unique username",
            incorrectDomain: "Domain to Secure has invalid domain",
            incorrectDomainWildcard:
                "A wildcard certificate domain name must begin with an asterisk",
            cidr: "The IP address/CIDR entered is not valid.",
            wordAnyInBoth:
                "DataBank security policies do not allow for the creation of any<->any rules on the firewall. Please enter either a source IP address/CIDR or a destination IP address/CIDR.",
            notDomain:
                "The value entered is not a valid domain name. Please enter a valid domain name: Example: databank.com",
            whitespace: "Please do not use spaces",
            securityCode: "Must be exactly 6 digits",
            url: "Please enter a valid URL",
            validatePhoneNumber: "Please enter a valid phone number",
        };

        return config[validatorName];
    }

    static patternValidation(pattern: string, skipEmpty: boolean = false): ValidatorFn {
        const patterns = {
            postalCode: /\d{5}(-\d{4})?/,
            streetAddress: /^(\d+)\s?([A-Za-z])+\s?([A-Za-z])+/i,
            // 1+ digit, 1+ lowercase, 1+ uppercase, 1+ special
            password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])/, // (?=.{8,})/,
            // 6 digits
            securityCode: /^\d{6}$/,
        };

        return (control: AbstractControl): { [key: string]: any } | null => {
            if (skipEmpty && (control.value === null || control.value === "")) {
                return null;
            }
            return patterns[pattern].test(control.value)
                ? null
                : { [pattern]: { value: control.value } };
        };
    }

    // Validator for email which SalesForce uses
    static sfEmailValidator(control: AbstractControl): ValidationErrors | null {
        const firstRegEx = /^[A-z0-9._%+\-/!#$%&'*=?^_`{|}~]+@[A-z0-9.-]+\.[A-z]{2,4}$/;
        const secondRegEx =
            /([a-zA-Z0-9_\-\.]+)@((\[a-z]{1,3}\.[a-z]{1,3}\.[a-z]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})/;

        if (!control || !control.value) return null;

        return control.value.match(firstRegEx) || control.value.match(secondRegEx)
            ? null
            : { email: true };
    }

    static numberFormatValidator(control: AbstractControl): ValidationErrors | null {
        const numberRegEx = /^\d+$/;
        if (!control || !control.value) {
            return null;
        }

        return control.value.match(numberRegEx) ? null : { number: true };
    }

    static postalCodeFormatValidator(countryCode: CountryCode): ValidatorFn {
        const regExp = postalCodesMap.get(countryCode);
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control || !control.value || !regExp) return null;

            return control.value.match(regExp) ? null : { postalCode: true };
        };
    }

    static streetAddressFormatValidator(control: AbstractControl): ValidationErrors | null {
        const streetAddressRegEx = /^(\d+)\s?([A-Za-z])+\s?([A-Za-z])+/i;
        if (!control || !control.value) {
            return null;
        }

        return control.value.match(streetAddressRegEx) ? null : { streetAddress: true };
    }

    static passwordFormatValidator(control: AbstractControl): ValidationErrors | null {
        const passwordRegEx = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])";
        if (!control || !control.value) {
            return null;
        }

        return control.value.match(passwordRegEx) ? null : { passwordRequirements: true };
    }

    static passwordComplexityValidator(control: AbstractControl): ValidationErrors | null {
        const validationRules = [
            "[A-Z]+", // English uppercase characters (A-Z)
            "[a-z]+", // English lowercase characters (a-z)
            "[0-9]+", // Numeric digits (0-9)
            "\\W|_+", // Non-alphanumeric characters (for example: !, $, # or %)
        ];
        if (!control || !control.value) {
            return null;
        }

        let matchesCount = 0;
        validationRules.forEach(rule => {
            if (control.value.match(rule)) matchesCount++;
        });

        return matchesCount >= 3 ? null : { passwordRequirements: true };
    }

    static LOAValidation(control: AbstractControl): ValidationErrors | null {
        if (
            !control?.value?.length ||
            (control?.value?.length && !control?.value.find(file => !file?.isDeleted))
        ) {
            return { invalid: true };
        }

        return null;
    }

    static MatchPasswords(
        oldPassword: string,
        newPassword: string,
        confirmPassword: string
    ): ValidatorFn {
        // eslint-disable-next-line consistent-return
        return (formGroup: AbstractControl): any => {
            const oldPasswordControl = (formGroup as FormGroup).controls[oldPassword];
            const newPasswordControl = (formGroup as FormGroup).controls[newPassword];
            const confirmPasswordControl = (formGroup as FormGroup).controls[confirmPassword];

            if (!oldPasswordControl || !newPasswordControl || !confirmPasswordControl) {
                return null;
            }

            if (newPasswordControl.value && oldPasswordControl.value === newPasswordControl.value) {
                newPasswordControl.setErrors({ newPasswordIsDuplicated: true });
            } else if (newPasswordControl.hasError("newPasswordIsDuplicated")) {
                newPasswordControl.setErrors(null);
            }

            if (newPasswordControl.value !== confirmPasswordControl.value) {
                confirmPasswordControl.setErrors({ passwordsMismatch: true });
            } else if (confirmPasswordControl.hasError("passwordsMismatch")) {
                confirmPasswordControl.setErrors(null);
            }
        };
    }

    static MatchNewPasswords(newPassword: string, confirmPassword: string) {
        // eslint-disable-next-line consistent-return
        return (formGroup: FormGroup): ValidationErrors | null => {
            const newPasswordControl = formGroup.controls[newPassword];
            const confirmPasswordControl = formGroup.controls[confirmPassword];

            if (!newPasswordControl || !confirmPasswordControl) {
                return null;
            }

            if (newPasswordControl.value !== confirmPasswordControl.value) {
                confirmPasswordControl.setErrors({ passwordsMismatch: true });
            } else if (confirmPasswordControl.hasError("passwordsMismatch")) {
                confirmPasswordControl.setErrors(null);
            }
        };
    }

    static CheckIpInputsForAny(firstInput: string, secondInput: string) {
        // eslint-disable-next-line consistent-return
        return (formGroup: FormGroup): void | null => {
            const firstControl = formGroup.controls[firstInput];
            const secondControl = formGroup.controls[secondInput];

            if (!firstControl || !secondControl) {
                return null;
            }

            if (firstControl.value === "any" && secondControl.value === "any") {
                secondControl.setErrors({ wordAnyInBoth: true });
            } else if (secondControl.hasError("wordAnyInBoth")) {
                secondControl.setErrors(null);
            }
        };
    }

    static userNameUniqueness(controlName: string, userNames: string[]) {
        // eslint-disable-next-line consistent-return
        return (formGroup: FormGroup): void | null => {
            const userNameControl = formGroup.controls[controlName];

            if (!userNameControl) {
                return null;
            }

            if (userNames.includes(userNameControl.value)) {
                userNameControl.setErrors({ userNameDuplicated: true });
            } else if (userNameControl.hasError("userNameDuplicated")) {
                userNameControl.setErrors(null);
            }
        };
    }

    static oldPasswordValidator(value: boolean) {
        return (): ValidationErrors | null => {
            return value ? { oldPasswordNotMatch: true } : null;
        };
    }

    static newPasswordValidator(value: boolean) {
        return (): ValidationErrors | null => {
            return value ? { newPasswordIsDuplicatedServer: true } : null;
        };
    }

    static expirationDateValidator(control: AbstractControl): ValidationErrors | null {
        if (control.value?.length >= 5) {
            const [month, year] = control.value.split(/[\s/]+/, 2);

            if (/^\d+$/.test(month) && /^\d{4}$/.test(year) && month >= 1 && month <= 12) {
                const expiry = new Date(year, month, 1);
                const currentTime = new Date();
                const maxExpiry = new Date();
                maxExpiry.setFullYear(maxExpiry.getFullYear() + 10);

                if (expiry > currentTime && expiry < maxExpiry) {
                    return null;
                }
            }
        }

        return { expDate: true };
    }

    static CCValidator(control: AbstractControl): ValidationErrors | null {
        if (control.value != null && control.value !== "") {
            const isCardValid = cardValidator.number(control.value).isValid;
            return isCardValid ? null : { creditCard: true };
        }
        return null;
    }

    static updateValueAndValidity(form: FormGroup, emitEvent: boolean = true): void {
        for (const controlName in form.controls) {
            if (Object.prototype.hasOwnProperty.call(form.controls, controlName)) {
                form.controls[controlName].updateValueAndValidity({ emitEvent });
            }
        }
    }

    static exclusionValidation(
        // eslint-disable-next-line default-param-last
        list: string[] = [],
        name: string // key name for getMessage()
    ): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return list.includes(control.value) ? { [name]: true } : null;
        };
    }

    static IPValidator(control: AbstractControl): ValidationErrors | null {
        const ipRegEx = "^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.|$)){4}$";
        if (!control || !control.value) {
            return null;
        }

        return control.value.match(ipRegEx) ? null : { ip: true };
    }

    static IPWithOptionalCIDRListValidator(control: AbstractControl): ValidationErrors | null {
        const ipRegEx = "^([0-9]{1,3}\\.){3}[0-9]{1,3}(\\/([0-9]|[1-2][0-9]|3[0-2]))?$";
        if (!control || !control.value || control.value === "any") {
            return null;
        }

        const hasErrors = control.value
            .split(",")
            .map(ip => ip.trim())
            .find(ip => !ip.match(ipRegEx));

        return hasErrors ? { cidr: true } : null;
    }

    static IPWithCIDRListValidator(control: AbstractControl): ValidationErrors | null {
        const ipRegEx = "^([0-9]{1,3}\\.){3}[0-9]{1,3}(\\/([0-9]|[1-2][0-9]|3[0-2]))$";
        if (!control || !control.value) {
            return null;
        }

        const hasErrors = control.value
            .split(",")
            .map(ip => ip.trim())
            .find(ip => !ip.match(ipRegEx));

        return hasErrors ? { cidr: true } : null;
    }

    static IPWithCIDRValidator(control: AbstractControl): ValidationErrors | null {
        const ipRegEx = "^([0-9]{1,3}\\.){3}[0-9]{1,3}(\\/([0-9]|[1-2][0-9]|3[0-2]))$";
        if (!control || !control.value) {
            return null;
        }

        const hasErrors = control.value.match(ipRegEx);

        return !hasErrors ? { cidr: true } : null;
    }

    static domainValidator(isWildcard: boolean, isAstericsAllowed: boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let pattern = "([a-zA-Z0-9_]([a-zA-Z0-9-_]{0,61}[a-zA-Z0-9])?\\.)+";

            const wildcardPattern = `(\\*\\.)${pattern}(([a-zA-Z]{2,})|(\\*)){1}`;
            const notWildcardPattern = `${pattern}[a-zA-Z]{2,}`;
            const astericsAllowedPattern = `(\\*\\.)?${notWildcardPattern}`;
            const { value } = control;

            if (!value?.length) return null;

            pattern = notWildcardPattern;

            if (isWildcard) {
                pattern = wildcardPattern;
            }
            if (isAstericsAllowed) {
                pattern = astericsAllowedPattern;
            }

            const regexp = new RegExp(`^${pattern}$`);

            if (isWildcard) {
                return value.indexOf("*") === 0 && regexp.test(value) ? null : { notDomain: true };
            }

            return regexp.test(value) ? null : { notDomain: true };
        };
    }

    static noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
        const isWhitespace = (control?.value?.toString() || "").match(/\s/);
        return isWhitespace ? { whitespace: true } : null;
    }

    static maxLengthWithoutTags(maxLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control || !control.value) {
                return null;
            }
            const valueWithoutTags = control.value.replace(/(<([^>]+)>)/gi, "");

            return valueWithoutTags.length > maxLength
                ? { maxlength: { requiredLength: maxLength } }
                : null;
        };
    }

    static URLValidator(control: AbstractControl): ValidationErrors | null {
        const ipRegEx =
            /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
        if (!control || !control.value) {
            return null;
        }

        return control.value.match(ipRegEx) ? null : { url: true };
    }
}
