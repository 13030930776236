import { Attachment, MappedAttachment } from "@shared/interfaces/attachment.interface";
import { Injectable } from "@angular/core";

@Injectable()
export class AttachmentMapService {
    mapAttachmentsFromApi(attachments: Attachment[]): MappedAttachment[] {
        return attachments.map(a => ({
            attachmentId: a.attachmentId,
            name: a.fileName,
            size: a.fileSize,
        }));
    }
}
