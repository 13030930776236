import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe as AngularDatePipe } from "@angular/common";
import { cultures } from "../constants/cultures.constant";

@Pipe({ name: "duiDate" })
export class DatePipe extends AngularDatePipe implements PipeTransform {
    transform(value: Date | string | number): string | null;
    transform(value: null | undefined): null;
    transform(value: Date | string | number | null | undefined): string | null;
    transform(value: Date | string | number, culture?: string, timezone?: string): string {
        const dateFormat = culture
            ? cultures.cultureData.find(({ currentCulture }) => currentCulture === culture)
                  .dateFormat
            : cultures.cultureData.find(({ currentCulture }) => currentCulture === "en-US")
                  .dateFormat;

        return super.transform(value, dateFormat, timezone);
    }
}
