import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import * as Quill from "quill";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "dui-wysiwyg",
    templateUrl: "./wysiwyg.component.html",
    styleUrls: ["./wysiwyg.component.scss"],
})
export class WYSIWYGComponent implements OnInit, OnDestroy {
    @Input() control: FormControl<string>;
    @Input() label: string;
    @Input() placeholder = "Input text";

    public config = {
        toolbar: [
            [{ size: ["10px", false, "18px", "32px"] }],
            [{ font: [] }],
            ["bold", "italic", "underline", { color: [] }, { background: [] }],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] },
            ],
            ["image"],
            ["clean"],
        ],
    };

    private destroy$ = new Subject<void>();

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        const AlignStyle = Quill.import("attributors/style/align");
        const SizeStyle = Quill.import("attributors/style/size");
        const FontStyle = Quill.import("attributors/style/font");
        Quill.register(AlignStyle, true);
        Quill.register(SizeStyle, true);
        Quill.register(FontStyle, true);

        // Need to explicitly trigger CD on value changes, because ngx-quill
        // doesn't support ChangeDetectionStrategy.OnPush until version 14.3.0
        // Angular 10 version is required to update to this version or higher
        this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.cdr.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get isRequired(): boolean {
        const validator =
            this.control && this.control.validator
                ? this.control.validator({} as AbstractControl)
                : null;
        return validator && validator.required;
    }

    @HostBinding("class.ng-invalid")
    get isErrorVisible(): boolean {
        return this.control && this.control.invalid && this.control.touched;
    }
}
