/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({ name: "duiTime" })
export class TimePipe extends DatePipe implements PipeTransform {
    transform(value: Date | string | number): string | null;
    transform(value: null | undefined): null;
    transform(value: Date | string | number | null | undefined): string | null;
    transform(value: Date | string | number, args?: any): string {
        return super.transform(value, "hh:mm a", args);
    }
}
