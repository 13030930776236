import { ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";

@Component({
    selector: "dui-paginator",
    templateUrl: "./paginator.component.html",
    styleUrls: ["./paginator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent {
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    @Input() totalItems: number;
    @Input() itemsPerPage: number;
    @Input() page: number;
}
