import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { NotifyAreaComponent } from "./notify-area.component";

@NgModule({
    declarations: [NotifyAreaComponent],
    imports: [CommonModule, MatIconModule],
    exports: [NotifyAreaComponent],
})
export class DuiNotifyAreaModule {}
