import { Injectable } from "@angular/core";
import {
    Router,
    CanLoad,
    CanActivateChild,
    ActivatedRouteSnapshot,
    Route,
    ActivatedRoute,
} from "@angular/router";
import { LoggerService } from "@core/services/logger.service";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { UserApiService } from "@core/services/user-api.service";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanLoad, CanActivateChild {
    constructor(
        private router: Router,
        private activeRouter: ActivatedRoute,
        private authStorageService: AuthStorageService,
        private userApiService: UserApiService,
        private loggerService: LoggerService
    ) {}

    canLoad(route: Route): Observable<boolean> {
        this.loggerService.log("[AuthGuard] CanLoad Init", route);
        return this.isLoggedIn(route.data?.skipGuard);
    }

    canActivateChild(route: ActivatedRouteSnapshot): Observable<boolean> {
        this.loggerService.log("[AuthGuard] CanActivateChild Init");
        return this.isLoggedIn(route.data.skipGuard);
    }

    isLoggedIn(skipGuard: string): Observable<boolean> {
        return this.authStorageService.selectAuth$().pipe(
            take(1),
            switchMap(isAuth => {
                return isAuth?.accessToken
                    ? this.userApiService.hasUserProfile().pipe(take(1))
                    : of(false);
            }),
            map(profile => {
                // Check user with PasswordExpired
                if (this.authStorageService.isPasswordExpired() && !skipGuard) {
                    this.loggerService.log("[AuthGuard] PasswordExpired");
                    this.router.navigate(["/reset-password"]);
                    return false;
                }

                if (profile) {
                    this.loggerService.log("[AuthGuard] Allow");
                    return true;
                }
                this.loggerService.log("[AuthGuard] Deny");
                this.router.navigate(["/auth/login"]);
                return false;
            })
        );
    }
}
