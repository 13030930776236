import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { TitleService } from "@core/services/title.service";

@Component({
    selector: "dbp-top-bar-title",
    templateUrl: "top-bar-title.component.html",
})
export class TopBarTitleComponent {
    title$: Observable<string> = this.titleService.title$;

    constructor(private titleService: TitleService) {}
}
