<section class="dui-widget-card" [loading]="isLoading">
    <mat-card class="no-shadow">
        <mat-card-header>
            <div class="toolbar">
                <div class="toolbar-left">
                    <div class="section-title toolbar-group" *ngIf="headerTitle != null">
                        <mat-card-title>{{headerTitle}}</mat-card-title>
                    </div>
                    <div class="toolbar-group" *ngIf="totalItemsCount != null">
                        <h4 class="widget-value">{{totalItemsCount | duiThousandth}}</h4>
                    </div>
                    <ng-content select="[toolbar-left]"></ng-content>
                </div>
                <div class="toolbar-right">
                    <ng-content select="[toolbar-right]"></ng-content>
                    <div class="toolbar-group" *ngIf="isUsed.filter || isUsed.search || isUsed.export">

                        <button mat-icon-button
                                [ngClass]="{'filter-active': isFilterActive}" matTooltip="Filter"
                                (click)="onFilterToggle()" *ngIf="isUsed.filter">
                            <mat-icon svgIcon="dui:options"></mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="Search"
                                [ngClass]="{'filter-active': isSearchActive}"
                                (click)="onSearchToggle()" *ngIf="isUsed.search">
                            <mat-icon svgIcon="dui:search"></mat-icon>
                        </button>
                        <dui-menu-list innerTitle="EXPORT BY:" [isLoading]="isExportLoading" [options]="exportOptions"
                            (itemSelect)="onExport($event)" *ngIf="isUsed.export && exportOptions.length > 1">Export</dui-menu-list>

                        <button mat-flat-button
                                load-small
                                [loading]="isExportLoading"
                                *ngIf="isUsed.export && exportOptions.length === 1"
                                (click)="onExport(exportOptions[0].value)"
                        >
                            <mat-icon [svgIcon]="exportOptions[0].iconName"></mat-icon>
                            {{exportOptions[0].label}}
                        </button>
                    </div>
                    <div class="toolbar-group" *ngIf="isUsed.refresh">
                        <button mat-icon-button (click)="onRefreshClick()" matTooltip="Refresh">
                            <mat-icon svgIcon="refresh"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </mat-card-header>
        <mat-card-content *ngIf="isUsed.filter" [hidden]="!isOpened.filter" class="filter-area">
            <div #filtersWrapper>
                <ng-content select="[filters-content]"></ng-content>
            </div>
            <div class="filter-controls">
                <button mat-stroked-button (click)="onClearClicked()">Clear</button>
                <button mat-flat-button (click)="onFilterClicked()">Filter</button>
            </div>
        </mat-card-content>
        <mat-card-content *ngIf="isUsed.search" [hidden]="!isOpened.search" class="filter-area" #searchWrapper>
            <ng-content select="[search-content]"></ng-content>
        </mat-card-content>
        <mat-card-content *ngIf="isUsed.setting" class="filter-area" #settingWrapper>
            <ng-content select="[setting-content]"></ng-content>
        </mat-card-content>
        <mat-card-content class="clear-paddings">
            <ng-content select="[table-content]"></ng-content>
        </mat-card-content>
    </mat-card>
</section>
