export interface IPermissionCustom {
    [key: string]: IPermissionCustomDetails;
}

export interface IPermissionCustomDetails {
    permissions: Array<number | IPermissionParams>;
    checkType: PermissionCheckType;
}

export interface IPermissionParams {
    permissionCode: number;
    permissionAction?: number;
}

export enum PermissionCheckType {
    Every = "Every",
    Some = "Some",
}

export interface IPermission {
    id: number;
    name: string;
    permission: number;
}
