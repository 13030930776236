import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: "dui-card",
    templateUrl: "./card.component.html",
    styleUrls: ["./card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
    @Input() heading: string;
    @Input() canExpand = true;
    @Input() expanded = true;

    @Output() expandedChange = new EventEmitter<boolean>();

    expandContent(): void {
        this.expanded = !this.expanded;
        this.expandedChange.emit(this.expanded);
    }
}
