import { PERMISSIONS_CUSTOM } from "@core/constants/permission-custom.constant";
import { PERMISSIONS } from "@core/constants/permissions.constant";
import { IPermissionCustomDetails } from "@core/interfaces/permission.interface";

export interface IMenuItem {
    click?: string;
    icon: string; // 'fas fa-home'
    isActive?: boolean;
    name: string; // 'Home'
    route: string; // '/home'
    permission?: number | number[] | IPermissionCustomDetails; // 2 // permissions.HomePage
    permissionActions?: number[];
    restriction?: string;
    restrictionParameters?: any;
}

export interface IMenuItemParent extends IMenuItem {
    children?: IMenuItem[];
    expanded?: boolean;
}

export const MENU_ITEMS: IMenuItemParent[] = [
    {
        name: "Home",
        route: "/home",
        permission: PERMISSIONS.HomePage,
        icon: "home_filled",
        children: [],
    },
    {
        name: "Databank Solutions",
        route: "/solutions",
        icon: "clipboard_check",
        permission: PERMISSIONS.DataBankSolutions,
        children: [],
    },
    {
        name: "Support",
        route: "/support",
        icon: "group_chat",
        permission: -1,
        children: [],
    },
    {
        name: "My Account",
        route: "/my-account",
        click: "checkMyAccount",
        icon: "how_to_reg",
        expanded: true,
        permission: PERMISSIONS_CUSTOM.MyAccount,
        children: [
            {
                name: "Manage Users",
                route: `/my-account/users`,
                icon: "fas fa-circle",
                permission: PERMISSIONS.MyAccountManageUsers,
            },
            {
                name: "View Roles",
                route: `/my-account/roles`,
                icon: "fas fa-circle",
                permission: PERMISSIONS.MyAccountManageRoles,
            },
            {
                name: "My Profile",
                route: `/my-profile`,
                icon: "fas fa-circle",
                permission: PERMISSIONS_CUSTOM.MyProfile,
            },
        ],
    },
];
