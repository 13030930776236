/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, ElementRef, Input, OnChanges } from "@angular/core";
import { ISpinner, SpinnerOptions, SpinnerType } from "../../interfaces/spinner.interface";
import { SpinnerFactory } from "./spinners/spinner-factory";

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: "[loading]" })
export class LoadingDirective implements OnChanges {
    @Input() loading: boolean;
    @Input() options: SpinnerOptions;

    @Input("load-small")
    set isSmall(value: boolean) {
        this.spinnerType = SpinnerType.Small;
    }

    @Input("load-toggle")
    set isToggle(value: boolean) {
        this.spinnerType = SpinnerType.Toggle;
    }

    private spinnerType?: SpinnerType;

    private spinner: ISpinner;

    constructor(private element: ElementRef) {}

    ngOnChanges(): void {
        if (!this.spinner) {
            this.spinner = SpinnerFactory.createSpinner(
                this.element.nativeElement,
                this.options,
                this.spinnerType
            );
        }
        if (!this.loading) {
            this.spinner.stopSpinner();
        } else if (this.loading) {
            this.spinner.startSpinner();
        }
    }
}
