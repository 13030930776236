import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { RawValue, Value } from "@shared/interfaces/form.interface";

export type TimeframeDialogFormValue = Value<FormGroup<TimeframeDialogFormConfig>>;
export type TimeframeDialogFormRawValue = RawValue<FormGroup<TimeframeDialogFormConfig>>;

const defaultFormValue: TimeframeDialogFormValue = {
    dateFrom: null,
    dateTo: null,
};

export class TimeframeDialogFormConfig {
    dateFrom: FormControl<Date | null>;
    dateTo: FormControl<Date | null>;

    constructor(value: TimeframeDialogFormValue = defaultFormValue) {
        const fb = new FormBuilder().nonNullable;

        this.dateFrom = fb.control(value.dateFrom, [Validators.required]);
        this.dateTo = fb.control(value.dateTo, [Validators.required]);
    }
}
