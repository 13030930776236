import { Component, Input } from "@angular/core";

type IconSize = "small" | "normal";

@Component({
    selector: "dui-timeline-item",
    templateUrl: "./timeline.component.html",
    styleUrls: ["./timeline.component.scss"],
})
export class TimelineComponent {
    @Input() date: string;
    @Input() simpleView = false;
    @Input() showTime = true;
    @Input() iconSize: IconSize = "normal";
}
