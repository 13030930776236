import { Component, ChangeDetectionStrategy } from "@angular/core";
import { ProgressBarService } from "@core/services/progress-bar.service";

@Component({
    selector: "dbp-progress-bar",
    templateUrl: "./progress-bar.component.html",
    styleUrls: ["./progress-bar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
    public isProgressBarActive$ = this.progressBarService.isActive$;

    constructor(private progressBarService: ProgressBarService) {}
}
