import { ISpinner } from "../../../interfaces/spinner.interface";

export class SpinnerToggleClass extends ISpinner {
    private spinnerTemplate = `<svg class='dui-spinner' width='25' height='25' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
        <circle class='path' fill='none' stroke-width='3' stroke-linecap='round' cx='15' cy='15' r='10'></circle>
        </svg>`;

    private directiveClass = "preloader-toggle";
    private container: HTMLElement;
    private spinner: HTMLElement;
    private toggle: HTMLElement;

    startSpinner(): void {
        this.container = document.createElement("div");
        this.spinner = document.createElement("div");
        this.loadingElement.classList.add(this.directiveClass);
        this.toggle = this.loadingElement.querySelector(".mat-slide-toggle-thumb") as HTMLElement;

        this.container.classList.add("dui-spinner-container");
        this.spinner.innerHTML = this.spinnerTemplate;
        this.container.appendChild(this.spinner);
        this.toggle.appendChild(this.container);
    }

    stopSpinner(): void {
        if (this.container) {
            this.loadingElement.classList.remove(this.directiveClass);
            this.toggle.removeChild(this.container);
        }
    }
}
