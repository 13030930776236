import { Component, OnInit, ChangeDetectionStrategy, Inject } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { take } from "rxjs/operators";
import { IntervalSettings } from "@core/interfaces/timeframe.interface";
import { TIME_FRAME_MAX_DAYS_RANGE } from "@core/constants/timeframe.constant";
import {
    TimeframeDialogFormConfig,
    TimeframeDialogFormValue,
} from "../../configs/timeframe-dialog-form.config";

@Component({
    selector: "dbp-top-bar-timeframe-dialog",
    templateUrl: "./top-bar-timeframe-dialog.component.html",
    styleUrls: ["./top-bar-timeframe-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarTimeframeDialogComponent implements OnInit {
    public dateForm: FormGroup<TimeframeDialogFormConfig>;
    public pastDays: number = TIME_FRAME_MAX_DAYS_RANGE;
    public defaultFormParam: TimeframeDialogFormValue;

    constructor(
        public ref: MatDialogRef<TopBarTimeframeDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            [key: string]: any;
            content: IntervalSettings;
        }
    ) {}

    ngOnInit(): void {
        if (this.data.content) {
            this.defaultFormParam = {
                dateFrom: this.data.content?.startDate || null,
                dateTo: this.data.content?.endDate || null,
            };
        }
        this.dateForm = new FormGroup(new TimeframeDialogFormConfig(this.defaultFormParam));

        this.ref
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
                this.data.onReject();
            });
    }

    public get selectedToValue(): Date {
        const dateToValue = this.dateForm.controls.dateTo.value;
        return this.isValidDate(dateToValue) ? dateToValue : this.maxDate;
    }

    public get selectedFromValue(): Date {
        const dateFromValue = this.dateForm.controls.dateFrom.value;
        return this.isValidDate(dateFromValue) ? dateFromValue : this.minDate;
    }

    public get minDate(): Date {
        const minDate = new Date();
        const minDateMilliseconds = minDate.setDate(minDate.getDate() - this.pastDays);
        return new Date(minDateMilliseconds);
    }

    public get maxDate(): Date {
        return new Date();
    }

    public submit(): void {
        if (this.dateForm.valid) {
            this.data.onConfirm()(this.selectedFromValue, this.selectedToValue);
            this.ref.close();
        }
        this.dateForm.markAllAsTouched();
    }

    public close(): void {
        this.data.onReject();
        this.ref.close();
    }

    private isValidDate(date) {
        return date instanceof Date && !Number.isNaN(date.getTime());
    }
}
