import { ErrorHandler, Injectable } from "@angular/core";

declare global {
    interface Window {
        newrelic: typeof newrelic;
    }
}

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
    private newrelic = this.window.newrelic;
    constructor(private window: Window) {}

    handleError(error: string | Error): void {
        if (this.newrelic) {
            this.newrelic.noticeError(error);
        }
        // eslint-disable-next-line no-console
        console.error("ERROR: ", error);
    }
}
