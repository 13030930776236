<div *ngIf="isVisible" class="edge-portal-aside-container {{ statusClass }}" (mouseleave)="unhover()">
    <div class="edge-portal-aside-panel">
        <div class="edge-portal-aside-brand-area">
            <section class="edge-portal-aside-brand-container">
                <a [href]="commonUrls.databank.home" target="blank" class="logo-white"></a>
                <a href="javascript:void(0)" class="edge-portal-aside-toggler" (click)="toggleStatus()">
                    <mat-icon svgIcon="double_arrow_regular" class="toggle-collapsing rotate-180"></mat-icon>
                    <mat-icon svgIcon="double_arrow_regular" class="toggle-expanding"></mat-icon>
                </a>
            </section>
        </div>
        <div class="edge-aside-menu">
            <ul id="side-bar" class="edge-aside-menu-container" (mouseenter)="hover()">
                <li class="edge-aside-menu-item {{item.isActive?'active':''}}" 
                    *ngFor="let item of menu; index as i">
                    <ng-container *ngIf="item.children.length; else noChildren">
                        <a class="edge-aside-menu-link parent {{item.expanded ? '' : 'collapsed'}}"
                           [routerLink]="item.route"
                           [routerLinkActive]="'active'"
                        >
                            <mat-icon svgIcon="{{item.icon}}" class="edge-aside-menu-icon"></mat-icon>
                            <span class="edge-aside-menu-text flexible">{{item.name}}</span>
                            <span class="edge-aside-menu-toggle" (click)="toggle($event, i)">
                                <mat-icon svgIcon="arrow_right" class="toggle-collapsing rotate-90"></mat-icon>
                                <mat-icon svgIcon="arrow_right" class="toggle-expanding"></mat-icon>
                            </span>
                        </a>
                        <ul class="edge-aside-submenu">
                            <li class="edge-aside-submenu-item" *ngFor="let child of item.children; index as ci">
                                <a [routerLink]="child.route" 
                                class="edge-aside-submenu-link {{child.isActive?'active':''}}">
                                    <i class="{{child.icon}}"></i>
                                    <span class="edge-aside-submenu-text flexible">{{child.name}}</span>
                                </a>
                            </li>
                        </ul>
                    </ng-container>
                    <ng-template #noChildren>
                        <a [routerLink]="item.route"
                            class="edge-aside-menu-link {{item.isActive?'active':''}}">
                            <mat-icon svgIcon="{{item.icon}}" class="edge-aside-menu-icon"></mat-icon>
                            <span class="edge-aside-menu-text flexible">{{item.name}}</span>
                        </a>
                    </ng-template>
                </li>
                
            </ul>
        </div>
        <div class="edge-portal-aside-contact-item">
            <span class="edge-portal-aside-contact-label">Partner Support</span>
            <a class="edge-portal-aside-link" href="tel:12148455499">1.214.845.5499</a>
        </div>
    </div>
</div>
<div *ngIf="isVisible" class="edge-portal-aside-brick {{statusClass}}"></div>

