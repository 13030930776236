import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({ name: "safeHTML" })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    transform(html: string) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}
