import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TimelineComponent } from "./timeline.component";
import { DuiCoreModule } from "../../core";

@NgModule({
    declarations: [TimelineComponent],
    imports: [CommonModule, DuiCoreModule],
    providers: [],
    exports: [TimelineComponent],
})
export class DuiTimelineModule {}
