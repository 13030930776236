import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, Input } from "@angular/core";
import { Subject } from "rxjs";
import { PartnersService } from "@shared/services/partners.service";
import { FormControl } from "@angular/forms";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { LocalStorageService } from "@core/services/local-storage.service";
import { ISelectOption } from "@databank-ui";

const PARTNER_LS_KEY = "dbp-home-partner-filter";

@Component({
    selector: "dbp-top-bar-partner",
    templateUrl: "./top-bar-partner.component.html",
    styleUrls: ["./top-bar-partner.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarPartnerComponent implements OnInit, OnDestroy {
    @Input() partnerList: ISelectOption[];
    public partnerControl = new FormControl<string>("");
    private destroy$ = new Subject<void>();

    constructor(
        private partners: PartnersService,
        private localStorageService: LocalStorageService
    ) {}

    ngOnInit(): void {
        // TODO: Refactor working with localstorage. Move to service.
        const current =
            this.partners.getValue() || this.localStorageService.getItem(PARTNER_LS_KEY);

        if (current) {
            this.partnerControl.patchValue(current, { emitEvent: false });
            this.partners.setValue(current);
        }

        // process partner change
        this.partnerControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
            this.localStorageService.setItem(PARTNER_LS_KEY, value);
            this.partners.setValue(value);
        });

        // Update form control value  upon changes from Partner Service
        this.partners.selected$
            .pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe(partner => {
                if (partner !== this.partnerControl.value) {
                    this.localStorageService.setItem(PARTNER_LS_KEY, partner);
                    this.partnerControl.patchValue(partner);
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
