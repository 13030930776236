import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Severity } from "../interfaces/severity.interface";

export type SeverityList = { [key: number]: string };

const DEFAULT_SEVERITIES: SeverityList = {
    [Severity.Black]: "severity_black",
    [Severity.Gray]: "severity_gray",
    [Severity.Blue]: "severity_blue",
    [Severity.High]: "severity_high",
    [Severity.Medium]: "severity_medium",
    [Severity.Low]: "severity_low",
    [Severity.Critical]: "severity_critical",
    [Severity.Healthy]: "severity_healthy",
};
@Directive({
    selector: "[duiSeverity]",
})
export class SeverityDirective implements OnChanges {
    @Input("duiSeverity") severityId: number;
    @Input() customSeverities: SeverityList;

    private get severities(): SeverityList {
        return this.customSeverities ? this.customSeverities : DEFAULT_SEVERITIES;
    }

    constructor(private el: ElementRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        const element = this.el.nativeElement as Element;

        if (changes.severityId?.currentValue != null && element) {
            // Remove all severity classes
            const severityClasses = Object.entries(this.severities).map(e => e[1]);
            element.classList.remove(...severityClasses);

            // Add new severity class
            element.classList.add(this.severities[this.severityId]);
        }
    }
}
