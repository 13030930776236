import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ITokenDto } from "@core/interfaces/dto/token-dto.interface";
import { webApiUrls } from "@core/constants/url.constant";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Params, Router } from "@angular/router";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { UserStorageService } from "@core/services/storages/user-storage.service";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { LoggerService } from "@core/services/logger.service";
import { NotificationService } from "@databank-ui";
import { LocalStorageService } from "@core/services/local-storage.service";
import { SETTINGS_LS_KEY } from "@shared/constants/local-storage-keys.constant";
import { UserApiService } from "./user-api.service";

@Injectable({
    providedIn: "root",
})
export class AuthApiService {
    constructor(
        private http: HttpClient,
        private router: Router,
        private authStorageService: AuthStorageService,
        private userStorageService: UserStorageService,
        private settingStorageService: SettingStorageService,
        private userApiService: UserApiService,
        private notificationService: NotificationService,
        private loggerService: LoggerService,
        private localStorageService: LocalStorageService
    ) {}

    /**
     * Simple auth with login and password
     */
    public simpleAuth(login: string, password: string): Observable<void> {
        const body = {
            login,
            password,
        };

        return this.getToken(body).pipe(map(() => {}));
    }

    public logout(): void {
        this.http
            .post<void>(`${webApiUrls.auth}/Logout`, null)
            .pipe(
                tap(() => {
                    this.loggerService.log("[AuthApiService] HTTP POST Logout Success");
                })
            )
            .subscribe();

        this.clearStorages();
    }

    public clearStoragesAndNavigate(): void {
        this.clearStorages();
        this.navigateToLogin();
    }

    public navigateToLogin(): void {
        this.router.navigate(["/auth/login"]);
    }

    public retrieveUsername(email: string): Observable<any> {
        const headers = new HttpHeaders().set("skip-token", "true");
        return this.http.post(
            `${webApiUrls.base}/TroubleLogging/ForgotUsername`,
            { email },
            { headers }
        );
    }

    public sendForgotPasswordEmail(username: string): Observable<any> {
        const headers = new HttpHeaders().set("skip-token", "true");
        return this.http.post(
            `${webApiUrls.base}/TroubleLogging/ForgotPassword`,
            { username },
            { headers }
        );
    }

    public refreshToken(): void {
        this.loggerService.log("[AuthApiService] HTTP POST /Refresh");
        const path = `${webApiUrls.auth}/Refresh`;
        const body = new FormData();
        body.append("refreshToken", this.authStorageService.getAuth().refreshToken);

        this.http
            .post<ITokenDto>(path, body, {
                observe: "response",
            })
            .pipe(
                tap(data => {
                    this.loggerService.log(
                        "[AuthApiService] HTTP POST /refresh Success",
                        data.body
                    );
                    this.updateAuthData(data.headers, data.body);
                }),
                map(data => data.body),
                catchError(err => {
                    this.notificationService.error("An error occurred during token refresh");
                    return of(err);
                })
            )
            .subscribe();
    }

    /**
     * HTTP GET get Token
     */
    private getToken(body: Params, params?: Params): Observable<ITokenDto> {
        this.loggerService.log("[AuthApiService] HTTP POST /login");
        const path = `${webApiUrls.auth}/login`;
        const headers = new HttpHeaders().set("skip-token", "true");

        return this.http
            .post<ITokenDto>(path, body, {
                headers,
                params: params || null,
                observe: "response",
            })
            .pipe(
                tap(data => {
                    this.loggerService.log("[AuthApiService] HTTP POST /login Success", data.body);
                    this.settingStorageService.initUserSetting(body.login);
                    this.updateAuthData(data.headers, data.body);
                }),
                map(data => data.body)
            );
    }

    private updateAuthData(headers: HttpHeaders, body: ITokenDto): void {
        const userSessionData = JSON.parse(headers.get("usersessiondata"));
        this.authStorageService.setAuthData({
            ...body,
            isPasswordExpired: userSessionData.IsPasswordExpired,
        });
    }

    private clearStorages(): void {
        this.partialClearLocalStorage();
        this.authStorageService.clearAuthData();
        this.userStorageService.clearUserData();
        this.userApiService.clearUserProfileCache();
    }

    private partialClearLocalStorage() {
        // We need to keep "settings" in LS between sessions. These are specific user preferences
        // related to functionalities such as "remember me", "sidebar", etc.
        // Check SettingStorageService for more info
        const settings = this.localStorageService.getItem(SETTINGS_LS_KEY);
        this.localStorageService.clear();
        this.localStorageService.setItem(SETTINGS_LS_KEY, settings);
    }
}
