import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from "@angular/core";
import { fromEvent, Observable, Subject } from "rxjs";
import { exhaustMap, filter, map, pairwise, startWith, takeUntil } from "rxjs/operators";

interface ScrollPosition {
    sH: number; // scroll height
    sT: number; // scroll top
    cH: number; // client height
}

const DEFAULT_SCROLL_POSITION: ScrollPosition = {
    sH: 0,
    sT: 0,
    cH: 0,
};

@Directive({
    selector: "[dbpInfiniteScroller]",
})
export class InfiniteScrollerDirective<T = any> implements AfterViewInit, OnDestroy {
    // Callback function which should be used to append items in the scrollable list
    @Input() scrollCallback: () => Observable<T>;

    // Until what percentage the user should scroll the container for the scrollCallback() to be called
    @Input() scrollPercent = 90;

    // If true as soon as the directive is initialized call the scrollCallback()
    @Input() immediateCallback = false;

    private scrollEvent$: Observable<any>;
    private userScrolledDown$: Observable<ScrollPosition[]>;
    private requestOnScroll$: Observable<ScrollPosition[]>;
    private destroy$ = new Subject<void>();

    constructor(private elm: ElementRef) {}

    ngAfterViewInit(): void {
        this.registerScrollEvent();

        this.streamScrollEvents();

        this.requestCallbackOnScroll();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public scrollToTop(): void {
        (this.elm.nativeElement as HTMLElement).scrollTo({ top: 0 });
    }

    private registerScrollEvent(): void {
        this.scrollEvent$ = fromEvent(this.elm.nativeElement, "scroll");
    }

    private streamScrollEvents(): void {
        this.userScrolledDown$ = this.scrollEvent$.pipe(
            map(
                (e): ScrollPosition => ({
                    sH: e.target.scrollHeight,
                    sT: e.target.scrollTop,
                    cH: e.target.clientHeight,
                })
            ),
            pairwise(),
            filter(
                positions =>
                    this.isUserScrollingDown(positions) &&
                    this.isScrollExpectedPercent(positions[1])
            )
        );
    }

    private requestCallbackOnScroll(): void {
        this.requestOnScroll$ = this.userScrolledDown$;

        if (this.immediateCallback) {
            this.requestOnScroll$ = this.requestOnScroll$.pipe(
                startWith([DEFAULT_SCROLL_POSITION, DEFAULT_SCROLL_POSITION])
            );
        }

        this.requestOnScroll$
            .pipe(
                takeUntil(this.destroy$),
                // New scrollCallback function will be called only after previous emits any value
                exhaustMap(() => {
                    return this.scrollCallback();
                })
            )
            .subscribe();
    }

    private isUserScrollingDown(positions: ScrollPosition[]): boolean {
        return positions[0].sT < positions[1].sT;
    }

    private isScrollExpectedPercent(position: ScrollPosition): boolean {
        return (position.sT + position.cH) / position.sH > this.scrollPercent / 100;
    }
}
