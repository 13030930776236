import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { IModalCommonConfig } from "./dialog.interface";

@Component({
    selector: "dui-dialog",
    templateUrl: "./dialog.component.html",
    styleUrls: ["./dialog.component.scss"],
})
export class DialogComponent implements OnInit, OnDestroy {
    private wasRejected = false;
    private wasConfirmed = false;

    private onDestroy = new Subject<void>();

    constructor(
        public ref: MatDialogRef<DialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IModalCommonConfig
    ) {}

    ngOnInit(): void {
        if (this.data && this.data.loading$) {
            this.data.loading$
                .pipe(takeUntil(this.onDestroy))
                .subscribe(value => (!value.loading && !value.failed ? this.ref.close() : null));
        }

        this.ref
            .beforeClosed()
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.data.onClose();
                if (!this.wasRejected && !this.wasConfirmed) this.data.onCancel();
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    reject(): void {
        this.wasRejected = true;
        this.data.onReject();
    }

    confirm(): void {
        this.wasConfirmed = true;
        this.data.onConfirm();
        this.closeModal();
    }

    private closeModal(): void {
        if (!this.data.loading$) {
            this.ref.close();
        }
    }
}
