import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { MenuListItem } from "../menu-list";

const IS_OPENED_DEFAULT_VALUES = {
    filter: false,
    search: false,
};

const IS_USED_DEFAULT_VALUES = {
    refresh: true,
    filter: true,
    search: true,
    export: true,
    setting: true,
};

@Component({
    selector: "dui-widget-card",
    templateUrl: "./widget-card.component.html",
    styleUrls: ["./widget-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetCardComponent<T> implements OnInit, AfterViewInit {
    @Input() headerTitle: string;
    @Input() totalItemsCount: number;
    @Input() isFilterActive: boolean;
    @Input() isSearchActive: boolean;
    @Input() isExportLoading: boolean;
    @Input() isLoading: boolean;
    @Input() exportOptions: MenuListItem<T>[] = [];

    @Output() refreshClick = new EventEmitter<void>();
    @Output() filterApply = new EventEmitter<void>();
    @Output() filterCancel = new EventEmitter<void>();
    @Output() export = new EventEmitter<T>();

    @ViewChild("filtersWrapper") filtersWrapper: ElementRef;
    @ViewChild("searchWrapper") searchWrapper: ElementRef;
    @ViewChild("settingWrapper") settingWrapper: ElementRef;

    public isOpened = { ...IS_OPENED_DEFAULT_VALUES };
    public isUsed = { ...IS_USED_DEFAULT_VALUES };

    constructor(private ref: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.isUsed = {
            ...this.isUsed,
            refresh: !!this.refreshClick.observers.length,
            export: !!this.export.observers.length,
        };
    }

    ngAfterViewInit(): void {
        this.isUsed = {
            ...this.isUsed,
            filter: !!(this.filtersWrapper.nativeElement as HTMLElement).children.length,
            search: !!(this.searchWrapper.nativeElement as HTMLElement).children.length,
            setting: !!(this.settingWrapper.nativeElement as HTMLElement).children.length,
        };
        this.ref.detectChanges();
    }

    public onExport(fileType: T): void {
        this.export.emit(fileType);
    }

    public onRefreshClick(): void {
        this.closeAllSectionsExcept("");
        this.refreshClick.emit();
    }

    public onClearClicked(): void {
        this.filterCancel.emit();
    }

    public onFilterClicked(): void {
        this.filterApply.emit();
    }

    public onFilterToggle(): void {
        this.closeAllSectionsExcept("filter");
        this.isOpened.filter = !this.isOpened.filter;
        this.ref.markForCheck();
    }

    public onSearchToggle(): void {
        this.closeAllSectionsExcept("search");
        this.isOpened.search = !this.isOpened.search;
        this.ref.markForCheck();
    }

    private closeAllSectionsExcept(exceptSection: string): void {
        Object.keys(this.isOpened).forEach(key => {
            if (key === exceptSection) return;
            this.isOpened[key] = false;
        });
    }
}
