import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { DuiCoreModule } from "../../core";
import { IdleDialogComponent } from "./idle-dialog.component";
import { DuiProgressModule } from "../progress";

@NgModule({
    declarations: [IdleDialogComponent],
    imports: [CommonModule, DuiCoreModule, DuiProgressModule, MatDialogModule, MatButtonModule],
    providers: [],
    exports: [IdleDialogComponent],
})
export class DuiIdleDialogModule {}
