import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LoginLayoutShellComponent } from "./containers/login-layout-shell/login-layout-shell.component";
import { LoginHeaderComponent } from "./components/login-header/login-header.component";
import { LoginFooterComponent } from "./components/login-footer/login-footer.component";

@NgModule({
    declarations: [LoginLayoutShellComponent, LoginHeaderComponent, LoginFooterComponent],
    imports: [CommonModule, RouterModule],
    exports: [LoginLayoutShellComponent],
})
export class LoginLayoutModule {}
