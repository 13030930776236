import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { CoreModule } from "@core/core.module";
import { SharedModule } from "@shared/shared.module";
import {
    DuiCopyrightModule,
    DuiCoreModule,
    DuiIdleDialogModule,
    DuiSelectModule,
    DuiValidationErrorModule,
} from "@databank-ui";

import { PortalLayoutShellComponent } from "./containers/portal-layout-shell/portal-layout-shell.component";
import { PortalFooterComponent } from "./components/portal-footer/portal-footer.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TopBarTitleComponent } from "./components/top-bar-title/top-bar-title.component";
import { TopBarUserInfoComponent } from "./components/top-bar-user-info/top-bar-user-info.component";
import { TopBarLogoutComponent } from "./components/top-bar-logout/top-bar-logout.component";
import { TopBarTimeframeComponent } from "./components/top-bar-timeframe/top-bar-timeframe.component";
import { TopBarTimeframeDialogComponent } from "./components/top-bar-timeframe-dialog/top-bar-timeframe-dialog.component";
import { TopBarPartnerComponent } from "./components/top-bar-partner/top-bar-partner.component";

@NgModule({
    declarations: [
        PortalLayoutShellComponent,
        PortalFooterComponent,
        SidebarComponent,
        TopBarTitleComponent,
        TopBarUserInfoComponent,
        TopBarLogoutComponent,
        TopBarTimeframeComponent,
        TopBarTimeframeDialogComponent,
        TopBarPartnerComponent,
    ],
    imports: [
        CoreModule,
        CommonModule,
        SharedModule,
        RouterModule,
        DuiCoreModule,
        DuiCopyrightModule,
        DuiIdleDialogModule,
        DuiSelectModule,
        DuiValidationErrorModule,
    ],
    exports: [PortalLayoutShellComponent],
    providers: [],
})
export class PortalLayoutModule {}
