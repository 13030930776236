import { environment } from "../../../environments/environment";

const hostname = `${environment.protocol}://${environment.hostname}`;

export const webApiUrls = {
    base: `${hostname}/PartnerPortal/api`,
    auth: `${hostname}/PartnerPortal/api/auth`,
};

export const commonUrls = {
    databank: {
        home: "https://www.databank.com/",
        privacyPolicy: "https://www.databank.com/privacy",
        termOfUse: "https://www.databank.com/terms-of-use/",
        contactUs: "https://www.databank.com/contact-us/",
        partner: "https://www.databank.com/about-databank/partner-program/",
    },
};
