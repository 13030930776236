<div class="auth-page-image"></div>
<header>
    <div class="shell llayout-header">
        <a [href]="commonUrls.databank.home" class="logo-white"></a>
        <a class="burger-menu"
           [class.active]="burgerMenuState"
           (click)="burgerMenuState = !burgerMenuState">
            <span></span>
            <span></span>
            <span></span>
        </a>
        <div class="llayout-header__content">
            <ul class="llayout-header__list llayout-header__contact-list">
                <li class="llayout-header__contact-item">
                    <span class="llayout-header__contact-label">Sales</span>
                    <a class="llayout-header__link" href="tel:18008407533">1.800.840.7533</a>
                </li>
                <li class="llayout-header__contact-item">
                    <span class="llayout-header__contact-label">Support</span>
                    <a class="llayout-header__link" href="tel:18553282247">1.855.328.2247</a>
                </li>
            </ul>
            <nav class="llayout-header__nav">
                <ul class="llayout-header__list">
                    <li>
                        <a class="llayout-header__link"
                           [href]="commonUrls.databank.contactUs">
                            Contact
                        </a>
                    </li>
                    <li>
                        <a class="llayout-header__link"
                           [href]="commonUrls.databank.partner">
                            Partners
                        </a>
                    </li>
                    <li>
                        <a class="llayout-header__link"
                           routerLink="/auth/support">
                            Support
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>
<div class="auth-page-header">
    <div class="auth-page-title">
        <div>Partner Program</div>
    </div>
    <div class="auth-page-notice">Partnering for Success</div>
</div>
