import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CopyrightComponent } from "./copyright.component";

@NgModule({
    declarations: [CopyrightComponent],
    imports: [CommonModule],
    providers: [],
    exports: [CopyrightComponent],
})
export class DuiCopyrightModule {}
