import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { SelectComponent } from "./select.component";
import { DuiValidationErrorModule } from "../validation-error";

@NgModule({
    declarations: [SelectComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DuiValidationErrorModule,
        MatSelectModule,
        MatFormFieldModule,
        MatIconModule,
        MatButtonModule,
    ],
    exports: [SelectComponent],
    providers: [],
})
export class DuiSelectModule {}
