import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { PhoneInputComponent } from "./phone-input.component";
import { DuiValidationErrorModule } from "../validation-error";
import { NgxMatIntlTelInputModule } from "./ngx-mat-intl-tel-input/ngx-mat-intl-tel-input.module";

@NgModule({
    declarations: [PhoneInputComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        DuiValidationErrorModule,
        NgxMatIntlTelInputModule,
    ],
    providers: [],
    exports: [PhoneInputComponent],
})
export class DuiPhoneInputModule {}
