import { HttpClient, HttpHeaders } from "@angular/common/http";
import { InjectionToken, Provider } from "@angular/core";
import { webApiUrls } from "@core/constants/url.constant";
import { CountryLookup, LookupListItem } from "@shared/interfaces/lookup-list-item.interface";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

const anonymousHeaders = new HttpHeaders().set("skip-token", "true");

export const LOCATIONS_LOOKUP = new InjectionToken<Observable<LookupListItem[]>>(
    "Lookup: Locations list"
);

export const COUNTRIES_LOOKUP = new InjectionToken<Observable<CountryLookup[]>>(
    "Lookup: Countries list"
);

export const STATES_LOOKUP = new InjectionToken<
    (countryId: string) => Observable<LookupListItem[]>
>("Lookup: States list");

export const MASTER_AGENTS_LOOKUP = new InjectionToken<Observable<LookupListItem[]>>(
    "Lookup: Master Agents list"
);

export const COMMON_LOOKUPS_PROVIDERS: Provider[] = [
    {
        provide: LOCATIONS_LOOKUP,
        deps: [HttpClient],
        useFactory: (http: HttpClient): Observable<LookupListItem[]> => {
            const path = `${webApiUrls.base}/Infrastructure/Locations`;
            return http
                .get<LookupListItem[]>(path, { headers: anonymousHeaders })
                .pipe(shareReplay(1));
        },
    },
    {
        provide: COUNTRIES_LOOKUP,
        deps: [HttpClient],
        useFactory: (http: HttpClient): Observable<CountryLookup[]> => {
            const path = `${webApiUrls.base}/Infrastructure/Countries`;
            return http
                .get<CountryLookup[]>(path, { headers: anonymousHeaders })
                .pipe(shareReplay(1));
        },
    },
    {
        provide: STATES_LOOKUP,
        deps: [HttpClient],
        useFactory: (http: HttpClient): ((countryId: string) => Observable<LookupListItem[]>) => {
            return (countryId: string) => {
                const path = `${webApiUrls.base}/Infrastructure/States`;
                return http.get<LookupListItem[]>(path, {
                    headers: anonymousHeaders,
                    params: { countryId },
                });
            };
        },
    },
    {
        provide: MASTER_AGENTS_LOOKUP,
        deps: [HttpClient],
        useFactory: (http: HttpClient): Observable<LookupListItem[]> => {
            const path = `${webApiUrls.base}/Infrastructure/MasterAgents`;
            return http
                .get<LookupListItem[]>(path, { headers: anonymousHeaders })
                .pipe(shareReplay(1));
        },
    },
];
