/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable prefer-object-spread */
import { ISelectGroup, ISelectOption, ISelectViewOption } from "./select.interface";

export class SelectData {
    public filteredItems: ISelectViewOption[];
    // Need for invisible mat-option on UI to have a possibility to see selected item;
    public invisibleSelectedOption: ISelectViewOption | null = null;
    public isGrouped = false;

    private allMappedItems: ISelectViewOption[];
    constructor(elements: ISelectGroup[] | ISelectOption[]) {
        this.isGrouped = !!(elements[0] as ISelectGroup)?.items?.length;
        this.allMappedItems = this.getMappedAllOptions(elements);
        this.filteredItems = this.allMappedItems.map(x => Object.assign({}, x));
    }

    public resetSearchItems(): void {
        this.filteredItems = this.allMappedItems.map(x => Object.assign({}, x));
    }

    public filterDataBySearchString(searchString: string): void {
        if (!searchString) {
            this.resetSearchItems();
        } else {
            searchString = searchString.toLowerCase();
            this.filteredItems = this.isGrouped
                ? this.searchByGroups(searchString)
                : this.searchByOptions(searchString);
        }
    }

    public setInvisibleSelectedOption(selectedValue: any): void {
        this.invisibleSelectedOption = Object.assign(
            {},
            this.allMappedItems.find(el => el.value === selectedValue)
        );
    }

    private searchByGroups(searchString: string): ISelectViewOption[] {
        const filteredArray: ISelectViewOption[] = [];
        let startIndex: number | null = null;
        const lastIndex: number = this.allMappedItems.length - 1;

        this.allMappedItems.forEach((item, index) => {
            if (item.isSelfGroup || index === lastIndex) {
                if (startIndex !== null) {
                    filteredArray.push(
                        ...this.getFilteredGroupItems(
                            this.allMappedItems.slice(
                                startIndex,
                                index === lastIndex ? lastIndex + 1 : index
                            ),
                            searchString
                        )
                    );
                }
                startIndex = index;
            }
        });

        return filteredArray;
    }

    private getFilteredGroupItems(
        groupItems: ISelectViewOption[],
        searchString: string
    ): ISelectViewOption[] {
        let items: ISelectViewOption[] = [];
        for (let i = 0; i < groupItems.length; i++) {
            const isMatch = groupItems[i].label.toLowerCase().includes(searchString);
            if (groupItems[i].isSelfGroup && isMatch) {
                items = [...groupItems];
                break;
            } else if (isMatch) {
                items.push(groupItems[i]);
            }
        }

        if (items.length >= 1 && !items[0].isSelfGroup)
            items.unshift(groupItems.find(el => el.isSelfGroup) as ISelectViewOption);
        return items;
    }

    private searchByOptions(searchString: string): ISelectViewOption[] {
        return this.allMappedItems.filter(item => item.label.toLowerCase().includes(searchString));
    }

    private getMappedAllOptions(elements: ISelectGroup[] | ISelectOption[]): ISelectViewOption[] {
        const array: ISelectViewOption[] = [];
        this.invisibleSelectedOption = null;
        if (!elements.length) {
            return [];
        }

        if (elements.length && (elements[0] as ISelectGroup).items)
            (elements as ISelectGroup[]).forEach((group: ISelectGroup) => {
                array.push(this.getMappedGroupOption(group));
                group.items.forEach((item: ISelectOption) => {
                    array.push(this.getMappedOption(item, group));
                });
            });
        else
            (elements as ISelectOption[]).forEach((item: ISelectOption) => {
                array.push(this.getMappedOption(item));
            });

        return array;
    }

    private getMappedGroupOption(group: ISelectGroup): ISelectViewOption {
        return {
            label: group.label,
            value: `${group.label}_group`,
            isSelfGroup: true,
            groupName: group.label,
        };
    }

    private getMappedOption(item: ISelectOption, group?: ISelectGroup): ISelectViewOption {
        return { ...item, groupName: group?.label };
    }
}
