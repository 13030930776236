import { Component, ChangeDetectionStrategy } from "@angular/core";
import { AuthApiService } from "@core/services/auth-api.service";

@Component({
    selector: "dbp-top-bar-logout",
    templateUrl: "./top-bar-logout.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarLogoutComponent {
    constructor(private authApiService: AuthApiService) {}

    logout(): void {
        this.authApiService.logout();
        this.authApiService.navigateToLogin();
    }
}
