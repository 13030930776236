<mat-label class="form-control-label" *ngIf="label" [ngClass]="{
    'field-required': isRequired && control?.enabled,
    'field-disabled': control?.disabled
}">{{label}}</mat-label>

<div class="multiselect-list-wrapper"
     [class.error]="isErrorVisible"
     [class.disabled]="isControlDisabled"
     [class.with-paginator]="isPaginatorActive"
>
    <mat-tab-group class="full-width" [selectedIndex]="selectedTabIsDefault ? 1 : 0" (selectedTabChange)="onTabChanged($event);">
        <!-- ALL -->
        <mat-tab label="All">
            <div class="table-options">
                <button mat-icon-button
                        [matMenuTriggerFor]="search"
                        [class.filter-active]="searchControl.value.length"
                        type="button"
                        matTooltip="Search">
                    <mat-icon svgIcon="dui:search"></mat-icon>
                </button>
                <mat-menu #search="matMenu" class="multiselect-list-search">
                    <div class="search-wrapper" (click)="$event.stopPropagation()">
                        <mat-form-field>
                            <input matInput placeholder="Search" [formControl]="searchControl">
                        </mat-form-field>
                    </div>
                </mat-menu>
            </div>
            <table class="common-table">
                <thead>
                    <tr>
                        <th>
                            <mat-checkbox
                                [matTooltip]="allTabTooltip"
                                [checked]="selectedEnabledCount === enabledCount && enabledCount > 0"
                                [disabled]="isControlDisabled || enabledCount === 0"
                                (change)="onCheckboxAllChange($event)"
                            ></mat-checkbox>
                        </th>
                        <th *ngFor="let column of displayedColumns; let i = index" [innerHTML]="column.title">
                        </th>
                    </tr>

                </thead>
                <tbody>
                    <ng-container *ngFor="let item of itemsList; trackBy: trackByItemId">
                        <tr *ngIf="item.show">
                            <td>
                                <mat-checkbox
                                    [checked]="item.selected"
                                    [disabled]="isControlDisabled || item.disabled"
                                    (change)="onCheckboxSingleChange($event, item)"
                                ></mat-checkbox>
                            </td>
                            <td *ngFor="let column of displayedColumns"
                                [innerHTML]="item.data[column.value] != null ? item.data[column.value] : ''"
                            ></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </mat-tab>
        <!-- SELECTED -->
        <mat-tab>
            <ng-template mat-tab-label>
                Selected
                <span class="selected-count" [class.selected-count-hidden]="selectedCount === 0">
                    {{ selectedCount }}
                </span>
            </ng-template>

            <div class="table-options">
                <button mat-icon-button [matMenuTriggerFor]="search" [class.filter-active]="searchControl.value.length"
                    matTooltip="Search">
                    <mat-icon svgIcon="dui:search"></mat-icon>
                </button>
                <mat-menu #search="matMenu" class="multiselect-list-search">
                    <div class="search-wrapper" (click)="$event.stopPropagation()">
                        <mat-form-field>
                            <input matInput placeholder="Search" [formControl]="searchControl">
                        </mat-form-field>
                    </div>
                </mat-menu>
            </div>
            <table class="common-table">
                <thead>
                    <tr>
                        <th>
                            <mat-checkbox
                                [matTooltip]="selectedTabTooltip"
                                [checked]="selectedEnabledCount > 0"
                                [disabled]="selectedEnabledCount === 0 || isControlDisabled"
                                (change)="onCheckboxAllChange($event);"
                            ></mat-checkbox>
                        </th>
                        <th *ngFor="let column of displayedColumns" [innerHTML]="column.title"></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of itemsList; trackBy: trackByItemId">
                        <tr *ngIf="item.selected && item.show">
                            <td>
                                <mat-checkbox
                                    [checked]="true"
                                    [disabled]="isControlDisabled || item.disabled"
                                    (change)="onCheckboxSingleChange($event, item)"
                                ></mat-checkbox>
                            </td>
                            <td *ngFor="let column of displayedColumns"
                                [innerHTML]="item.data[column.value] !== null ? item.data[column.value] : ''"
                            ></td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </mat-tab>
    </mat-tab-group>
    <div class="multiselect-paginator-container" *ngIf="isPaginatorActive" [hidden]="isShowPaginator()">
        <dui-paginator></dui-paginator>
    </div>
</div>
<div class="error-container mat-error" [style.visibility]="isErrorVisible ? 'visible' : 'hidden'">
    <dui-validation-error [control]="control"></dui-validation-error>
</div>
