export const PERMISSIONS_ACTIONS = {
    none: 0,
    view: 1,
    add: 2,
    edit: 4,
    delete: 8,
};

export const PERMISSIONS = {
    HomePage: 1,
    HomePageYourDataBankTeam: 2,
    HomePageLeadHistory: 3,
    DataBankSolutions: 4,
    DataBankSolutionsPromotions: 5,
    DataBankSolutionsDataBankProducts: 6,
    DataBankSolutionsDataCenterDataSheets: 7,
    MyAccountManageUsers: 8,
    ManageUsers: 9,
    MyAccountManageRoles: 10,
    ManageRoles: 11,
    ProfilePage: 12,
    MyProfilePagePersonalInformation: 13,
    MyProfilePageCurrentSessions: 14,
    MyProfilePageRole: 15,
    MyAccount: 17,
};
