/* eslint-disable @typescript-eslint/no-use-before-define */
import {
    AbstractControl,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from "@angular/forms";
import { CountryCode } from "../enums";
import { postalCodesMap } from "./cultures.constant";

export const VALIDATORS = {
    required: Validators.required,
    requiredAndNonWhitespace,
    sfEmail,
    postalCode,
    passwordComplexity,
    matchPasswords,
    matchNewPasswords,
    URL,
    noWhitespace,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getValidatorErrorMessage(validatorName: string, validatorValue?: any): string {
    const config = {
        required: "This field is required",
        email: "Please enter a valid email",
        number: "Please enter numbers only",
        maxlength: `Must contain no more than ${validatorValue.requiredLength} characters`,
        minlength: `Must contain at least ${validatorValue.requiredLength} characters`,
        min: `Must be greater than or equal to ${validatorValue.min}`,
        max: `Must be less than or equal to ${validatorValue.max}`,
        passwordLength: "Password is too short",
        passwordMatch: "New password and confirm do not match",
        passwordOld: "New password cannot be the same as the old password",
        passwordRequirements: "Password does not meet requirements",
        phone: "Please enter a valid phone number",
        invalidEndDate: "Please enter a date after the start date",
        postalCode: "Please enter a valid zip code",
        ssnMismatch: "Social Security Numbers do not match",
        streetAddress: "Please enter a residential address",
        passwordsMismatch: "New password and confirm do not match",
        oldPasswordNotMatch: "Does not match current password",
        newPasswordIsDuplicated:
            "You can not reuse your previous 24 passwords. Try a different one",
        newPasswordIsDuplicatedServer:
            "You can not reuse your previous 24 passwords. Try a different one",
        creditCard: "Invalid credit card number",
        expDate: "Invalid expiration date",
        forbiddenValue: "Such value already exists",
        startTime: "Start time is higher than end time",
        endTime: "End time is lower than start time",
        pattern: "Please enter a valid value",
        ip: "Please enter a valid IP address",
        userNameDuplicated: "Username already in use. Please specify a unique username",
        incorrectDomain: "Domain to Secure has invalid domain",
        incorrectDomainWildcard: "A wildcard certificate domain name must begin with an asterisk",
        cidr: "The IP address/CIDR entered is not valid.",
        wordAnyInBoth:
            "DataBank security policies do not allow for the creation of any<->any rules on the firewall. Please enter either a source IP address/CIDR or a destination IP address/CIDR.",
        notDomain:
            "The value entered is not a valid domain name. Please enter a valid domain name: Example: databank.com",
        whitespace: "Please enter a value",
        securityCode: "Must be exactly 6 digits",
        url: "Please enter a valid URL",
    };

    return config[validatorName];
}

/** _______Validator Functions List_______ */

// The same as Validators.required but also requires at least 1 non-whitespace character
function requiredAndNonWhitespace(control: AbstractControl): ValidationErrors | null {
    const requiredError = Validators.required(control);
    if (requiredError) return requiredError;

    return typeof control.value !== "string" || control.value.match(/\S+/)
        ? null
        : { required: true };
}

// Validator for email which SalesForce uses
function sfEmail(control: AbstractControl): ValidationErrors | null {
    const firstRegEx = /^[A-z0-9._%+\-/!#$%&'*=?^_`{|}~]+@[A-z0-9.-]+\.[A-z]{2,4}$/;
    const secondRegEx =
        /^([a-zA-Z0-9_\-.]+)@((\[a-z]{1,3}\.[a-z]{1,3}\.[a-z]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})$/;

    if (!control || !control.value) return null;

    return control.value.match(firstRegEx) || control.value.match(secondRegEx)
        ? null
        : { email: true };
}

function postalCode(countryCode: CountryCode): ValidatorFn {
    const regExp = postalCodesMap.get(countryCode);
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control || !control.value || !regExp) return null;

        return control.value.match(regExp) ? null : { postalCode: true };
    };
}

function passwordComplexity(control: AbstractControl): ValidationErrors | null {
    const validationRules = [
        "[A-Z]+", // English uppercase characters (A-Z)
        "[a-z]+", // English lowercase characters (a-z)
        "[0-9]+", // Numeric digits (0-9)
        "\\W|_+", // Non-alphanumeric characters (for example: !, $, # or %)
    ];
    if (!control || !control.value) {
        return null;
    }

    let matchesCount = 0;
    validationRules.forEach(rule => {
        if (control.value.match(rule)) matchesCount++;
    });

    return matchesCount >= 3 ? null : { passwordRequirements: true };
}

function matchPasswords(
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
): ValidatorFn {
    // eslint-disable-next-line consistent-return
    return (formGroup: AbstractControl): any => {
        const oldPasswordControl = (formGroup as UntypedFormGroup).controls[oldPassword];
        const newPasswordControl = (formGroup as UntypedFormGroup).controls[newPassword];
        const confirmPasswordControl = (formGroup as UntypedFormGroup).controls[confirmPassword];

        if (!oldPasswordControl || !newPasswordControl || !confirmPasswordControl) {
            return null;
        }

        if (newPasswordControl.value && oldPasswordControl.value === newPasswordControl.value) {
            newPasswordControl.setErrors({ newPasswordIsDuplicated: true });
        } else if (newPasswordControl.hasError("newPasswordIsDuplicated")) {
            newPasswordControl.setErrors(null);
        }

        if (newPasswordControl.value !== confirmPasswordControl.value) {
            confirmPasswordControl.setErrors({ passwordsMismatch: true });
        } else if (confirmPasswordControl.hasError("passwordsMismatch")) {
            confirmPasswordControl.setErrors(null);
        }
    };
}

function matchNewPasswords(newPassword: string, confirmPassword: string) {
    // eslint-disable-next-line consistent-return
    return (formGroup: UntypedFormGroup): void | null => {
        const newPasswordControl = formGroup.controls[newPassword];
        const confirmPasswordControl = formGroup.controls[confirmPassword];

        if (!newPasswordControl || !confirmPasswordControl) {
            return null;
        }

        if (newPasswordControl.value !== confirmPasswordControl.value) {
            confirmPasswordControl.setErrors({ passwordsMismatch: true });
        } else if (confirmPasswordControl.hasError("passwordsMismatch")) {
            confirmPasswordControl.setErrors(null);
        }
    };
}

function URL(control: AbstractControl): ValidationErrors | null {
    const ipRegEx =
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
    if (!control || !control.value) {
        return null;
    }

    return control.value.match(ipRegEx) ? null : { url: true };
}

function noWhitespace(control: AbstractControl): ValidationErrors | null {
    const isWhitespace = (control?.value?.toString() || "").match(/\s/);
    return isWhitespace ? { whitespace: true } : null;
}
