import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { DuiNoDataModule } from "../no-data";
import { DuiPaginatorModule } from "../paginator";
import { TableCellTemplateComponent } from "./table-cell-template.component";
import { TableExpandableComponent } from "./table-expandable.component";
import { DuiCoreModule } from "../../core";

@NgModule({
    declarations: [TableExpandableComponent, TableCellTemplateComponent],
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule,
        MatTooltipModule,
        DuiNoDataModule,
        DuiPaginatorModule,
        DuiCoreModule,
        MatIconModule,
        MatButtonModule,
    ],
    providers: [],
    exports: [TableExpandableComponent, TableCellTemplateComponent],
})
export class DuiTableExpandableModule {}
