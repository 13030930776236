/* eslint-disable @typescript-eslint/member-ordering */
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import { MatSelect, MatSelectChange } from "@angular/material/select";
import { Subject, Subscription } from "rxjs";
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    startWith,
    takeUntil,
    tap,
} from "rxjs/operators";
import { A, NINE, SPACE, Z, ZERO } from "@angular/cdk/keycodes";
import { META_DATA } from "./select.metadata";
import { SelectData } from "./select-data.class";
import { ISelectGroup, ISelectOption, ISelectViewOption } from "./select.interface";

@Component({
    selector: "dui-select",
    templateUrl: "./select.component.html",
    styleUrls: ["./select.component.scss"],
})
export class SelectComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() label: string;
    @Input() placeholder: string;
    @Input() noDataText: string;
    @Input() control: FormControl<string>;
    @Input() options: ISelectOption[];
    @Input() groups: ISelectGroup[];
    @Input() isDisabled: boolean;
    @Input() highlighted = false;

    @Output() selectionChange: EventEmitter<string | number> = new EventEmitter<string | number>();

    @ViewChild("edgeSelect") selectElem: MatSelect;
    @ViewChild("selectContent") selectContent: ElementRef;
    @ViewChild("searchSelectInput") searchSelectInput: ElementRef;
    @ContentChild("option") optionTemplateRef: TemplateRef<any>;

    public onDestroy = new Subject<void>();

    public searchControl = new FormControl();
    public selectData: SelectData;
    public viewOptions: ISelectViewOption[];

    // Portion loading config
    private meta = META_DATA;
    private readonly RELOAD_SCROLL_DIFF = this.meta.RELOAD_SCROLL_DIFF;
    private readonly PORTION_LENGTH = this.meta.PORTION_LENGTH;
    private portionIndex = 0;
    private controlValueSubscription: Subscription;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.searchControl.valueChanges
            .pipe(takeUntil(this.onDestroy), debounceTime(300), distinctUntilChanged())
            .subscribe((searchString: string) => this.filterOptions(searchString));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.options?.currentValue || changes?.groups?.currentValue) {
            this.selectData = new SelectData(
                changes?.options?.currentValue || changes?.groups?.currentValue
            );
            this.resetVisibleItems();

            if (this.controlValueSubscription) {
                this.controlValueSubscription.unsubscribe();
            }
            this.controlValueSubscription = this.control.valueChanges
                .pipe(takeUntil(this.onDestroy), startWith(this.control.value))
                .subscribe(value => this.selectData.setInvisibleSelectedOption(value));
        }
    }

    ngAfterViewInit(): void {
        this.selectElem.openedChange
            .pipe(
                takeUntil(this.onDestroy),
                tap(opened => {
                    if (opened) {
                        this.focusSearch();
                    } else {
                        this.searchControl.setValue("");
                    }
                }),
                filter(value => value && this.selectData.filteredItems.length > this.PORTION_LENGTH)
            )
            .subscribe(() => this.registerPanelScrollEvent());
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    get isRequired(): boolean {
        const validator =
            this.control && this.control.validator
                ? this.control.validator({} as AbstractControl)
                : null;
        return validator && validator.required;
    }

    private registerPanelScrollEvent(): void {
        const panel = this.selectContent.nativeElement;
        panel.addEventListener("scroll", (event: Event) => this.loadPortionOnScroll(event));
    }

    private loadPortionOnScroll(event: Event): void {
        const element = event.target as Element;

        if (
            element.scrollHeight - element.scrollTop <
                element.clientHeight + this.RELOAD_SCROLL_DIFF &&
            this.viewOptions.length !== this.selectData.filteredItems.length
        ) {
            this.portionIndex++;
            const firstIndex = this.portionIndex * this.PORTION_LENGTH;
            const lastIndex = firstIndex + this.PORTION_LENGTH;

            this.viewOptions.push(...this.selectData.filteredItems.slice(firstIndex, lastIndex));
        }
        this.cdr.markForCheck();
    }

    private resetVisibleItems(): void {
        this.portionIndex = 0;
        this.viewOptions = this.selectData.filteredItems.slice(
            this.portionIndex,
            this.PORTION_LENGTH
        );
        this.cdr.markForCheck();
    }

    /** ********************** Search *********************** */

    private filterOptions(searchString: string): void {
        this.selectData.filterDataBySearchString(searchString);

        this.selectContent.nativeElement.scrollTop = 0;
        this.resetVisibleItems();
    }

    public get isSearchVisible(): boolean {
        return this.options?.length > this.meta.HIDE_SEARCH_LENGTH || !!this.groups?.length;
    }

    /** ********************** Other *********************** */

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, consistent-return
    public compareWith = (obj1: any, obj2: any) => {
        if (this.control.value !== null && this.selectData.invisibleSelectedOption)
            return obj2 === this.control.value;
    };

    private focusSearch() {
        if (!this.searchSelectInput || !this.selectElem.panel) {
            return;
        }
        // save and restore scrollTop of panel, since it will be reset by focus()
        // note: this is hacky
        const panel = this.selectElem.panel.nativeElement;
        const { scrollTop } = panel;
        // focus
        this.searchSelectInput.nativeElement.focus();
        panel.scrollTop = scrollTop;
    }

    public handleKeydown(event: KeyboardEvent): void {
        // Prevent propagation for all alphanumeric characters in order to avoid selection issues
        if (
            (event.key && event.key.length === 1) ||
            (event.keyCode >= A && event.keyCode <= Z) ||
            (event.keyCode >= ZERO && event.keyCode <= NINE) ||
            event.keyCode === SPACE
        ) {
            event.stopPropagation();
        }
    }

    public onSelectionChange(event: MatSelectChange): void {
        this.selectionChange.emit(event.value);
    }
}
