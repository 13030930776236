import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DatePipe, DialogService } from "@databank-ui";
import { TimeframeLabel, TimeframeSettingsOption } from "@core/enums/timeframes.enum";
import { TimeframeDropdownOption } from "@core/interfaces/timeframe.interface";
import {
    DEFAULT_TIMEFRAMES,
    TIMEFRAME_DATA_PARAMETERS,
    TIMEFRAME_DROPDOWN_OPTIONS,
} from "@core/constants/timeframe.constant";
import { Routing } from "@core/helpers/routing";
import { TimeframeService } from "@shared/services/timeframe.service";
import { TopBarTimeframeDialogComponent } from "../top-bar-timeframe-dialog/top-bar-timeframe-dialog.component";

@Component({
    selector: "dbp-top-bar-timeframe",
    templateUrl: "./top-bar-timeframe.component.html",
    styleUrls: ["./top-bar-timeframe.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe],
})
export class TopBarTimeframeComponent implements OnDestroy {
    public previousSetting: TimeframeSettingsOption;
    public currentSetting: TimeframeSettingsOption;
    public dropdownOptions: TimeframeDropdownOption[] = [];

    private destroy$ = new Subject<void>();

    constructor(
        private datePipe: DatePipe,
        private route: ActivatedRoute,
        private router: Router,
        private dialogService: DialogService,
        private timeframe: TimeframeService,
        private cdr: ChangeDetectorRef
    ) {
        const defaults = { ...DEFAULT_TIMEFRAMES };
        // init timeframes by default
        this.setOptions(defaults.list);

        const value = this.timeframe.getSettings();
        if (!value) {
            // init timeframes by default
            this.reset(defaults.value);
        } else {
            this.currentSetting = value.settings;
            this.previousSetting = value.settings;
        }

        // process page change events
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.initSettings();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSelect(value: TimeframeSettingsOption): void {
        this.currentSetting = value;
        if (value === TimeframeSettingsOption.Custom) {
            const frameInterval = this.timeframe.getFrameInterval(value);
            this.dialogService.custom(TopBarTimeframeDialogComponent, {
                data: {
                    content: frameInterval,
                    onConfirm: () => {
                        return (startDate, endDate) => {
                            this.setSettings(value, startDate, endDate);
                            this.updateCustomOptionLabel(startDate, endDate);
                        };
                    },
                    onReject: () => {
                        this.currentSetting = this.previousSetting;
                    },
                },
            });
        } else {
            this.setSettings(value);
        }
    }

    public reset(value = DEFAULT_TIMEFRAMES.value): void {
        this.timeframe.setSettings(value, null, null, true);

        // set up default values
        this.currentSetting = value;
        this.previousSetting = value;
    }

    private initSettings(): void {
        let options = [];

        // check special list of available options set up for the current route
        if (Routing.hasRouteDataValue(TIMEFRAME_DATA_PARAMETERS.options, this.route.snapshot)) {
            options =
                Routing.getRouteDataValue<TimeframeSettingsOption[]>(
                    TIMEFRAME_DATA_PARAMETERS.options,
                    this.route.snapshot
                ) || DEFAULT_TIMEFRAMES.list;
            this.setOptions(options);
        }

        // check selected timeframe to change
        if (Routing.hasRouteDataValue(TIMEFRAME_DATA_PARAMETERS.value, this.route.snapshot)) {
            const currentValue = this.timeframe.getSettings();

            const option =
                Routing.getRouteDataValue<TimeframeSettingsOption>(
                    TIMEFRAME_DATA_PARAMETERS.value,
                    this.route.snapshot
                ) || DEFAULT_TIMEFRAMES.value;

            let userSettingsToUse = option;

            if (
                // if current value is different vs to be set
                currentValue.settings !== option &&
                // and new value is in the list
                options.includes(option)
            ) {
                this.timeframe.setSettings(option, null, null, true);
            } else {
                userSettingsToUse = currentValue.settings;
            }

            this.currentSetting = userSettingsToUse;
            this.previousSetting = userSettingsToUse;
            this.cdr.detectChanges();
        }
    }

    private setOptions(optionsId: TimeframeSettingsOption[] = []): void {
        this.dropdownOptions = optionsId.map(optionId => {
            if (optionId === TimeframeSettingsOption.Custom) {
                const frameInterval = this.timeframe.getSettings();
                return {
                    id: TimeframeSettingsOption.Custom,
                    name: TimeframeLabel.Custom,
                    displayName: this.getCustomOptionLabel(
                        frameInterval?.startDate,
                        frameInterval?.endDate
                    ),
                };
            }
            return TIMEFRAME_DROPDOWN_OPTIONS.find(option => option.id === optionId);
        });
    }

    private getCustomOptionLabel(startDate: Date, endDate: Date): string {
        const formattedStartDate = startDate && this.datePipe.transform(startDate);
        const formattedEndDate = endDate && this.datePipe.transform(endDate);
        return !!formattedStartDate && !!formattedEndDate
            ? `${formattedStartDate} to ${formattedEndDate}`
            : "Custom";
    }

    private updateCustomOptionLabel(startDate: Date, endDate: Date): void {
        const option = this.dropdownOptions.find(
            setting => setting.id === TimeframeSettingsOption.Custom
        );
        option.displayName = this.getCustomOptionLabel(startDate, endDate);
    }

    private setSettings(
        settings: TimeframeSettingsOption,
        startDate?: Date,
        endDate?: Date,
        isDefault = false
    ): void {
        this.timeframe.setSettings(settings, startDate, endDate, isDefault);
        this.previousSetting = settings;
    }
}
