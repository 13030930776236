import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { LoggerService } from "@core/services/logger.service";
import { DialogService } from "@databank-ui";
import { Observable } from "rxjs";
import { IUnsavedChanges } from "../interfaces/unsaved-changes.interface";

@Injectable({
    providedIn: "root",
})
export class UnsavedChangesGuard implements CanDeactivate<IUnsavedChanges> {
    constructor(private loggerService: LoggerService, private dialog: DialogService) {}

    canDeactivate(component: IUnsavedChanges): Observable<boolean> | boolean {
        this.loggerService.log("[UnsavedChangesGuard] canDeactivate");
        if (component.hasUnsavedChanges()) {
            return this.dialog.unsavedChanges();
        }
        return true;
    }
}
