import { Component, Input } from "@angular/core";

@Component({
    selector: "dui-notify-area",
    templateUrl: "./notify-area.component.html",
    styleUrls: ["./notify-area.component.scss"],
})
export class NotifyAreaComponent {
    @Input() type: "warning" | "error" | "info" = "info";
}
