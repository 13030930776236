<div [loading]="(data.loading$ | async)?.loading">
    <div class="dui-dialog-header">
        <span class="dui-dialog-title flexible">{{data.title}}</span>
        <a href="javascript:void(0)" class="dui-dialog-close" mat-dialog-close>
            <i aria-hidden="true" class="flaticon2-cross"></i>
        </a>
    </div>
    <div class="dui-dialog-content" mat-dialog-content [innerHtml]="data.body">
    </div>
    <div class="dui-dialog-footer" mat-dialog-actions>
        <button mat-stroked-button *ngIf="!data.hideCancelButton"
                color="primary"
                (click)="reject()"
                mat-dialog-close
        >{{data.cancelButton}}</button>
        <button mat-flat-button
                color="primary"
                (click)="confirm()"
                cdkFocusInitial
        >{{data.okButton}}</button>
    </div>
</div>
