<div class="top-bar-user">
    <button mat-icon-button
            class="user-initials"
            [matMenuTriggerFor]="userPanel"
            matTooltip="My Profile"
            matTooltipPosition="below"
            matTooltipClass="below"
            id="top-btnMyProfile"
    >
        <mat-icon svgIcon="person"></mat-icon>
    </button>
    <mat-menu #userPanel="matMenu" xPosition="before" class="top-bar-panel user">
        <ng-container *ngIf="user$ | async as user">
            <div class="user-content">
                <span class="user-name">
                    {{user.firstName}} {{user.lastName}}
                </span>
            </div>
            <div class="user-email">
                {{user.emailAddress}}
            </div>
        </ng-container>
        <div class="separator"></div>
        <div class="user-routs">
            <button id="top-bar-user-go-to-profile__button" mat-menu-item [routerLink]="['/my-profile']">
                <mat-icon svgIcon="person"></mat-icon>
                <span>My profile</span>
            </button>
        </div>
    </mat-menu>
</div>
