import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DuiCoreModule } from "../../core";
import { ProgressComponent } from "./progress.component";

@NgModule({
    declarations: [ProgressComponent],
    imports: [CommonModule, DuiCoreModule, MatTooltipModule],
    providers: [],
    exports: [ProgressComponent],
})
export class DuiProgressModule {}
