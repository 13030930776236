import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { DuiCoreModule } from "../../core";
import { DuiMenuListModule } from "../menu-list";
import { WidgetSearchComponent } from "./widget-search.component";

@NgModule({
    declarations: [WidgetSearchComponent],
    imports: [
        CommonModule,
        DuiCoreModule,
        DuiMenuListModule,
        MatButtonModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
    ],
    exports: [WidgetSearchComponent],
})
export class DuiWidgetSearchModule {}
