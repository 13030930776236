import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { BadgeColor } from "../badge/badge.component";

export type StatusConfig<T> = {
    value: T;
    color: BadgeColor;
}[];

@Component({
    selector: "dui-status-badge",
    templateUrl: "./status-badge.component.html",
})
export class StatusBadgeComponent<T> implements OnChanges {
    @Input() value: T;
    @Input() config: StatusConfig<T>;
    @Input() isSolid = false;

    public statusColor: BadgeColor = BadgeColor.None;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.value?.currentValue || changes.config?.currentValue) {
            this.statusColor =
                this.config.find(item => item.value === this.value)?.color || BadgeColor.None;
        }
    }
}
