import { Injectable } from "@angular/core";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { DialogService, IdleDialogComponent, convertSecondToMMSS } from "@databank-ui";
import { MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { TitleService } from "@core/services/title.service";
import { LoggerService } from "@core/services/logger.service";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class IdleService {
    timeOutCountdown$: Observable<number>;
    sessionExpired$: Observable<void>;
    needToRefreshToken$: Observable<void>;
    private timeOutCountdownSbj = new BehaviorSubject(environment.idle.warningTime);
    private sessionExpiredSbj: Subject<void> = new Subject();
    private needToRefreshTokenSbj: Subject<void> = new Subject();
    private keepAliveInterval = environment.idle.sessionTime / 2.5; // Ping interval
    private tokenExpirationDate: number;
    private modalRef: MatDialogRef<IdleDialogComponent>;

    constructor(
        private dialog: DialogService,
        private idle: Idle,
        private keepalive: Keepalive,
        private authStorageService: AuthStorageService,
        private titleService: TitleService,
        private loggerService: LoggerService
    ) {
        this.timeOutCountdown$ = this.timeOutCountdownSbj.asObservable();
        this.sessionExpired$ = this.sessionExpiredSbj.asObservable();
        this.needToRefreshToken$ = this.needToRefreshTokenSbj.asObservable();
    }

    public initIdleSession(): void {
        this.loggerService.log("[IDLE] Init Idle");

        // how long can they be inactive before considered idle, in seconds
        this.idle.setIdle(environment.idle.sessionTime);

        // how long can they be idle before considered timed out, in seconds
        this.idle.setTimeout(environment.idle.warningTime);

        // provide sources that will "interrupt" aka provide events indicating the user is active
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        // Idle time is finish, show modal
        this.idle.onIdleStart.subscribe(() => {
            this.loggerService.log("[IDLE] Show idle dialog");

            this.idle.clearInterrupts();

            this.modalRef = this.dialog.custom(IdleDialogComponent, {
                data: {
                    timeLeft: environment.idle.warningTime,
                    countdown$: this.timeOutCountdown$,
                    onContinueSession: () => {
                        this.onContinueSession();
                    },
                },
            });
        });

        // do something when the user has timed out
        this.idle.onTimeout.subscribe(() => {
            this.loggerService.log("[IDLE] No activity. Logout.");
            this.onTimeOutSession();
        });

        // do something as the timeout countdown does its thing
        this.idle.onTimeoutWarning.subscribe(seconds => {
            this.timeOutCountdownSbj.next(seconds);
            this.titleService.setTitle(
                `${convertSecondToMMSS(seconds)} until your session times out!`
            );
        });

        this.keepalive.interval(this.keepAliveInterval); // will ping at this interval while not idle, in seconds
        this.keepalive.onPing.subscribe(() => {
            this.checkTokenExpiration();
        });
    }

    public stopSession(): void {
        this.loggerService.log("[IDLE] Stop Idle");
        this.idle.stop();
    }

    public startSession(): void {
        this.loggerService.log("[IDLE] Start Idle");
        const { accessTokenExpires } = this.authStorageService.getAuth();
        this.tokenExpirationDate = this.getTokenExpirationDate(accessTokenExpires);
        this.idle.watch();
    }

    private onTimeOutSession() {
        this.modalRef.close();
        this.stopSession();
        this.sessionExpiredSbj.next();
    }

    private onContinueSession() {
        this.loggerService.log("[IDLE] Continue session");

        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.titleService.setDefaultTitle();
        this.idle.watch();
    }

    private checkTokenExpiration() {
        const dateNow = new Date().getTime();
        const isTokenExpired = dateNow + this.keepAliveInterval * 1000 >= this.tokenExpirationDate;
        this.loggerService.log("[IDLE] If token expired in the next check?", isTokenExpired);

        if (isTokenExpired) {
            this.loggerService.log("[IDLE] Token Expired. Refresh token");
            this.needToRefreshTokenSbj.next();
        }
    }

    /**
     * Create token expiration date with gap.
     * Gap should be 5% of token life but not more than 2 minutes
     */
    private getTokenExpirationDate(tokenExpiredDate: string): number {
        const maxTimeGap = 120; // 2 minutes in second max
        const percent = 5; // percent of the gap
        const percentGap = (percent * environment.idle.tokenLifeTime) / 100; // 5% of the token life
        const gap = percentGap > maxTimeGap ? maxTimeGap : percentGap;
        const tokenExpiredLocalDate = new Date(tokenExpiredDate).getTime(); // utc to local
        const expirationTimeWithGap = tokenExpiredLocalDate - gap * 1000;

        this.loggerService.log("[IDLE] Set Expired Token Date", new Date(expirationTimeWithGap));
        return new Date(expirationTimeWithGap).getTime();
    }
}
