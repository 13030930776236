import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LoggerService } from "@core/services/logger.service";
import { AuthApiService } from "@core/services/auth-api.service";
import { Router } from "@angular/router";
import { HTTPStatusesEnum } from "@core/enums/http-statuses.enum";
import { NotificationService } from "@databank-ui";

export interface IHttpError {
    status: number;
    message: string;
    errorContent: object;
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private authApiService: AuthApiService,
        private loggerService: LoggerService,
        private router: Router,
        private notificationService: NotificationService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse | ErrorEvent | any) => {
                if (err instanceof HttpErrorResponse) {
                    // eslint-disable-next-line default-case
                    switch (err.status) {
                        case HTTPStatusesEnum.Unauthorized:
                            this.handleUnauthorized();
                            break;
                        case HTTPStatusesEnum.Forbidden:
                            this.router.navigate(["/access-denied"]);
                            break;
                        case HTTPStatusesEnum.ExpiredLink:
                            this.router.navigate(["/expired-link"]);
                            break;
                        case HTTPStatusesEnum.InvalidLink:
                            this.router.navigate(["/invalid-link"]);
                            break;
                        case HTTPStatusesEnum.ServerError:
                            this.notificationService.error(
                                "Oops, something went wrong. Server Error."
                            );
                            break;
                    }
                }

                const errorObj: IHttpError = this.handleErrorMessage(err);
                return throwError(errorObj);
            })
        );
    }

    private handleUnauthorized() {
        this.authApiService.clearStoragesAndNavigate();
    }

    private handleErrorMessage(err): IHttpError {
        let message: string;
        let status: number;

        if (err.error instanceof ErrorEvent) {
            message = `An error occurred: ${err.error.message}`;
            status = err.error.status;
        } else if (err.error && err.error.message) {
            message = `${err.error.message}`;
            status = err.status;
        } else {
            message = `Backend returned code ${err.status}: ${err.message}`;
            status = err.status;
        }

        // eslint-disable-next-line no-console
        console.error(message);

        return {
            message,
            status,
            errorContent: err.error || null,
        };
    }
}
