<mat-card>
    <mat-card-header>
        <div class="toolbar">
            <div class="toolbar-left">
                <div class="toolbar-group">
                    <mat-card-title>{{heading}}</mat-card-title>
                </div>
                <ng-content select="[toolbar-left]"></ng-content>
            </div>
            <div class="toolbar-right">
                <div class="toolbar-group" *ngIf="canExpand">
                    <button  mat-icon-button class="clean" (click)="expandContent()">
                        <mat-icon [ngClass]="{expanded: expanded}" svgIcon="dui:double_arrow_bold" class="edge-expand-icon"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </mat-card-header>

    <mat-card-content class="clear-paddings" *ngIf="expanded">
        <ng-content></ng-content>
    </mat-card-content>
</mat-card>
