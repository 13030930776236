import { ActivatedRouteSnapshot, Router } from "@angular/router";

export class Routing {
    static hasRouteDataValue(parameter: string, route: ActivatedRouteSnapshot): boolean | Function {
        // eslint-disable-next-line no-prototype-builtins
        if (route.data.hasOwnProperty(parameter)) {
            return true;
        }
        if (!route.children.length) {
            return false;
        }
        return Routing.hasRouteDataValue(parameter, route.firstChild);
    }

    static getRouteDataValue<T>(parameter: string, route: ActivatedRouteSnapshot): T {
        // eslint-disable-next-line no-prototype-builtins
        if (route.data.hasOwnProperty(parameter)) {
            return route.data[parameter];
        }
        if (!route.children.length) {
            return undefined;
        }
        return Routing.getRouteDataValue(parameter, route.firstChild);
    }

    static forceReloadState(router: Router): void {
        // Save initial router parameters
        const fn = router.routeReuseStrategy.shouldReuseRoute;
        const type = router.onSameUrlNavigation;

        // Set up force state reloading
        router.routeReuseStrategy.shouldReuseRoute = () => false;
        router.onSameUrlNavigation = "reload";

        // Force reload current route
        router.navigate([router.url]).finally(() => {
            // Restore saved initial settings
            router.routeReuseStrategy.shouldReuseRoute = fn;
            router.onSameUrlNavigation = type;
        });
    }
}
