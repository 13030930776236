import { NgModule } from "@angular/core";
import { ValidationErrorComponent } from "./validation-error.component";

@NgModule({
    declarations: [ValidationErrorComponent],
    imports: [],
    providers: [],
    exports: [ValidationErrorComponent],
})
export class DuiValidationErrorModule {}
