<div
    [class.clickable]="clickable"
    [ngClass]="{
        'info-widget-classic': type === typeEnum.classic,
        'info-widget-link-based': type === typeEnum.linkBased,

        'small': small,

        'default': colorType === colorTypeEnum.default,
        'error': colorType === colorTypeEnum.error,
        'info': colorType === colorTypeEnum.info,
        'success': colorType === colorTypeEnum.success,
        'primary': colorType === colorTypeEnum.primary,
        'disable': colorType === colorTypeEnum.disable,
        'dark': colorType === colorTypeEnum.dark
    }"
>
    <div class="icon-section" *ngIf="type === typeEnum.classic">
        <ng-content select="[icon]"></ng-content>
    </div>
    <div class="body-section">
        <span class="iw-title" *ngIf="heading">{{heading}}</span>
        <span class="iw-value" *ngIf="bodyText || bodyText === 0">{{bodyText}}</span>
        <p class="iw-description" *ngIf="description">{{description}}</p>
        <ng-content select="[body]"></ng-content>
    </div>
</div>
