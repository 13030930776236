import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { ExpiredLinkComponent } from "./components/expired-link/expired-link.component";
import { InvalidLinkComponent } from "./components/invalid-link/invalid-link.component";

@NgModule({
    declarations: [ExpiredLinkComponent, InvalidLinkComponent],
    imports: [CommonModule, MatIconModule, MatButtonModule, RouterModule],
    exports: [ExpiredLinkComponent, InvalidLinkComponent],
})
export class ExceptionPagesModule {}
