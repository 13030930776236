<div class="background"></div>
<div class="exception-page-wrapper">
    <main class="main">
        <mat-icon svgIcon="error" class="exception-icon error"></mat-icon>
        <div class="info-column">
            <h2 class="title">Oops! Invalid link</h2>
            <h3 class="subtitle">The link you are trying to access is invalid</h3>
            <button mat-flat-button class="big bold action-button" [routerLink]="backButton.route">{{backButton.text}}</button>
        </div>
    </main>
    <footer class="footer">
        <a [href]="commonUrls.databank.home" class="logo"></a>
    </footer>
</div>
