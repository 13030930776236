import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { NotificationComponent } from "./notification.component";
import { META_DATA } from "./notification.metadata";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    constructor(public notification: MatSnackBar) {}

    // eslint-disable-next-line default-param-last
    public info(data: string = META_DATA.info.data, config?: MatSnackBarConfig): void {
        this.open({ ...META_DATA.info, data, ...config });
    }

    // eslint-disable-next-line default-param-last
    public warning(data: string = META_DATA.warning.data, config?: MatSnackBarConfig): void {
        this.open({ ...META_DATA.warning, data, ...config });
    }

    // eslint-disable-next-line default-param-last
    public success(data: string = META_DATA.success.data, config?: MatSnackBarConfig): void {
        this.open({ ...META_DATA.success, data, ...config });
    }

    // eslint-disable-next-line default-param-last
    public error(data: string = META_DATA.error.data, config?: MatSnackBarConfig): void {
        this.open({ ...META_DATA.error, data, ...config });
    }

    // Custom notification, based on MatSnackBarConfig
    public custom(config: MatSnackBarConfig = { ...META_DATA.info }): void {
        this.open(config);
    }

    private open(config: MatSnackBarConfig): void {
        this.notification.openFromComponent(NotificationComponent, config);
    }
}
