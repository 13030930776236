import { DOCUMENT } from "@angular/common";
import { InjectionToken, Provider } from "@angular/core";

export const SUB_TOP_BAR = new InjectionToken<Element>("SubTopPar: html element");

export const SUB_TOP_BAR_PROVIDER: Provider = {
    provide: SUB_TOP_BAR,
    deps: [DOCUMENT],
    useFactory: (document: Document) => document.querySelector("#subTopBar"),
};
