import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

export enum ErrorType {
    Default = "default",
    Error = "error",
}

const Message = {
    [ErrorType.Default]: "No data to display",
    [ErrorType.Error]: "We’re sorry, the service is temporarily unavailable.",
};

@Component({
    selector: "dui-no-data",
    template: `
        <mat-icon svgIcon="dui:warning_outlined"></mat-icon>
        <span>{{ text || message[typeError] || defaultText }}</span>
    `,
    styleUrls: ["./no-data.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDataComponent {
    @Input() text: string;
    @Input() typeError: ErrorType;
    public message = Message;
    public defaultText = Message.default;
}
