import { Injectable } from "@angular/core";
import extend from "just-extend";
import { ExportFileTypes } from "../enums";

@Injectable()
export class UtilityService {
    public static isIEBrowser(): boolean {
        return (
            window.navigator.userAgent.indexOf("MSIE ") > -1 ||
            window.navigator.userAgent.indexOf("Trident/") > -1
        );
    }

    public static getISOStringInLocalFormat(date: Date, hours: string, minutes: string): string {
        const correctHours = hours && hours.toString().length === 1 ? `0${hours}` : hours;
        const correctMinutes = hours && minutes.toString().length === 1 ? `0${minutes}` : minutes;
        // eslint-disable-next-line no-useless-concat
        return `${date.toISOString().split("T")[0]}T${correctHours}:${correctMinutes}:` + `00.000`;
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public static deepClone(data: any): any {
        return JSON.parse(JSON.stringify(data));
    }

    // Performs a deep merge of objects. The same as Object.assign but deep
    public static deepMerge(target: object, ...sources: any[]): object {
        return extend(true, target, ...sources);
    }

    public static combineDateAndTime(date: Date, time: Date): Date {
        let resultDate = null;

        if (date) {
            resultDate = new Date(date.getTime());

            if (time) {
                resultDate.setHours(time.getHours());
                resultDate.setMinutes(time.getMinutes());
            }
        }

        return resultDate;
    }

    public static setStartDate(date: Date): Date {
        if (!date) {
            return null;
        }

        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    }

    public static setEndDate(date: Date): Date {
        if (!date) {
            return null;
        }

        const newDate = new Date(date);
        newDate.setHours(23, 59, 59, 999);
        return newDate;
    }

    public static openSeparateWindow(url: string): void {
        window.open(url, "window", "width=1000,height=700");
    }

    public static getFileExtension(fileType: ExportFileTypes): string {
        switch (fileType) {
            case ExportFileTypes.PDF:
                return ".pdf"; // TODO: exportDCAccessReportList method using not xlsx event if it chosen
            case ExportFileTypes.XLS:
                return ".xlsx";
            case ExportFileTypes.DOCX:
                return ".docx";
            case ExportFileTypes.DOC:
                return ".doc";
            case ExportFileTypes.XLSX:
                return ".xlsx";
            case ExportFileTypes.PPTX:
                return ".pptx";
            case ExportFileTypes.PPT:
                return ".ppt";
            case ExportFileTypes.CSV:
                return ".csv";
            default:
                return "";
        }
    }

    public static downloadFile(
        file: Blob,
        fileName: string,
        fileType?: ExportFileTypes | string
    ): void {
        // Add fileType to fileName
        if (fileType) {
            const mappedType: string = this.getFileExtension(fileType as ExportFileTypes);
            fileName += mappedType || (fileType as string);
        }

        const link = document.createElement("a");
        link.download = fileName;
        link.href = URL.createObjectURL(file);
        link.click();
        URL.revokeObjectURL(link.href);
    }
}
