import { Component, Input } from "@angular/core";

export enum BadgeColor {
    None = "",
    Success = "success-color",
    Error = "error-color",
    Warning = "warning-color",
    Info = "info-color",
    Primary = "primary-color",
    Default = "default-color",
}

@Component({
    selector: "dui-badge",
    templateUrl: "./badge.component.html",
    styleUrls: ["./badge.component.scss"],
})
export class BadgeComponent {
    @Input() isSolid = false;
    @Input() color: BadgeColor;
}
