import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { CardComponent } from "./card.component";
import { DuiCoreModule } from "../../core";

@NgModule({
    declarations: [CardComponent],
    imports: [CommonModule, MatIconModule, MatCardModule, DuiCoreModule, MatButtonModule],
    providers: [],
    exports: [CardComponent],
})
export class DuiCardModule {}
