import { IPermissionCustom, PermissionCheckType } from "../interfaces/permission.interface";
import { PERMISSIONS } from "./permissions.constant";

export const PERMISSIONS_CUSTOM: IPermissionCustom = {
    MyAccount: {
        permissions: [
            PERMISSIONS.MyAccountManageUsers,
            PERMISSIONS.MyAccountManageRoles,
            PERMISSIONS.ProfilePage,
        ],
        checkType: PermissionCheckType.Some,
    },
    MyProfile: {
        permissions: [
            PERMISSIONS.MyProfilePagePersonalInformation,
            PERMISSIONS.MyProfilePageCurrentSessions,
            PERMISSIONS.MyProfilePageRole,
        ],
        checkType: PermissionCheckType.Some,
    },
};
