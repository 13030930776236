import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ISetting } from "@core/interfaces/settings.interface";
import { LocalStorageService } from "@core/services/local-storage.service";
import { LoggerService } from "@core/services/logger.service";
import { map } from "rxjs/operators";
import extend from "just-extend";
import { SETTINGS_LS_KEY } from "@shared/constants/local-storage-keys.constant";

const DEFAULT_SETTING: ISetting = {
    userName: null,
    isCurrent: null,
    sideBarCollapsed: false,
    rememberMe: null,
    timeframe: null,
    partner: null,
    portalSettings: null,
};

@Injectable({
    providedIn: "root",
})
export class SettingStorageService {
    settings$: Observable<ISetting[]>;
    private settingsData$: BehaviorSubject<ISetting[]> = new BehaviorSubject([]);

    constructor(
        private localStorageService: LocalStorageService,
        private loggerService: LoggerService
    ) {
        this.settings$ = this.settingsData$.asObservable();
        this.initSettingData();
    }

    /**
     * Init data from local storage
     */
    initSettingData(): void {
        const storageData: ISetting[] = this.localStorageService.getItem(SETTINGS_LS_KEY);
        if (storageData) {
            this.settingsData$.next(storageData);
        }
    }

    /**
     * Init user in first login
     */
    initUserSetting(userName: string): void {
        // todo: refactor
        if (this.hasUserSetting(userName)) {
            this.updateStorage(userName, {
                ...DEFAULT_SETTING,
                ...this.getUserSettingByName(userName),
                userName,
            });
        } else {
            this.addStorage({
                ...DEFAULT_SETTING,
                userName,
            });
        }

        this.setUserCurrent(userName);
    }

    /**
     * Setting data
     */
    updateSetting(setting: Partial<ISetting>): void {
        const currentUserName = this.getUserName();
        const currentUserSetting = this.getCurrentUserSetting();
        this.updateStorage(currentUserName, extend(true, {}, currentUserSetting, setting));
    }

    getSetting(): ISetting {
        return this.getCurrentUserSetting();
    }

    selectSetting(): Observable<ISetting[]> {
        return this.settings$;
    }

    clearSettingData(): void {
        this.settingsData$.next(null);
    }

    checkIfRememberMeOn(): boolean {
        return this.getCurrentUserSetting()?.rememberMe;
    }

    /**
     * User Name
     */
    getUserName(): string {
        return this.getCurrentUserSetting()?.userName;
    }

    /**
     * Side Bar collapsed | expanded state
     */
    isSidebarCollapsed(): Observable<boolean> {
        return this.selectCurrentUserSetting().pipe(
            map((item: ISetting) => !!item?.sideBarCollapsed)
        );
    }

    /**
     * Private
     */
    private hasUserSetting(userName: string): boolean {
        const users = this.settingsData$.getValue();
        return Boolean(users.find(user => user.userName === userName));
    }

    private getCurrentUserSetting(): ISetting {
        const users = this.settingsData$.getValue();
        return users.find(user => user.isCurrent);
    }

    private selectCurrentUserSetting(): Observable<ISetting> {
        return this.settings$.pipe(map(settings => settings.find(item => item.isCurrent)));
    }

    private getUserSettingByName(userName: string): ISetting {
        const users = this.settingsData$.getValue();
        return users.find(user => user.userName === userName);
    }

    private setUserCurrent(userName: string): void {
        this.resetIsCurrentFlag();
        this.updateStorage(userName, {
            isCurrent: true,
        });
    }

    // Reset IsCurrent flag in all users
    private resetIsCurrentFlag(): void {
        let usersSettings = [...this.settingsData$.getValue()];
        usersSettings = usersSettings.map(setting => {
            return {
                ...setting,
                isCurrent: false,
            };
        });

        this.settingsData$.next(usersSettings);
        this.localStorageService.setItem(SETTINGS_LS_KEY, usersSettings);
    }

    private addStorage(settings) {
        const usersSetting = [...this.settingsData$.getValue()];
        // Todo: Check exist
        usersSetting.push(settings);
        this.loggerService.log("[SettingStorageService] Add storage", {
            usersSetting,
        });
        this.settingsData$.next(usersSetting);
        this.localStorageService.setItem(SETTINGS_LS_KEY, usersSetting);
    }

    private updateStorage(userName: string, settings) {
        const usersSetting = [...this.settingsData$.getValue()];
        const storageData = usersSetting.map(userSetting => {
            if (userSetting.userName === userName) {
                return {
                    ...userSetting,
                    ...settings,
                };
            }
            return userSetting;
        });

        this.loggerService.log("[SettingStorageService] Update storage", {
            storageData,
        });
        this.settingsData$.next(storageData);
        this.localStorageService.setItem(SETTINGS_LS_KEY, storageData);
    }
}
