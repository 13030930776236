import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from "@angular/core";
import { AbstractControl, FormControl } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { debounceTime, filter, takeWhile } from "rxjs/operators";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { PaginatorComponent } from "../paginator/paginator.component";

export type MultiselectList = MultiselectListItem[];
export type MultiselectListItem = { [key: string]: string | boolean };
type ItemId = string | number;
type ListItem = {
    selected: boolean;
    show: boolean;
    disabled: boolean;
    data: MultiselectListItem;
};

@Component({
    selector: "dui-multiselect-list",
    templateUrl: "./multiselect-list.component.html",
    styleUrls: ["./multiselect-list.component.scss"],
})
export class MultiselectListComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() displayedColumns: { value: string; title: string }[];
    @Input() tableData: MultiselectList;
    @Input() idField: string;
    @Input() disabledField: string;
    @Input() control: FormControl;
    @Input() label: string;
    @Input() selectedTabIsDefault = false;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("paginator") isPaginatorActive = false;
    @Output() selectedChange = new EventEmitter<ItemId[]>();
    @ViewChild(PaginatorComponent) paginatorComp: PaginatorComponent;

    public itemsList: ListItem[] = [];
    public selectedCount = 0;
    public selectedEnabledCount = 0;
    public enabledCount = 0;
    public paginatorData = {
        all: {
            page: 0,
            totalItems: 0,
        },
        selected: {
            page: 0,
            totalItems: 0,
        },
        itemsPerPage: 7,
    };

    public searchControl = new FormControl<string>("");
    public allTabTooltip: string;
    public selectedTabTooltip: string;

    private paginationItemsList: ListItem[] = [];
    private isSelectedPage: boolean;
    private isComponentActive = true;
    private ignoreValueChange = false;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (this.control) {
            // Subscribe to formControl change
            this.control.valueChanges
                .pipe(
                    takeWhile(() => this.isComponentActive),
                    filter(() => {
                        const allowValue = !this.ignoreValueChange;
                        this.ignoreValueChange = false;
                        return allowValue;
                    })
                )
                .subscribe(value => {
                    this.updateSelectedItems(value);
                });
        }
        this.updateTooltips();

        // Subscribe to search change
        this.searchControl.valueChanges
            .pipe(
                takeWhile(() => this.isComponentActive),
                debounceTime(300)
            )
            .subscribe(value => this.searchItems(value));

        this.isSelectedPage = this.selectedTabIsDefault;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.tableData && changes.tableData.currentValue) {
            this.itemsList = this.tableData.map(item => ({
                selected: false,
                show: true,
                disabled: this.isItemDisabled(item),
                data: item,
            }));
            if (this.isPaginatorActive) {
                this.paginatorData.all.totalItems = this.itemsList.length;
                this.paginationItemsList = this.itemsList;
            }
            this.updateSelectedItems(this.control.value);
            this.searchControl.setValue("");
        }
    }

    ngAfterViewInit(): void {
        if (this.isPaginatorActive) {
            this.updatePaginationByType("all");
            this.paginatorComp.paginator.page
                .pipe(takeWhile(() => this.isComponentActive))
                .subscribe(() => {
                    this.changePaginationPage(this.paginatorComp.paginator.pageIndex);
                });
        }
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.isComponentActive = false;
    }

    get isControlDisabled(): boolean {
        return this.control && this.control.disabled;
    }

    @HostBinding("class.ng-invalid")
    get isErrorVisible(): boolean {
        return this.control && this.control.invalid && this.control.touched;
    }

    get isRequired(): boolean {
        const validator =
            this.control && this.control.validator
                ? this.control.validator({} as AbstractControl)
                : null;
        return validator && validator.required;
    }

    public onCheckboxAllChange({ checked }: MatCheckboxChange): void {
        this.selectedCount = 0;
        this.selectedEnabledCount = 0;
        const list = this.isPaginatorActive ? this.paginationItemsList : this.itemsList;

        list.forEach(item => {
            if (!item.disabled) item.selected = checked;
            if (item.selected) this.selectedCount++;
            if (item.selected && !item.disabled) this.selectedEnabledCount++;
        });

        this.updateTooltips();
        this.emitSelectedItems();
    }

    public onCheckboxSingleChange({ checked }: MatCheckboxChange, item: ListItem): void {
        if (checked) {
            this.selectedCount++;
            this.selectedEnabledCount++;
            item.selected = true;
        } else {
            this.selectedCount--;
            this.selectedEnabledCount--;
            item.selected = false;
        }

        if (this.isSelectedPage) this.updatePaginationByType("selected");
        else this.updatePaginationByType("all");

        this.updateTooltips();
        this.emitSelectedItems();
    }

    public trackByItemId(index: number, item: ListItem): string {
        return item.data[this.idField] as string;
    }

    private updateSelectedItems(newSelected: ItemId[]): void {
        this.selectedCount = 0;
        this.selectedEnabledCount = 0;
        this.enabledCount = 0;
        const list = this.isPaginatorActive ? this.paginationItemsList : this.itemsList;

        list.forEach(item => {
            const selected = newSelected?.find(id => item.data[this.idField] === id);

            if (selected != null) {
                this.selectedCount++;
                item.selected = true;
                if (!item.disabled) this.selectedEnabledCount++;
            } else {
                item.selected = false;
            }
            if (!item.disabled) this.enabledCount++;
        });
        this.updateTooltips();

        if (this.isPaginatorActive && this.paginatorComp) {
            this.paginatorData.selected.page = 0;
            if (this.isSelectedPage) this.updatePaginationByType("selected");
            else this.updatePaginationByType("all");
        }
    }

    private emitSelectedItems(): void {
        let selectedIndices: any = [];
        const list = this.isPaginatorActive ? this.paginationItemsList : this.itemsList;

        list.forEach(item => {
            if (item.selected) selectedIndices.push(item.data[this.idField]);
        });
        if (selectedIndices.length === 0) selectedIndices = null;

        this.ignoreValueChange = true;
        this.updateControl(selectedIndices);
        this.selectedChange.emit(selectedIndices);
    }

    private updateTooltips(): void {
        if (this.selectedEnabledCount === 0) this.selectedTabTooltip = "";
        else this.selectedTabTooltip = "Unselect all";

        if (this.enabledCount === 0) this.allTabTooltip = "";
        else if (this.selectedEnabledCount === this.enabledCount)
            this.allTabTooltip = "Unselect all";
        else this.allTabTooltip = "Select all";
    }

    private updateControl(value: any[]): void {
        if (this.control) {
            this.control.setValue(value);
            this.control.markAsTouched();
            this.control.markAsDirty();
        }
    }

    private searchItems(searchString: string): void {
        const searchStr = searchString.toLowerCase();
        const list = this.isPaginatorActive ? this.paginationItemsList : this.itemsList;

        if (!searchStr.length) {
            list.forEach(item => {
                item.show = true;
            });
        } else {
            list.forEach(item => {
                let containsSearchString = false;
                for (const col of this.displayedColumns) {
                    if (
                        item.data[col.value] &&
                        (item.data[col.value] as string).toLowerCase().includes(searchStr)
                    ) {
                        containsSearchString = true;
                        break;
                    }
                }
                if (containsSearchString) item.show = true;
                else item.show = false;
            });
        }
        this.updatePaginationAfterSearch();
        this.updateTooltips();
    }

    private isItemDisabled(item: MultiselectListItem): boolean {
        if (this.disabledField) {
            return item[this.disabledField] as boolean;
        }
        return false;
    }

    /** ********************* Pagination ********************** */

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public onTabChanged(tab: MatTabChangeEvent): void {
        if (tab.index) {
            this.isSelectedPage = true;
            this.updatePaginationByType("selected");
        } else {
            this.isSelectedPage = false;
            this.updatePaginationByType("all");
        }
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public isShowPaginator(): boolean {
        if (
            this.isSelectedPage &&
            this.paginatorData.selected.totalItems <= this.paginatorData.itemsPerPage
        ) {
            return true;
        }
        if (
            this.isSelectedPage &&
            this.paginatorData.all.totalItems <= this.paginatorData.itemsPerPage
        ) {
            return true;
        }
        return false;
    }

    private updatePaginationByType(type: "all" | "selected"): void {
        if (this.isPaginatorActive) {
            this.changePaginationPage(this.paginatorData[type].page);
            this.paginatorComp.paginator.pageIndex = this.paginatorData[type].page;
            this.paginatorComp.paginator.pageSize = this.paginatorData.itemsPerPage;
            this.paginatorComp.paginator.length = this.paginatorData[type].totalItems;
        }
    }

    private changePaginationPage(page: number): void {
        const startList = page * this.paginatorData.itemsPerPage;
        const endList = startList + this.paginatorData.itemsPerPage;
        const paginationItems = this.paginationItemsList.filter(item =>
            this.isSelectedPage ? item.show && item.selected : item.show
        );
        this.itemsList = paginationItems.slice(startList, endList);
        if (this.isSelectedPage) {
            this.paginatorData.selected.page = page;
            this.paginatorData.selected.totalItems =
                paginationItems.length || this.paginatorData.itemsPerPage;
        } else {
            this.paginatorData.all.page = page;
            this.paginatorData.all.totalItems =
                paginationItems.length || this.paginatorData.itemsPerPage;
        }
    }

    private updatePaginationAfterSearch(): void {
        if (this.isPaginatorActive) {
            this.paginatorComp.paginator.pageIndex = 0;
            this.changePaginationPage(0);
            if (this.isSelectedPage) this.updatePaginationByType("selected");
            else this.updatePaginationByType("all");
        }
    }
}
