<div class="top-bar-select">
    <span class="select-name">Timeframe:</span>
    <mat-form-field>
        <mat-select panelClass="top-bar-select-panel"
                    [value]="currentSetting"
                    id="top-timeframe"
        >
            <mat-option *ngFor="let timeFrame of dropdownOptions"
                        [value]="timeFrame.id"
                        (click)="onSelect(timeFrame.id)"
            >{{timeFrame.displayName || timeFrame.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
