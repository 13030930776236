import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { PaginatorComponent } from "./paginator.component";

@NgModule({
    declarations: [PaginatorComponent],
    imports: [CommonModule, MatPaginatorModule, MatButtonModule, MatIconModule],
    exports: [PaginatorComponent],
    providers: [],
})
export class DuiPaginatorModule {}
