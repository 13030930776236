<div class="notify-section" [ngClass]="{'warning': type === 'warning', 'error': type === 'error', 'info': type === 'info'}">
    <ng-container [ngSwitch]="type">
        <div>
            <mat-icon *ngSwitchCase="'warning'" svgIcon="dui:warning"></mat-icon>
            <mat-icon *ngSwitchCase="'error'" svgIcon="dui:error"></mat-icon>
            <mat-icon *ngSwitchCase="'info'" svgIcon="dui:info"></mat-icon>
        </div>
    </ng-container>
    <span class="notify-content">
        <ng-content></ng-content>
    </span>
</div>