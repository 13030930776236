<ul class="dui-notification-container">
    <li class="dui-notification-icon">
        <mat-icon class="dui-notification-info-icon" svgIcon="dui:info"></mat-icon>
        <mat-icon class="dui-notification-warning-icon" svgIcon="dui:warning"></mat-icon>
        <mat-icon class="dui-notification-success-icon" svgIcon="dui:success"></mat-icon>
        <mat-icon class="dui-notification-error-icon" svgIcon="dui:error"></mat-icon>
    </li>
    <li class="dui-notification-message flexible" [innerHTML]="data"></li>
    <li class="dui-notification-ctrl">
        <a href="javascript:void(0)" class="dui-icon-link" (click)="ref.dismiss()">
            <mat-icon svgIcon="dui:clear"></mat-icon>
        </a>
    </li>
</ul>
