import { TablePaging, TableSorting } from "./table-expandable.interface";

export const EXPAND_TABLE_CONFIG = {
    defaultItemsPerPage: 6,
    collapseColumn: { value: "collapse_action", title: "" },
};

export const DEFAULT_SORTING: TableSorting = {
    property: "",
    direction: "",
};

export const DEFAULT_PAGING: TablePaging = {
    page: 1,
    itemsPerPage: 6,
    totalItems: 0,
};
