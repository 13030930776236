import { Component, Input } from "@angular/core";
import { AbstractControl, UntypedFormControl } from "@angular/forms";

@Component({
    selector: "dui-phone-input",
    templateUrl: "./phone-input.component.html",
    styleUrls: ["./phone-input.component.scss"],
})
export class PhoneInputComponent {
    @Input() control: UntypedFormControl;
    @Input() label = "Phone Number";
    @Input() highlighted = false;

    get isRequired(): boolean {
        const validator = this.control?.validator
            ? this.control.validator({} as AbstractControl)
            : null;
        return validator && validator.required;
    }
}
