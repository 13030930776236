<div class="top-bar-group">
    <button
        mat-icon-button
        class="clean"
        matTooltip="Log Out"
        matTooltipPosition="below"
        matTooltipClass="below"
        id="top-btnLogOff"
        (click)="logout()"
    >
        <mat-icon svgIcon="logout"></mat-icon>
    </button>
</div>
