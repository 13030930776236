import { MatSnackBarConfig } from "@angular/material/snack-bar";

const baseClass = "dui-notification";

export const META_DATA: { [key: string]: MatSnackBarConfig } = {
    info: {
        panelClass: [baseClass, "info"],
        data: "Notification",
        duration: 4000,
    },
    warning: {
        panelClass: [baseClass, "warning"],
        data: "Warning!",
        duration: 4000,
    },
    success: {
        panelClass: [baseClass, "success"],
        data: "Success!",
        duration: 4000,
    },
    error: {
        panelClass: [baseClass, "error"],
        data: "Error!",
    },
};
