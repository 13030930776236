import { Component, OnInit } from "@angular/core";
import { IdleService } from "@core/services/idle.service";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { AuthApiService } from "@core/services/auth-api.service";
import { TitleService } from "@core/services/title.service";
import { NotificationService } from "@databank-ui";

@Component({
    selector: "dbp-root",
    templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
    private expiredMessage = "Your session is expired";

    constructor(
        private idleService: IdleService,
        private authStorageService: AuthStorageService,
        private authApiService: AuthApiService,
        private titleService: TitleService,
        private notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this.idleService.initIdleSession();

        this.authStorageService.isAuthorized$().subscribe(isAuthorized => {
            if (isAuthorized) {
                this.idleService.startSession();
            } else {
                this.idleService.stopSession();
            }
        });

        this.idleService.sessionExpired$.subscribe(() => {
            this.titleService.setDefaultTitle();
            this.notificationService.custom({
                duration: 0,
                data: this.expiredMessage,
                panelClass: ["dui-notification", "warning"],
            });
            this.authApiService.logout();
            this.authApiService.clearStoragesAndNavigate();
        });

        this.idleService.needToRefreshToken$.subscribe(() => {
            this.authApiService.refreshToken();
        });
    }
}
