import { Component, ChangeDetectionStrategy } from "@angular/core";
import { commonUrls } from "@core/constants/url.constant";

@Component({
    selector: "dbp-login-footer",
    templateUrl: "./login-footer.component.html",
    styleUrls: ["./login-footer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFooterComponent {
    public readonly commonUrls = commonUrls;
}
