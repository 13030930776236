<div (click)="onClose()">
    <div class="dui-dialog-header">
        <span class="dui-dialog-title flexible">{{meta.title}}</span>
    </div>
    <div class="dui-dialog-content" mat-dialog-content>
        <p>{{meta.message}}</p>
        <br />
        <dui-progress type="TIMER" [limit]="data.timeLeft" [value]="data.countdown$ | async"></dui-progress>
    </div>
</div>

