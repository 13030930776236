/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-classes-per-file */

import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ComponentType } from "@angular/cdk/portal";
import { Observable, Subject } from "rxjs";
import { DialogComponent } from "./dialog.component";
import { IModalCommonConfig } from "./dialog.interface";
import { META_DATA } from "./dialog.metadata";

class DialogConfig implements MatDialogConfig {
    panelClass: string | string[] = META_DATA.confirmation.panelClass;
    data: IModalCommonConfig = new ModalCommonConfig();
    maxWidth: string | number;

    constructor(data?: MatDialogConfig) {
        if (data) {
            this.panelClass = data.panelClass || this.panelClass;
            this.data = { ...META_DATA.confirmation.data, ...data.data };
            this.maxWidth = data.maxWidth || META_DATA.defaultModalWidth;
        }
    }
}

export class ModalCommonConfig implements IModalCommonConfig {
    title: string;
    body: string;
    okButton: string;
    cancelButton: string;
    onReject: () => void;
    onConfirm: () => void;
    onClose: () => void;
    onCancel: () => void;

    constructor(data?: IModalCommonConfig) {
        this.title = data?.title || META_DATA.confirmation.data.title;
        this.body = data?.body || META_DATA.confirmation.data.body;
        this.okButton = data?.okButton || META_DATA.confirmation.data.okButton;
        this.title = data?.cancelButton || META_DATA.confirmation.data.cancelButton;
        this.onReject = data?.onReject || (() => {});
        this.onConfirm = data?.onConfirm || (() => {});
        this.onClose = data?.onClose || (() => {});
        this.onCancel = data?.onCancel || (() => {});
    }
}

@Injectable({
    providedIn: "root",
})
export class DialogService {
    constructor(public dialog: MatDialog) {}

    // open standard confirmation dialog
    public confirm(options?: MatDialogConfig): void {
        this.dialog.open(DialogComponent, new DialogConfig(options));
    }

    // open dialog using custom component
    public custom<T>(component: ComponentType<T>, options: MatDialogConfig): MatDialogRef<T> {
        const dialogRef = this.dialog.open(component, new DialogConfig(options));

        return dialogRef;
    }

    public unsavedChanges(): Observable<boolean> {
        const subj = new Subject<boolean>();
        this.confirm({
            data: {
                body: "You have unsaved changes on this page. Are you sure you want to leave this page and discard your changes?",
                title: "Confirmation",
                onConfirm: () => {
                    subj.next(true);
                    subj.complete();
                },
                onReject: () => {
                    subj.next(false);
                    subj.complete();
                },
                okButton: "Ok",
                cancelButton: "Cancel",
            },
        });
        return subj.asObservable();
    }
}
