import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IAuthData } from "@core/interfaces/auth-data.interface";
import { LocalStorageService } from "@core/services/local-storage.service";
import { LoggerService } from "@core/services/logger.service";
import { distinctUntilChanged, map } from "rxjs/operators";

const STORAGE_KEY = "dbp-auth";

@Injectable({
    providedIn: "root",
})
export class AuthStorageService {
    auth$: Observable<IAuthData>;
    private authData$: BehaviorSubject<IAuthData> = new BehaviorSubject(null);

    constructor(
        private localStorageService: LocalStorageService,
        private loggerService: LoggerService
    ) {
        this.auth$ = this.authData$.asObservable();
        this.initAuthData();
    }

    /**
     * Init data from local storage
     */
    initAuthData(): void {
        const storageData = this.localStorageService.getItem(STORAGE_KEY);
        if (storageData) {
            this.authData$.next(storageData as any);
        }
    }

    setAuthData(authData: IAuthData): void {
        this.loggerService.log("[AuthStorageService] Update storage", authData);
        const updatedData = { ...authData };
        this.authData$.next(updatedData);
        this.localStorageService.setItem(STORAGE_KEY, updatedData);
    }

    clearAuthData(): void {
        this.authData$.next(null);
        this.localStorageService.removeItem(STORAGE_KEY);
    }

    getAuth(): IAuthData {
        return this.authData$.getValue();
    }

    selectAuth$(): Observable<IAuthData> {
        return this.auth$;
    }

    isAuthorized(): boolean {
        const authData = this.authData$.getValue();
        return !!authData?.accessToken && !authData?.isPasswordExpired;
    }

    isAuthorized$(): Observable<boolean> {
        return this.auth$.pipe(
            map(item => !!item?.accessToken && !item?.isPasswordExpired),
            distinctUntilChanged()
        );
    }

    isPasswordExpired(): boolean {
        return this.getAuth()?.isPasswordExpired;
    }
}
