import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatCardModule } from "@angular/material/card";
import { DuiCoreModule } from "../../core";
import { DuiMenuListModule } from "../menu-list";
import { WidgetCardComponent } from "./widget-card.component";

@NgModule({
    declarations: [WidgetCardComponent],
    imports: [
        CommonModule,
        DuiCoreModule,
        DuiMenuListModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        MatCardModule,
    ],
    exports: [WidgetCardComponent],
})
export class DuiWidgetCardModule {}
