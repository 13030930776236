import { APP_INITIALIZER, NgModule } from "@angular/core";
import { CORE_PIPES } from "@core/constants/pipes.constant";
import { IconsRegisterService } from "./services/icons-register.service";
import { CORE_DIRECTIVES } from "./constants/directives.constant";

@NgModule({
    declarations: [...CORE_PIPES, ...CORE_DIRECTIVES],
    providers: [
        IconsRegisterService,
        {
            provide: APP_INITIALIZER,
            useFactory: (iconsRegisterService: IconsRegisterService) => () => {
                return iconsRegisterService.registerIcons();
            },
            deps: [IconsRegisterService],
            multi: true,
        },
    ],
    exports: [...CORE_PIPES, ...CORE_DIRECTIVES],
})
export class CoreModule {}
