import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "duiBytesConverter" })
export class BytesConverterPipe implements PipeTransform {
    transform(bytes: number, decimals: number = 1): string {
        if (!bytes) {
            return "0 Bytes";
        }

        let isNegative = false;
        if (bytes < 0) {
            isNegative = true;
            bytes = -bytes;
        }

        const oneKbSize = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const type = Math.floor(Math.log(bytes) / Math.log(oneKbSize));

        return `${isNegative ? "-" : ""}${parseFloat(
            (bytes / oneKbSize ** type).toFixed(decimals)
        )} ${sizes[type]}`;
    }
}
