import { HttpClient } from "@angular/common/http";
import { InjectionToken, Provider } from "@angular/core";
import { webApiUrls } from "@core/constants/url.constant";
import { NotificationService, UtilityService } from "@databank-ui";
import { Observable, of, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";

const errorMsg = {
    request: "Failed to download file.",
    filename: "Unknown filename.",
};

export type GetAttachmentFn = (attachmentId: string) => Observable<void>;

export const GET_ATTACHMENT = new InjectionToken<GetAttachmentFn>("Download file by attachmentId");

export const GET_ATTACHMENT_PROVIDER: Provider = {
    provide: GET_ATTACHMENT,
    deps: [HttpClient, NotificationService],
    useFactory: (http: HttpClient, notify: NotificationService): GetAttachmentFn => {
        return (attachmentId: string) => {
            const path = `${webApiUrls.base}/attachment/${attachmentId}`;
            return http
                .get<Blob>(path, { responseType: "blob" as "json", observe: "response" })
                .pipe(
                    catchError(err => {
                        notify.error(errorMsg.request);
                        return throwError(err);
                    }),
                    switchMap(response => {
                        const contentDisposition = response.headers.get("content-disposition");
                        const matchResult = contentDisposition?.match(/filename\*=UTF-8''(.*)/);

                        if (!matchResult) {
                            notify.error(errorMsg.filename);
                            return throwError(errorMsg.filename);
                        }

                        const filename = decodeURIComponent(matchResult[1]);
                        UtilityService.downloadFile(response.body, filename);
                        return of(null);
                    })
                );
        };
    },
};
