<mat-card class="file-card">
    <div class="file-card-info">
        <div class="file-card-field">
            <span class="file-size">{{ file.size | duiBytesConverter }}</span>
        </div>
        <div class="file-card-field" [matTooltip]="file.name"
            matTooltipPosition="below" matTooltipClass="below"
        >
            <span class="file-name" >{{ file.name }}</span>
        </div>
    </div>
    <button class="file-card-remove" mat-icon-button (click)="removeFile()">
        <mat-icon svgIcon="dui:clear"></mat-icon>
    </button>
</mat-card>
