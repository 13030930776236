import { Component, Inject } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

export * from "./notification.metadata";

@Component({
    selector: "dui-notification",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent {
    constructor(
        public ref: MatSnackBarRef<NotificationComponent>,
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        @Inject(MAT_SNACK_BAR_DATA) public data: string
    ) {}
}
