// Todo: Discuss. Move to app?
export enum ExportFileTypes {
    PDF = 1,
    XLS = 2,
    CSV = 3,
    DOCX = 4,
    DOC = 5,
    XLSX = 6,
    PPTX = 7,
    PPT = 8,
}
