import {
    Component,
    ChangeDetectionStrategy,
    OnDestroy,
    OnInit,
    ChangeDetectorRef,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { commonUrls } from "@core/constants/url.constant";
import { PERMISSIONS, PERMISSIONS_ACTIONS } from "@core/constants/permissions.constant";
import { PermissionsStorageService } from "@core/services/storages/permissions-storage.service";
import { IPermissionCustomDetails } from "@core/interfaces/permission.interface";
import { Event as RouterEvent, NavigationEnd, Router } from "@angular/router";
import { UtilityService } from "@databank-ui";
import { IMenuItemParent, MENU_ITEMS } from "./sidebar.metadata";

@Component({
    selector: "dbp-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit, OnDestroy {
    public menu: IMenuItemParent[];
    public readonly commonUrls = commonUrls;
    public isVisible = true;
    private isCollapsed = false;
    private isHovered = false;
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private router: Router,
        private settingsService: SettingStorageService,
        private permissionsService: PermissionsStorageService,
        private permissionsStorageService: PermissionsStorageService,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.menu = UtilityService.deepClone(MENU_ITEMS);
        this.updateMyAccountOverviewMenuItem();
        /**
         * Track expand | collapse state globally
         */
        this.settingsService
            .isSidebarCollapsed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(isCollapsed => {
                this.isCollapsed = isCollapsed;
                // off hovered flag
                if (isCollapsed) {
                    this.isHovered = false;
                }
            });

        /**
         * Router events processing
         */
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: RouterEvent) => {
            if (event instanceof NavigationEnd) {
                // Deactivate active items
                this.deactivateMenuItems();
                // find and activate related item(s)
                this.activateMenuItem(event.urlAfterRedirects);

                // // Emit this change
                this.cdr.detectChanges();
            }
        });

        this.permissionsStorageService.data.pipe(takeUntil(this.destroy$)).subscribe(value => {
            if (value) {
                this.applyPermissions();
            } else {
                this.menu = [];
            }
        });

        this.activateMenuItem(this.router.url);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    /** ********************************************************************************************
     * EXPAND | COLLAPSE | HOVER panel
     ********************************************************************************************* */
    get statusClass(): string {
        return `${this.isCollapsed ? "collapsed" : ""} ${this.isHovered ? "hovered" : ""}`;
    }

    public toggleStatus(): void {
        this.settingsService.updateSetting({
            sideBarCollapsed: !this.isCollapsed,
        });
    }

    public hover(): void {
        this.isHovered = true;
    }

    public unhover(): void {
        this.isHovered = false;
    }

    /** ********************************************************************************************
     * EXPAND | COLLAPSE menu item
     ********************************************************************************************* */

    public toggle(event: Event, index: number): void {
        event.preventDefault();
        this.menu[index].expanded = !this.menu[index].expanded;
    }

    /**
     * STEP #1: MENU GENERATION -> PERMISSIONS
     */
    private applyPermissions(): void {
        let menuAllowed: IMenuItemParent[] = [];
        // process parent items
        menuAllowed = UtilityService.deepClone(MENU_ITEMS).filter(item => {
            return (
                item.permission === -1 ||
                (item.permissionActions || [PERMISSIONS_ACTIONS.view]).every(action => {
                    if (typeof item.permission === "object") {
                        return this.permissionsService.checkCustomPermission(
                            item.permission as IPermissionCustomDetails
                        );
                    }
                    return this.permissionsService.has(item.permission as number, action);
                })
            );
        });
        // process children
        menuAllowed.forEach(parent => {
            if (parent.children.length) {
                parent.children = parent.children.filter(item => {
                    return (
                        item.permission === -1 ||
                        (item.permissionActions || [PERMISSIONS_ACTIONS.view]).every(action => {
                            if (typeof item.permission === "object") {
                                return this.permissionsService.checkCustomPermission(
                                    item.permission as IPermissionCustomDetails
                                );
                            }
                            return this.permissionsService.has(item.permission as number, action);
                        })
                    );
                });
            }
        });
        // temporary menu
        this.menu = [...menuAllowed];
        this.updateMyAccountOverviewMenuItem();
    }

    private updateMyAccountOverviewMenuItem() {
        // Make My Account Overview item clickable/not if user has permission
        // @TODO: Add generic functionality for handling such cases - check edge portal and implement
        // similar functionality to the one that handles  click: 'checkMyAccount', in route item
        const hasPermission = this.permissionsService.has(PERMISSIONS.MyAccount);
        const currentItem = this.menu.find(item => item.name === "My Account");
        const itemIndex = this.menu.indexOf(currentItem);
        this.menu[itemIndex].route = hasPermission ? "/my-account" : null;
    }

    /**
     * HELPER: deactivate active links
     */
    private deactivateMenuItems(): void {
        this.menu.forEach(item => {
            item.isActive = false;
            item.children.forEach(child => {
                child.isActive = false;
            });
        });
    }

    /**
     * HELPER: find menu item by route name
     */
    private activateMenuItem(route: string): void {
        this.menu.forEach(item => {
            if (item.route === route || route.startsWith(item.route)) {
                // mark parent as active
                item.isActive = true;
            }
            item.children.forEach(
                // eslint-disable-next-line no-return-assign
                child => {
                    if (child.route === route || route.startsWith(child.route)) {
                        // mark parent as active
                        item.isActive = true;
                        // mark child as active
                        child.isActive = true;
                    }
                }
            );
        });
    }
}
