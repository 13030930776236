<button mat-button [loading]="isLoading" load-small [matMenuTriggerFor]="menu" class="functional">
    <ng-content></ng-content>
    <mat-icon svgIcon="dui:expand" class="menu-expand"></mat-icon>
</button>
<mat-menu #menu="matMenu" class="dui-menu-list" xPosition="before">
    <button mat-menu-item disabled class="menu-title">
        <span>{{innerTitle}}</span>
    </button>
    <button *ngFor="let option of options" (click)="onClickButton(option.value)" mat-menu-item>
        <mat-icon [svgIcon]="option.iconName"></mat-icon>
        <span class="item-label">{{option.label}}</span>
    </button>
</mat-menu>
