import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DuiCoreModule } from "../../core";
import { FileInfoComponent } from "./file-info/file-info.component";
import { SelectFileComponent } from "./select-file/select-file.component";
import { UploadFilesComponent } from "./upload-files.component";

@NgModule({
    declarations: [UploadFilesComponent, SelectFileComponent, FileInfoComponent],
    imports: [
        DuiCoreModule,
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCardModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatTooltipModule,
    ],
    exports: [UploadFilesComponent],
})
export class DuiUploadFilesModule {}
