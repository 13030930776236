<div class="dui-timeline">
    <div class="dui-timeline-label">
        <div class="dui-date">
            {{ date | duiDate }}
        </div>
        <div class="dui-time" *ngIf="showTime">
            {{ date | duiTime }}
        </div>
    </div>
    <ng-container *ngIf="!simpleView">
        <div class="dui-timeline-separator" [ngClass]="{'small-icon': iconSize === 'small'}">
            <div class="dui-timeline-icon">
                <ng-content select="[icon]"></ng-content>
            </div>
        </div>
        <div class="dui-timeline-content">
            <ng-content select="[body]"></ng-content>
        </div>
    </ng-container>
</div>
