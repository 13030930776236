import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter, map, mergeMap, takeUntil, tap } from "rxjs/operators";
import { Title } from "@angular/platform-browser";

@Injectable({
    providedIn: "root",
})
export class TitleService implements OnDestroy {
    public title$: Observable<string>;

    private titleSbj: BehaviorSubject<string> = new BehaviorSubject("");
    private destroy$ = new Subject<void>();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private title: Title
    ) {
        this.title$ = this.titleSbj.asObservable();

        this.router.events
            .pipe(
                takeUntil(this.destroy$),
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                mergeMap(route => route.data),
                tap(data => {
                    this.titleSbj.next(data.title);
                })
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    setTitle(title: string): void {
        this.title.setTitle(title);
    }

    setDefaultTitle(): void {
        this.title.setTitle("DataBank Partner");
    }
}
