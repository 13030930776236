import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { PERMISSIONS_CUSTOM } from "@core/constants/permission-custom.constant";
import { PERMISSIONS } from "@core/constants/permissions.constant";
import { AuthGuard } from "@core/guards/auth.guard";
import { AvailabilityGuard } from "@core/guards/availability.guard";
import { UnAuthGuard } from "@core/guards/un-auth.guard";
import { EdgeRoutes } from "@core/interfaces/routes.interface";
import { ExpiredLinkComponent } from "@shared/modules/exception-pages/components/expired-link/expired-link.component";
import { InvalidLinkComponent } from "@shared/modules/exception-pages/components/invalid-link/invalid-link.component";
import { LoginLayoutShellComponent } from "./layouts/login-layout/containers/login-layout-shell/login-layout-shell.component";
import { PortalLayoutShellComponent } from "./layouts/portal-layout/containers/portal-layout-shell/portal-layout-shell.component";

const routes: EdgeRoutes = [
    { path: "", pathMatch: "full", redirectTo: "home" },
    {
        // Login layout. For both authorized and non-authorized users
        path: "",
        component: LoginLayoutShellComponent,
        children: [
            {
                path: "forgot-password",
                loadChildren: () =>
                    import("./modules/forgot-password/forgot-password.module").then(
                        ({ ForgotPasswordModule }) => ForgotPasswordModule
                    ),
            },
            {
                path: "auth/setup-account",
                loadChildren: () =>
                    import("./modules/setup-account/setup-account.module").then(
                        ({ SetupAccountModule }) => SetupAccountModule
                    ),
            },
        ],
    },
    {
        // Login layout. For non-authorized users
        path: "auth",
        canLoad: [UnAuthGuard],
        canActivate: [UnAuthGuard],
        component: LoginLayoutShellComponent,
        loadChildren: () =>
            import("./modules/auth/auth.module").then(({ AuthModule }) => AuthModule),
    },
    {
        // Portal layout. For authorized users
        path: "",
        canActivateChild: [AuthGuard],
        component: PortalLayoutShellComponent,
        children: [
            {
                path: "home",
                canLoad: [AuthGuard, AvailabilityGuard],
                data: {
                    availability: {
                        permissionCode: PERMISSIONS.HomePage,
                    },
                    title: "Home",
                },
                loadChildren: () =>
                    import("./modules/home/home.module").then(({ HomeModule }) => HomeModule),
            },
            {
                path: "solutions",
                canLoad: [AuthGuard, AvailabilityGuard],
                data: {
                    availability: {
                        permissionCode: PERMISSIONS.DataBankSolutions,
                    },
                    title: "DataBank Solutions",
                },
                loadChildren: () =>
                    import("./modules/solutions/solutions.module").then(
                        ({ SolutionsModule }) => SolutionsModule
                    ),
            },
            {
                path: "my-account",
                canLoad: [AuthGuard, AvailabilityGuard],
                data: {
                    availability: {
                        permissionCustom: PERMISSIONS_CUSTOM.MyAccount,
                    },
                    title: "My Account",
                },
                loadChildren: () =>
                    import("./modules/my-account/my-account.module").then(
                        ({ MyAccountModule }) => MyAccountModule
                    ),
            },
            {
                path: "my-profile",
                canLoad: [AuthGuard, AvailabilityGuard],
                data: {
                    availability: {
                        permissionCustom: PERMISSIONS_CUSTOM.MyProfile,
                    },
                    title: "My Profile",
                },
                loadChildren: () =>
                    import("./modules/my-profile/my-profile.module").then(
                        ({ MyProfileModule }) => MyProfileModule
                    ),
            },
            {
                path: "support",
                canLoad: [AuthGuard],
                data: {
                    title: "Support",
                },
                loadChildren: () =>
                    import("./modules/support-page/support-page.module").then(
                        ({ SupportPageModule }) => SupportPageModule
                    ),
            },
            {
                path: "access-denied",
                canLoad: [AuthGuard],
                data: {
                    title: "Access Denied",
                },
                loadChildren: () =>
                    import("./modules/access-denied/access-denied.module").then(
                        ({ AccessDeniedModule }) => AccessDeniedModule
                    ),
            },
        ],
    },
    {
        path: "",
        component: LoginLayoutShellComponent,
        canActivateChild: [AuthGuard],
        children: [
            {
                path: "reset-password",
                canLoad: [AuthGuard],
                data: {
                    title: "Reset password",
                    skipGuard: true,
                },
                loadChildren: () =>
                    import("./modules/reset-password/reset-password.module").then(
                        ({ ResetPasswordModule }) => ResetPasswordModule
                    ),
            },
        ],
    },
    {
        path: "expired-link",
        component: ExpiredLinkComponent,
    },
    {
        path: "invalid-link",
        component: InvalidLinkComponent,
    },
    {
        path: "",
        component: PortalLayoutShellComponent,
        children: [
            {
                path: "epic",
                // canLoad: [AuthGuard, AvailabilityGuard],
                data: {
                    title: "Ticket 123456",
                },
                loadChildren: () =>
                    import("./modules/epic/epic.module").then(({ EpicModule }) => EpicModule),
            },
        ],
    },
    { path: "**", redirectTo: "/home" },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
