import { Component, Output, EventEmitter, Input } from "@angular/core";
import { MinimumFileInfo } from "../minimum-file-info.interface";

@Component({
    selector: "dui-file-info",
    templateUrl: "./file-info.component.html",
    styleUrls: ["./file-info.component.scss"],
})
export class FileInfoComponent {
    @Input() file: File | MinimumFileInfo;
    @Output() fileRemoved = new EventEmitter();

    public removeFile(): void {
        this.fileRemoved.emit();
    }
}
