import { ISpinner } from "../../../interfaces/spinner.interface";

export class SpinnerDefaultClass extends ISpinner {
    private spinnerTemplate = `<svg class='dui-spinner' width='70' height='70' viewBox='0 0 70 70' xmlns='http://www.w3.org/2000/svg'>
    <circle class='path' fill='none' stroke-width='6' stroke-linecap='round' cx='35' cy='35' r='30'></circle>
    </svg> <span class='loading-label'>Loading...</span>`;

    private directiveClass = "preloader";
    private container: HTMLElement;
    private spinner: HTMLElement;

    startSpinner(): void {
        this.container = document.createElement("div");
        this.spinner = document.createElement("div");

        this.loadingElement.classList.add(this.directiveClass);
        this.container.classList.add("dui-spinner-container");

        if (this.options?.noLabel) {
            this.container.classList.add("dui-spinner-container--no-label");
        }

        if (this.options?.noBackground) {
            this.container.classList.add("dui-spinner-container--no-background");
        }

        this.spinner.innerHTML = this.spinnerTemplate;

        this.container.appendChild(this.spinner);
        this.loadingElement.appendChild(this.container);
    }

    stopSpinner(): void {
        if (this.container) {
            this.loadingElement.classList.remove(this.directiveClass);
            this.loadingElement.removeChild(this.container);
        }
    }
}
