import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MenuListComponent } from "./menu-list.component";
import { DuiCoreModule } from "../../core";

@NgModule({
    declarations: [MenuListComponent],
    imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule, DuiCoreModule],
    exports: [MenuListComponent],
    providers: [],
})
export class DuiMenuListModule {}
