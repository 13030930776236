<mat-label class="form-control-label" [ngClass]="{
    'field-required': isRequired && control.enabled,
    'field-disabled': control.disabled
}">{{label}}</mat-label>

<quill-editor [formControl]="control"
              [placeholder]="placeholder"
              [modules]="config"
              [sanitize]="false"
              [class.error]="isErrorVisible">
</quill-editor>

<div class="dui-wysiwyg-error-container" [style.visibility]="isErrorVisible ? 'visible' : 'hidden'">
    <mat-error>
        <dui-validation-error [control]="control"></dui-validation-error>
    </mat-error>
</div>
