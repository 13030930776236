import { NgModule } from "@angular/core";
import { UtilityService } from "./services/utility.service";
import { ValidationService } from "./services/validation.service";
import { SeverityDirective } from "./directives/severity.directive";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { IconsRegisterService } from "./services/icons-register.service";
import { ThousandthPipe } from "./pipes/thousandth.pipe";
import { DateTimePipe } from "./pipes/date-time.pipe";
import { TimePipe } from "./pipes/time.pipe";
import { DatePipe } from "./pipes/date.pipe";
import { BytesConverterPipe } from "./pipes/bytes-converter.pipe";
import { LoadingDirective } from "./directives/loading/loading.directive";

@NgModule({
    declarations: [
        LoadingDirective,
        SeverityDirective,
        SafeHtmlPipe,
        ThousandthPipe,
        DateTimePipe,
        TimePipe,
        DatePipe,
        BytesConverterPipe,
    ],
    imports: [],
    providers: [ValidationService, UtilityService, IconsRegisterService],
    exports: [
        LoadingDirective,
        SeverityDirective,
        SafeHtmlPipe,
        ThousandthPipe,
        DateTimePipe,
        TimePipe,
        DatePipe,
        BytesConverterPipe,
    ],
})
export class DuiCoreModule {
    constructor(iconsService: IconsRegisterService) {
        iconsService.registerIcons();
    }
}
