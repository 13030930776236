import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { LoggerService } from "@core/services/logger.service";
import { IUserDto } from "@core/interfaces/dto/user-profile-dto.interface";

export interface IUser extends IUserDto {}

@Injectable({
    providedIn: "root",
})
export class UserStorageService {
    user$: Observable<IUser>;
    private userData$: BehaviorSubject<IUser> = new BehaviorSubject(null);

    constructor(private loggerService: LoggerService) {
        this.user$ = this.userData$.asObservable();
    }

    /**
     * User data
     */
    setUserData(userData: IUser): void {
        this.userData$.next({ ...userData });
        this.loggerService.log("[UserStorageService] Update storage", userData);
    }

    getUser(): IUser {
        return this.userData$.getValue();
    }

    selectUser(): Observable<IUser> {
        return this.user$;
    }

    clearUserData(): void {
        this.userData$.next(null);
    }

    get isDatabankAdmin(): boolean {
        return this.getUser()?.isDatabankAdmin;
    }

    get isMasterAgent(): boolean {
        return this.getUser()?.isMasterAgent;
    }
}
