import { InjectionToken, Provider } from "@angular/core";
import { IEnvironmentFeatureFlags } from "@core/interfaces/environment.interface";
import { environment } from "src/environments/environment";

export const FEATURE_FLAGS = new InjectionToken<IEnvironmentFeatureFlags>("");

export const FEATURE_FLAGS_PROVIDER: Provider = {
    provide: FEATURE_FLAGS,
    useValue: environment.featureFlags,
};
