import { SpinnerSmallClass } from "./spinner-small";
import { ISpinner, SpinnerOptions, SpinnerType } from "../../../interfaces/spinner.interface";
import { SpinnerDefaultClass } from "./spinner-default";
import { SpinnerToggleClass } from "./spinner-toggle";

export class SpinnerFactory {
    static createSpinner(
        element: HTMLElement,
        options: SpinnerOptions,
        type?: SpinnerType
    ): ISpinner {
        let Spinner;

        switch (type) {
            case SpinnerType.Small:
                Spinner = SpinnerSmallClass;
                break;
            case SpinnerType.Toggle:
                Spinner = SpinnerToggleClass;
                break;
            default:
                Spinner = SpinnerDefaultClass;
        }

        return new Spinner(element, options);
    }
}
