import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Routing } from "@core/helpers/routing";
import { SUB_TOP_BAR_PROVIDER } from "@shared/providers/sub-top-bar.provider";
import { LoggerService } from "@core/services/logger.service";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { PartnersService } from "@shared/services/partners.service";
import { UserStorageService } from "@core/services/storages/user-storage.service";
import { Subject } from "rxjs";

@Component({
    selector: "dbp-portal-layout",
    templateUrl: "./portal-layout-shell.component.html",
    styleUrls: ["./portal-layout-shell.component.scss"],
    providers: [SUB_TOP_BAR_PROVIDER],
})
export class PortalLayoutShellComponent implements OnDestroy {
    public sideBarMenuStatus$ = this.settingsService.isSidebarCollapsed();
    public flags = {
        isTimeframe: false,
        isPartners: false,
    };

    private destroy$ = new Subject<void>();

    constructor(
        public readonly partners: PartnersService,
        private route: ActivatedRoute,
        private router: Router,
        private loggerService: LoggerService,
        private settingsService: SettingStorageService,
        private user: UserStorageService
    ) {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.flags.isTimeframe = Routing.hasRouteDataValue("timeframe", this.route.snapshot)
                    ? !!Routing.getRouteDataValue("timeframe", this.route.snapshot)
                    : false;
                this.flags.isPartners = Routing.hasRouteDataValue("partners", this.route.snapshot)
                    ? !!Routing.getRouteDataValue("partners", this.route.snapshot)
                    : false;
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get isPartnersAllowed(): boolean {
        return this.user.isDatabankAdmin && this.flags.isPartners;
    }

    get isTimeframeAllowed(): boolean {
        return this.user.isDatabankAdmin && this.flags.isTimeframe;
    }
}
