import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { QuillModule } from "ngx-quill";
import { MatFormFieldModule } from "@angular/material/form-field";
import { WYSIWYGComponent } from "./wysiwyg.component";
import { DuiValidationErrorModule } from "../validation-error";

@NgModule({
    declarations: [WYSIWYGComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DuiValidationErrorModule,
        MatInputModule,
        MatFormFieldModule,
        QuillModule.forRoot(),
    ],
    exports: [WYSIWYGComponent],
})
export class DuiWysiwygModule {}
