/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const META_DATA = {
    confirmation: {
        panelClass: ["dui-dialog"],
        data: {
            title: "Confirmation",
            body: "Are you sure?",
            okButton: "Confirm",
            cancelButton: "Cancel",
            onReject: () => {},
            onConfirm: () => {},
            onClose: () => {},
            onCancel: () => {},
        },
    },
    defaultModalWidth: "80vw",
};
