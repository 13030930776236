import { InjectionToken, Provider } from "@angular/core";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";

export const BACK_BUTTON = new InjectionToken<BackButtonData>("Back button information");

export interface BackButtonData {
    text: string;
    route: string;
}

export const BACK_BUTTON_PROVIDER: Provider = {
    provide: BACK_BUTTON,
    deps: [AuthStorageService],
    useFactory: (authService: AuthStorageService): BackButtonData => {
        if (authService.isAuthorized()) {
            return {
                text: "Back to Home Page",
                route: "/home",
            };
        }

        return {
            text: "Back to Login Page",
            route: "/auth/login",
        };
    },
};
