<mat-form-field [class.highlighted]="highlighted">
    <mat-label class="form-control-label"
               [class.field-required]="isRequired && control.enabled">
        {{label}}
    </mat-label>

    <ngx-mat-intl-tel-input
        [preferredCountries]="['us']"
        [enablePlaceholder]="true"
        [enableSearch]="true"
        [formControl]="control"
        name="phone"
        class="dui-phone-input"
        inputPlaceholder="Enter phone number"
    ></ngx-mat-intl-tel-input>

    <mat-error *ngIf="control.invalid && control.touched">
        <dui-validation-error [control]="control"></dui-validation-error>
    </mat-error>
</mat-form-field>
