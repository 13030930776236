<div class="paginator-container">
    <button mat-icon-button [disabled]="paginator.pageIndex === 0" (click)="paginator.firstPage()">
        <mat-icon svgIcon="dui:navigate_first"></mat-icon>
    </button>
    <button mat-icon-button [disabled]="paginator.pageIndex === 0" (click)="paginator.previousPage()">
        <mat-icon svgIcon="dui:navigate_previous"></mat-icon>
    </button>

    <button mat-stroked-button class="clean" *ngIf="paginator.pageIndex !== 0"
    (click)="paginator.previousPage()">{{paginator.pageIndex}}
    </button>

    <button mat-flat-button>
        {{paginator.pageIndex + 1}}
    </button>

    <button mat-stroked-button class="clean" *ngIf="paginator.pageIndex !== paginator.getNumberOfPages() - 1"
    (click)="paginator.nextPage()">{{paginator.pageIndex + 2}}
    </button>

    <button mat-icon-button [disabled]="paginator.pageIndex === paginator.getNumberOfPages() - 1" (click)="paginator.nextPage()">
        <mat-icon svgIcon="dui:navigate_next"></mat-icon>
    </button>
    <button mat-icon-button [disabled]="paginator.pageIndex === paginator.getNumberOfPages() - 1" (click)="paginator.lastPage()">
        <mat-icon svgIcon="dui:navigate_last"></mat-icon>
    </button>
</div>
<mat-paginator style="display: none" #paginator [length]="totalItems" [pageSize]="itemsPerPage" [pageSizeOptions]="[]" [pageIndex]="page">
</mat-paginator>
