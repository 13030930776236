import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { commonUrls } from "@core/constants/url.constant";
import { BackButtonData, BACK_BUTTON, BACK_BUTTON_PROVIDER } from "../../back-button.provider";

@Component({
    selector: "dbp-invalid-link",
    templateUrl: "./invalid-link.component.html",
    styleUrls: ["../../exception-pages.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [BACK_BUTTON_PROVIDER],
})
export class InvalidLinkComponent {
    public readonly commonUrls = commonUrls;

    constructor(@Inject(BACK_BUTTON) public backButton: BackButtonData) {}
}
