import { Component, Input, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ValidationService } from "../../core/services/validation.service";

@Component({
    selector: "dui-validation-error",
    templateUrl: "./validation-error.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class ValidationErrorComponent {
    @Input() control: UntypedFormControl;
    @Input() customMessages: { [error: string]: string };

    public get errorMessages(): string | null {
        if (!this.control) {
            return null;
        }

        for (const propertyName in this.control.errors) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.control.errors.hasOwnProperty(propertyName)) {
                if (this.control.errors?.customError) {
                    return this.control.errors.customError;
                }

                if (this.customMessages && this.customMessages[propertyName]) {
                    return this.customMessages[propertyName];
                }
                return ValidationService.getValidatorErrorMessage(
                    propertyName,
                    this.control.errors[propertyName]
                );
            }
        }

        return null;
    }
}
