import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { MatSort, Sort } from "@angular/material/sort";
import { filter, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { PageEvent } from "@angular/material/paginator";
import extend from "just-extend";
import { DEFAULT_PAGING, DEFAULT_SORTING, EXPAND_TABLE_CONFIG } from "./table-expandable.metadata";
import { TableCellTemplateComponent } from "./table-cell-template.component";
import { SeverityList } from "../../core";
import { PaginatorComponent } from "../paginator";
import {
    AlignOptions,
    AllTableOptions,
    ExpandableData,
    TableColumnConfig,
    TableOptions,
    TablePaging,
    TableSorting,
} from "./table-expandable.interface";

interface AdditionalProperties {
    [key: string]: any;
    severity?: number;
    severityLabel?: string;
    expandedByDefault?: boolean;
}

interface ExtendedColumnConfig extends TableColumnConfig {
    cellCssClasses?: { [cssClass: string]: boolean };
    headerCssClasses?: { [cssClass: string]: boolean };
}

@Component({
    selector: "dui-table-expandable",
    templateUrl: "./table-expandable.component.html",
    styleUrls: ["./table-expandable.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed, void", style({ height: "0px" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
            transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableExpandableComponent<T extends AdditionalProperties>
    implements AfterViewInit, OnChanges, OnDestroy
{
    @Input() dataset: T[] | null = null;
    @Input() displayedColumns: TableColumnConfig[] = [];
    // All params are optional. You may pass only needed options to this Input to set only few options.
    // Pass null to reset all options to default.
    @Input() set options(value: TableOptions | null) {
        if (value === null) this.allOptions = this.getDefaultOptions();
        else this.allOptions = extend(true, {}, this.allOptions, value) as AllTableOptions;
    }

    @Input() withCollapse = true;
    @Input() expandedDetailsTemplate?: TemplateRef<any>;
    @Input() emptyMessage?: string;
    @Input() canExpandCheck?: (data: T) => boolean;
    @Input() customSorting?: (data: ExpandableData<T>[], sort: MatSort) => ExpandableData<T>[];
    @Input() customSeverityList?: SeverityList;
    @Input() isError = false;
    @Input() tableIsServerSide = false;

    @Output() pageChange = new EventEmitter<number>();
    @Output() sortChange = new EventEmitter<TableSorting>();
    @Output() expandChange = new EventEmitter<ExpandableData<T>>();
    @Output() rowClick = new EventEmitter<T>();

    @ViewChild(PaginatorComponent) public paginatorComponent: PaginatorComponent;
    @ViewChild(MatSort) public sortComponent: MatSort;

    @ContentChildren(TableCellTemplateComponent)
    cellTemplates: QueryList<TableCellTemplateComponent>;

    public allExpanded = false;
    public dataSource = new MatTableDataSource<ExpandableData<T>>([]);
    public columns: ExtendedColumnConfig[];
    public columnNames: string[];
    public tableCells = {};

    // This object always contains all table options
    private allOptions: AllTableOptions = this.getDefaultOptions();

    private ignoreNextSortChange = false;
    private ignoreNextPagingChange = false;
    private destroy$ = new Subject<void>();

    get isPaginatorHidden(): boolean {
        return (
            this.paginatorComponent?.paginator.length <= this.paginatorComponent?.paginator.pageSize
        );
    }

    constructor() {
        this.setFullListOfColumns();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && !changes.options.isFirstChange()) {
            this.applyOptions();
        }
        if (changes.displayedColumns?.currentValue) {
            this.setFullListOfColumns();
        }
        if (changes.dataset?.currentValue) {
            this.dataSource.data = this.getExpandableData(this.dataset);
        }
    }

    ngAfterViewInit(): void {
        // Set MatSort directive and MatPaginator component in MatTableDataSource
        // so it can handle all sorting and pagination automatically
        // (only for client-side tables)
        if (!this.tableIsServerSide) {
            this.dataSource.sort = this.sortComponent;

            if (this.customSorting) {
                this.dataSource.sortData = this.customSorting;
            }

            this.dataSource.sortingDataAccessor = (item, property) => {
                if (typeof item.element[property] === "string") {
                    return item.element[property].toLocaleLowerCase();
                }

                return item.element[property];
            };

            this.dataSource.paginator = this.paginatorComponent.paginator;
        }

        this.cellTemplates.forEach(template => {
            this.tableCells[template.columnName] = template.template;
        });

        this.sortComponent.sortChange
            .pipe(
                filter(() => {
                    if (this.ignoreNextSortChange) {
                        this.ignoreNextSortChange = false;
                        return false;
                    }
                    return true;
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((sort: Sort) => {
                // Set default value on third click sorted column
                if (!sort.direction) {
                    sort.active = DEFAULT_SORTING.property;
                }
                // Need to update allOptions object so next 'applyOptions'
                // method call will apply correct options
                this.allOptions.sorting.property = sort.active;
                this.allOptions.sorting.direction = sort.direction;

                this.sortChange.emit({
                    property: sort.active,
                    direction: sort.direction,
                });
            });

        this.paginatorComponent.paginator.page
            .pipe(
                filter(() => {
                    if (this.ignoreNextPagingChange) {
                        this.ignoreNextPagingChange = false;
                        return false;
                    }
                    return true;
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((event: PageEvent) => {
                // Need to update allOptions object so next 'applyOptions'
                // method call will apply correct options
                this.allOptions.paging.page = event.pageIndex + 1;

                this.pageChange.emit(this.allOptions.paging.page);
            });

        // Call this method when component is rendered and we can
        // access MatSort and MatPaginator to set all options
        this.applyOptions();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onRowClick(item: ExpandableData<T>): void {
        this.rowClick.emit(item.element);
    }

    public expandAllClick(): void {
        if (this.dataSource?.data?.length) {
            this.allExpanded = !this.allExpanded;
            this.dataSource.data.forEach(item => {
                item.expanded = this.allExpanded;
                this.expandChange.emit(item);
            });
        }
    }

    public canExpand(item: T): boolean {
        return this.canExpandCheck && item ? this.canExpandCheck(item) : true;
    }

    public elementExpandClick(item: ExpandableData<T>): void {
        item.expanded = !item.expanded;
        this.expandChange.emit(item);
        if (item.expanded) {
            this.allExpanded = true;
        } else {
            let isAllCollapsed = true;
            this.dataSource.data.forEach(dataItem => {
                if (dataItem.expanded) isAllCollapsed = false;
            });
            if (isAllCollapsed) this.allExpanded = false;
        }
    }

    // Additional CSS classes for cells alignment based on TableColumnConfig
    private getExtendedColumnsConfig(
        displayedColumns: TableColumnConfig[]
    ): ExtendedColumnConfig[] {
        return displayedColumns.map(column => ({
            ...column,
            cellCssClasses: {
                "cell-align-left": column.horizontalAlign === AlignOptions.Start,
                "cell-align-center": column.horizontalAlign === AlignOptions.Center,
                "cell-align-right": column.horizontalAlign === AlignOptions.End,
                "cell-align-top": column.verticalAlign === AlignOptions.Start,
                "cell-align-middle": column.verticalAlign === AlignOptions.Center,
                "cell-align-bottom": column.verticalAlign === AlignOptions.End,
            },
            headerCssClasses: {
                [`mat-header-${column.value}`]: true,
                "header-align-left": column.horizontalAlign === AlignOptions.Start,
                "header-align-center": column.horizontalAlign === AlignOptions.Center,
                "header-align-right": column.horizontalAlign === AlignOptions.End,
            },
        }));
    }

    private setFullListOfColumns(): void {
        const allTableColumns: ExtendedColumnConfig[] = [];

        // 'Collapse' column
        if (this.withCollapse) allTableColumns.push(EXPAND_TABLE_CONFIG.collapseColumn);

        // The rest columns
        const extendedColumnsConfig = this.getExtendedColumnsConfig(this.displayedColumns);
        allTableColumns.push(...extendedColumnsConfig);

        this.columns = allTableColumns;
        this.columnNames = this.columns.map(col => col.value);
    }

    private getExpandableData(data: T[] | null): ExpandableData<T>[] {
        const expandableData =
            data?.map(elem => ({
                element: elem,
                expanded: elem.expandedByDefault || false,
            })) || [];

        this.allExpanded = expandableData.some(item => item.expanded);

        return expandableData;
    }

    // Call this method to apply incoming options(sorting and pagination)
    private applyOptions(): void {
        this.applySorting(this.allOptions.sorting);
        this.applyPagination(this.allOptions.paging);
    }

    private applySorting(options: TableSorting): void {
        if (this.sortComponent) {
            this.sortComponent.active = options.property;
            this.sortComponent.direction = options.direction;

            // Need to emit this event so MatTableDataSource can apply changes and rerender data.
            // Since we don't want to emit anything from TableExpandable, we are using 'ignoreNextSortChange'
            // flag to skip just this one event(subscription to this event inside ngAfterViewInit)
            this.ignoreNextSortChange = true;
            this.sortComponent.sortChange.emit({
                active: options.property,
                direction: options.direction,
            });

            // Ugly hack to force mat-sort-header to rerender sorting arrow in correct state
            if (options.direction) {
                const activeSortHeader = this.sortComponent.sortables.get(options.property);
                if (activeSortHeader)
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    activeSortHeader["_setAnimationTransitionState"]({
                        fromState: options.direction,
                        toState: "active",
                    });
            }
        }
    }

    private applyPagination(options: TablePaging): void {
        if (this.paginatorComponent) {
            this.paginatorComponent.paginator.pageIndex = options.page - 1;
            this.paginatorComponent.paginator.length = options.totalItems;
            this.paginatorComponent.paginator.pageSize = options.itemsPerPage;

            // Need to emit this event so MatTableDataSource can apply changes and rerender data.
            // Using 'ignoreNextPagingChange' flag to skip just this one event
            // (subscription to this event inside ngAfterViewInit)
            this.ignoreNextPagingChange = true;
            this.paginatorComponent.paginator.page.emit({
                pageIndex: options.page - 1,
                length: options.totalItems,
                pageSize: options.itemsPerPage,
            });
        }
    }

    private getDefaultOptions(): AllTableOptions {
        return {
            paging: DEFAULT_PAGING,
            sorting: DEFAULT_SORTING,
        };
    }
}
