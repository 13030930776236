<mat-form-field [class.highlighted]="highlighted">
    <mat-label>{{label}}</mat-label>
    <mat-select
        #edgeSelect
        id="edge-select"
        [placeholder]="placeholder"
        [disabled]="isDisabled"
        [formControl]="control"
        [required]="isRequired"
        [compareWith]="compareWith"
        (selectionChange)="onSelectionChange($event)"
    >

        <div class="edge-mat-select-search" *ngIf="isSearchVisible">
            <input #searchSelectInput type="text" placeholder="Search" [formControl]="searchControl"
                (keydown)="handleKeydown($event)">

            <button mat-icon-button ngxMatSelectSearchClear class="clean" *ngIf="searchControl.value?.length"
                (click)="searchControl.setValue('')">
                <mat-icon svgIcon="clear"></mat-icon>
            </button>
        </div>

        <mat-option disabled *ngIf="!viewOptions?.length">{{noDataText}}</mat-option>

        <div class="edge-mat-select-items" [class.edge-select-group]="selectData?.isGrouped" #selectContent>
            <mat-option *ngIf="selectData?.invisibleSelectedOption" [value]="control.value" style="display: none">
                <ng-container *ngIf="optionTemplateRef" [ngTemplateOutlet]="optionTemplateRef"
                    [ngTemplateOutletContext]="{$implicit:selectData.invisibleSelectedOption}">
                </ng-container>
                <ng-container *ngIf="!optionTemplateRef">{{selectData.invisibleSelectedOption.label}}</ng-container>
            </mat-option>

            <mat-option *ngFor="let option of viewOptions" [value]="option.value" [disabled]="option.isSelfGroup"
                [class.group-label]="option.isSelfGroup">

                <!-- container for label template -->
                <ng-container *ngIf="optionTemplateRef" [ngTemplateOutlet]="optionTemplateRef"
                    [ngTemplateOutletContext]="{$implicit:option}">
                </ng-container>

                <!-- container for default label -->
                <ng-container *ngIf="!optionTemplateRef">{{option.label}}</ng-container>
            </mat-option>
        </div>

    </mat-select>
    <mat-error>
        <dui-validation-error [control]="control"></dui-validation-error>
    </mat-error>
</mat-form-field>
