import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StatusBadgeComponent } from "./status-badge.component";
import { DuiBadgeModule } from "../badge/badge.module";

@NgModule({
    declarations: [StatusBadgeComponent],
    imports: [CommonModule, DuiBadgeModule],
    providers: [],
    exports: [StatusBadgeComponent],
})
export class DuiStatusBadgeModule {}
