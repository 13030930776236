export abstract class ISpinner {
    constructor(public loadingElement: HTMLElement, public options?: SpinnerOptions) {}
    abstract startSpinner(): void;
    abstract stopSpinner(): void;
}

export interface SpinnerOptions {
    noLabel?: boolean; // for default spinner
    noBackground?: boolean; // for default spinner
}

export enum SpinnerType {
    Small,
    Toggle,
}
