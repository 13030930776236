<div class="portal edge-portal">
    <div
        class="edge-portal-aside"
        [ngClass]="(sideBarMenuStatus$ | async) ? 'collapsed' : 'expanded'">
        <dbp-sidebar></dbp-sidebar>
    </div>

    <div class="edge-portal-content">
        <div class="edge-container">
            <div class="top-bar">
                <div class="top-bar-heading">
                    <dbp-top-bar-title></dbp-top-bar-title>
                </div>
                <div class="top-bar-items">
                    <dbp-top-bar-timeframe *ngIf="isTimeframeAllowed"></dbp-top-bar-timeframe>
                    <dbp-top-bar-partner
                        *ngIf="isPartnersAllowed"
                        [partnerList]="partners.list$ | async"></dbp-top-bar-partner>
                    <dbp-top-bar-user-info class="top-bar-group"></dbp-top-bar-user-info>
                    <dbp-top-bar-logout class="top-bar-group"></dbp-top-bar-logout>
                </div>
            </div>

            <div class="sub-top-bar" id="subTopBar"></div>

            <dbp-progress-bar class="progress-bar"></dbp-progress-bar>

            <div class="edge-portal-content-container">
                <div class="edge-container">
                    <div class="edge-page">
                        <router-outlet></router-outlet>
                    </div>
                    <dbp-portal-footer></dbp-portal-footer>
                </div>
            </div>
        </div>
    </div>
</div>
