import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "dui-select-file",
    templateUrl: "./select-file.component.html",
    styleUrls: ["./select-file.component.scss"],
})
export class SelectFileComponent {
    @Input() isMultipleFileUpload: boolean;
    @Input()
    // value = ["pdf", "xlsx", ..., etc.]
    set fileExtensions(value: string[]) {
        if (value.length === 0) {
            this.extensions = null;
        } else {
            this.extensions = value.map(ext => `.${ext}`).join(",");
        }
    }

    @Input() disabled: boolean;
    @Input() hasSelectedFile: boolean;
    @Input() placeholder: string;
    @Input() isFilesUploaded: boolean;
    @Output() filesAdded = new EventEmitter<File[]>();

    // Allowed extensions for the 'accept' attribute of <input type="file">
    public extensions: string | null = null;

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public uploadFiles(target: any): void {
        const selectedFiles: File[] = Array.from(target.files);
        if (selectedFiles.length) {
            // If multiple upload disallowed remove all files except the first one
            if (!this.isMultipleFileUpload) selectedFiles.splice(1);

            this.filesAdded.emit(selectedFiles);
            target.value = null;
        }
    }
}
