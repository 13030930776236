import { ViewportScroller } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, Scroll } from "@angular/router";
import { AuthGuard } from "@core/guards/auth.guard";
import { UnAuthGuard } from "@core/guards/un-auth.guard";
import { UnsavedChangesGuard } from "@core/guards/unsaved-changes.guard";
import { AuthTokenInterceptor } from "@core/interceptors/auth-token.interceptor";
import { HttpErrorInterceptor } from "@core/interceptors/http-error.interceptor";
import { GlobalErrorHandlerService } from "@core/services/global-error-handler.service";
import { ExceptionPagesModule } from "@shared/modules/exception-pages/exception-pages.module";
import { COMMON_LOOKUPS_PROVIDERS } from "@shared/providers/common-lookups.providers";
import { FEATURE_FLAGS_PROVIDER } from "@shared/providers/feature-flags.provider";
import { GET_ATTACHMENT_PROVIDER } from "@shared/providers/get-attachment.provider";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { filter } from "rxjs/operators";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginLayoutModule } from "./layouts/login-layout/login-layout.module";
import { PortalLayoutModule } from "./layouts/portal-layout/portal-layout.module";

import "src/vendors/dayjs.lib";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ExceptionPagesModule,
        PortalLayoutModule,
        LoginLayoutModule,
        NgIdleKeepaliveModule.forRoot(),
    ],
    providers: [
        Title,
        AuthGuard,
        UnAuthGuard,
        UnsavedChangesGuard,
        { provide: Window, useValue: window },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true,
        },
        FEATURE_FLAGS_PROVIDER,
        ...COMMON_LOOKUPS_PROVIDERS,
        GET_ATTACHMENT_PROVIDER,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(router: Router, viewportScroller: ViewportScroller) {
        viewportScroller.setOffset([0, 60]);
        router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: Scroll) => {
            if (e.anchor) {
                // anchor navigation
                setTimeout(() => {
                    viewportScroller.scrollToAnchor(e.anchor);
                });
            } else if (e.position) {
                // backward navigation
                viewportScroller.scrollToPosition(e.position);
            } else {
                // forward navigation
                viewportScroller.scrollToPosition([0, 0]);
            }
        });
    }
}
