/**
 * Function filtering objects of arrays by values of object keys
 * Example
 * data = [
 *      {a: "value_aa", b: "value_ba", c: "value_ca"},
 *      {a: "value_ab", b: "value_bb", c: "value_cb"}
 *      {a: "value_ac", b: "value_bc", c: "value_cc"}
 * ]
 * filterBy = {a: "_aa", c: "_cc"}
 * return [
 *      {a: "value_aa", b: "value_ba", c: "value_ca"},
 *      {a: "value_ac", b: "value_bc", c: "value_cc"}
 * ]
 * TODO: add exceptions, compare non-string type
 */
export function filterByValues<T>(data: T[], filterBy: { [key: string]: string }): T[] {
    const filtersToApply = Object.entries(filterBy);

    return data.filter(item => {
        return filtersToApply.find(filterItem => {
            const filterKey = filterItem[0];
            const filterValue = filterItem[1];

            if (Object.prototype.hasOwnProperty.call(item, filterKey)) {
                if (!filterValue) {
                    return true;
                }
                const dataValue = item[filterKey].toLowerCase();
                return dataValue.includes(filterValue.toLowerCase());
            }
            return false;
        });
    });
}
