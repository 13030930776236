import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { LoggerService } from "@core/services/logger.service";

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    constructor(
        private authStorageService: AuthStorageService,
        private loggerService: LoggerService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.has("skip-token") || request.url.startsWith("assets/icons")) {
            return next.handle(request);
        }

        const token = this.authStorageService.getAuth()?.accessToken;

        this.loggerService.log("[AuthTokenInterceptor] Add Token", request, token);

        const newReq = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });

        return next.handle(token ? newReq : request);
    }
}
