import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    Router,
    CanActivateChild,
    CanLoad,
    Route,
} from "@angular/router";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { UserApiService } from "@core/services/user-api.service";
import { PERMISSIONS_ACTIONS } from "@core/constants/permissions.constant";
import { IPermissionCustomDetails } from "../interfaces/permission.interface";
import { PermissionsStorageService } from "../services/storages/permissions-storage.service";

enum Routes {
    Home = "home",
    AccessDenied = "access-denied",
}

@Injectable({
    providedIn: "root",
})
export class AvailabilityGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private router: Router,
        private permissionStorage: PermissionsStorageService,
        private userApiService: UserApiService
    ) {}

    canLoad(route: Route): Observable<boolean> | boolean {
        if (!route.data.availability) return true;

        const { permissionCode, permissionCustom } = route.data.availability;
        const permissionAction = route.data.permissionAction || PERMISSIONS_ACTIONS.view;
        const permission = permissionCustom || permissionCode;

        if (this.permissionStorage.permissions.length) {
            return this.checkAvailability(permission, permissionAction, route.path);
        }
        return this.userApiService.hasUserProfile().pipe(
            take(1),
            map(() => this.checkAvailability(permission, permissionAction, route.path))
        );
    }

    canActivateChild(routeSnapshot: ActivatedRouteSnapshot): boolean {
        const { permissionCodes } = routeSnapshot.data;
        const permissionAction = routeSnapshot.data.permissionAction || PERMISSIONS_ACTIONS.view;
        return this.checkAvailability(
            permissionCodes,
            permissionAction,
            routeSnapshot.routeConfig.path
        );
    }

    canActivate(routeSnapshot: ActivatedRouteSnapshot): boolean {
        const { permissionCodes } = routeSnapshot.data;
        const permissionAction = routeSnapshot.data.permissionAction || PERMISSIONS_ACTIONS.view;
        return this.checkAvailability(
            permissionCodes,
            permissionAction,
            routeSnapshot.routeConfig.path
        );
    }

    private checkAvailability(
        permission: number | IPermissionCustomDetails,
        permissionAction: number,
        route: string
    ): boolean {
        if (!permission) {
            return true;
        }

        if (typeof permission === "object") {
            return (
                this.permissionStorage.checkCustomPermission(permission) || this.accessDenied(route)
            );
        }
        return (
            this.permissionStorage.checkPermission(permission, permissionAction) ||
            this.accessDenied(route)
        );
    }

    private accessDenied(path: string): boolean {
        if (path === Routes.Home) {
            this.router.navigate(["/", Routes.AccessDenied]);
        } else {
            this.router.navigate(["/", Routes.Home]);
        }

        return false;
    }
}
