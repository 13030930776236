import { SortDirection } from "@angular/material/sort";

export interface ITableCellInfo {
    elementProp: string;
    element: any;
}

export interface ExpandableData<T> {
    element: T;
    expanded: boolean;
}

export interface TableOptions {
    sorting?: Partial<TableSorting>;
    paging?: Partial<TablePaging>;
}

export interface AllTableOptions {
    sorting: TableSorting;
    paging: TablePaging;
}

export interface TablePaging {
    page: number; // 1 is the first page
    itemsPerPage: number;
    totalItems: number;
}

export interface TableSorting {
    property: string;
    direction: SortDirection;
}

export interface TableColumnConfig<T = string> {
    value: T;
    sortValue?: T;
    title: string;
    horizontalAlign?: AlignOptions;
    verticalAlign?: AlignOptions;
}

export enum AlignOptions {
    Start = "start",
    Center = "center",
    End = "end",
}
