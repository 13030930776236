const TYPES = {
    CUSTOM: (value, label) => `${value} of ${label}`,
    PERCENTAGE: value => `${value}%`,
    PART: (value, limit) => `${value} / ${limit}`,
    TIMER: (time: number = 0) => {
        const min = parseInt(String(time / 60), 10);
        const sec = parseInt(String(time % 60), 10);
        return `${min > 9 ? min : `0${min}`} : ${sec > 9 ? sec : `0${sec}`}`;
    },
    TOTAL: value => `${value}`,
};

export const DEFAULT_OPTIONS = {
    limit: 100,
    value: 50,
    type: "PERCENTAGE",
    staticColor: "",
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    label: type => TYPES[type] || TYPES[DEFAULT_OPTIONS.type],
    title: "",
};
