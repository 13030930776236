import { Component, Input, TemplateRef } from "@angular/core";

@Component({
    selector: "dui-table-cell-template",
    template: `<ng-container [ngTemplateOutlet]="template"></ng-container>`,
})
export class TableCellTemplateComponent {
    @Input() columnName: string;
    @Input() template: TemplateRef<any>;
}
