import {
    Component,
    Output,
    EventEmitter,
    Input,
    OnChanges,
    SimpleChanges,
    OnInit,
    OnDestroy,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

export const DEFAULT_SEARCH_LENGTH = 100;

@Component({
    selector: "dui-widget-search",
    templateUrl: "./widget-search.component.html",
    styleUrls: ["./widget-search.component.scss"],
})
export class WidgetSearchComponent implements OnInit, OnChanges, OnDestroy {
    @Input() maxLength = DEFAULT_SEARCH_LENGTH;
    @Input() cachedSearchValue: string;
    @Input() isLive: boolean;
    @Input() searchValue: string;
    @Output() searchValueChange: EventEmitter<string> = new EventEmitter<string>();
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public control = new FormControl();
    private destroy$ = new Subject<void>();

    get isNoLimit(): boolean {
        return this.maxLength < 0;
    }

    ngOnInit(): void {
        if (this.isLive) {
            this.control.valueChanges
                .pipe(takeUntil(this.destroy$), debounceTime(300), distinctUntilChanged())
                .subscribe(value => {
                    this.searchValueChange.emit(value);
                });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.cachedSearchValue) {
            this.control.setValue(
                this.isNoLimit
                    ? this.cachedSearchValue
                    : this.cachedSearchValue?.slice(0, this.maxLength)
            );
            this.activeChange.emit(!!this.control.value);
        }

        if (changes.searchValue) {
            if (changes.searchValue.currentValue !== this.control.value) {
                this.control.setValue(changes.searchValue.currentValue);
            }
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onSearchClicked(): void {
        this.searchValueChange.emit(this.control.value.trim());
        this.activeChange.emit(!!this.control.value);
    }
}
