export enum IconsEnum {
    add = "add",
    refresh = "refresh",
    warning = "warning",
    error = "error",
    sell = "sell",
    edit = "edit",
    trash = "trash",
    success = "success",
    info = "info",
    clear = "clear",
    person = "person",
    logout = "logout",
    arrow_right = "arrow_right",
    double_arrow_regular = "double_arrow_regular",
    home_filled = "home_filled",
    clipboard_check = "clipboard_check",
    how_to_reg = "how_to_reg",
    group_chat = "group_chat",
    circle = "circle",
    list_bulleted = "list_bulleted",
    dots = "dots",
    pause = "pause",
    timer = "timer",
    play = "play",
    file_upload = "file_upload",
    file = "file",
    info_stroked = "info_stroked",
    question_mark = "question_mark",
    exclamation_mark = "exclamation_mark",
    verified_user = "verified_user",
    file_pdf = "file_pdf",
    file_excel = "file_excel",
    monetization = "monetization",
}
