import { Component } from "@angular/core";
import { IUser, UserStorageService } from "@core/services/storages/user-storage.service";
import { Observable } from "rxjs";

@Component({
    selector: "dbp-top-bar-user-info",
    templateUrl: "./top-bar-user-info.component.html",
    styleUrls: ["./top-bar-user-info.component.scss"],
})
export class TopBarUserInfoComponent {
    public user$: Observable<IUser> = this.userStorageService.selectUser();

    constructor(private userStorageService: UserStorageService) {}
}
