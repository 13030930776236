import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, shareReplay, tap } from "rxjs/operators";
import { webApiUrls } from "@core/constants/url.constant";
import { IPermissionsDto, IUserProfileDto } from "@core/interfaces/dto/user-profile-dto.interface";
import { UserStorageService } from "@core/services/storages/user-storage.service";
import { HttpClient } from "@angular/common/http";
import { IPermission } from "@core/interfaces/permission.interface";
import { PermissionsStorageService } from "./storages/permissions-storage.service";

@Injectable({
    providedIn: "root",
})
export class UserApiService {
    private userProfile$ = this.getUserProfileObs();

    constructor(
        private http: HttpClient,
        private userStorageService: UserStorageService,
        private permissionsStorageService: PermissionsStorageService
    ) {}

    public clearUserProfileCache(): void {
        this.userProfile$ = this.getUserProfileObs();
    }

    public hasUserProfile(): Observable<boolean> {
        if (!this.userStorageService.getUser()) {
            return this.userProfile$.pipe(map(data => Boolean(data)));
        }
        return of(true);
    }

    private getUserProfileObs(): Observable<IUserProfileDto> {
        return this.http.get<IUserProfileDto>(`${webApiUrls.auth}/UserProfile`).pipe(
            tap(data => {
                this.saveUserProfileToStorage(data);
            }),
            shareReplay(1)
        );
    }

    private saveUserProfileToStorage(profile: IUserProfileDto): void {
        this.userStorageService.setUserData(profile.user);

        const permissions = this.mapPermissions(profile.permissions);
        this.permissionsStorageService.setData(permissions);
    }

    private mapPermissions(permissions: IPermissionsDto[]): IPermission[] {
        return permissions.map(value => {
            return {
                id: value.permissionId,
                name: value.permission,
                permission: value.action,
            };
        });
    }
}
