/* eslint-disable guard-for-in */
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { iconsList } from "../constants/icons-list.constant";

@Injectable()
export class IconsRegisterService {
    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

    public registerIcons(): void {
        // For svg icon name use format `{namespace}:{iconName}`. For example:
        // <mat-icon svgIcon="dui:double_arrow_bold"></mat-icon>
        this.loadIconLiterals(iconsList, "dui");
    }

    private loadIconLiterals(iconsObj: { [key: string]: string }, namespace: string): void {
        for (const key in iconsObj) {
            const iconName = key;
            const iconData = iconsObj[key];
            this.matIconRegistry.addSvgIconLiteralInNamespace(
                namespace,
                iconName,
                this.domSanitizer.bypassSecurityTrustHtml(iconData)
            );
        }
    }
}
